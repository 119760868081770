import React, { useCallback } from "react";
import { CaretDownFilled, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { EventNames, useTracking } from "lib/tracking";
import SaveSupplierPopover from "./SaveSupplierPopover";

import css from "./SaveSupplier.module.scss";

export type SupplierContextSource =
  | "Supplier search"
  | "Bulk action"
  | "Supplier profile"
  | "In-row"
  | "Notice preview"
  | "Supplier filter";

type Props = {
  supplierGuid: string;
  supplierName: string;
  triggerType?: "bookmark" | "button";
  saveType?: "bookmark" | "button";
  contextSource: SupplierContextSource;
};

function SaveSupplier({
  supplierGuid,
  triggerType = "bookmark",
  saveType = "button",
  contextSource,
}: Props) {
  // This hook is in each save supplier component, but because of react query it should only run once
  const { data: savedSuppliers, isLoading: isLoadingSavedSuppliers } = useSavedSuppliers();

  const { partnerGuids, competitorGuids } = savedSuppliers || {
    partnerGuids: [],
    competitorGuids: [],
  };
  const isPartner = partnerGuids.some((guid) => guid === supplierGuid);
  const isCompetitor = competitorGuids.some((guid) => guid === supplierGuid);
  const isSaved = isPartner || isCompetitor;

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const tracking = useTracking();

  const supplierSignalTracking = useCallback(
    (allSupplierSignalCategoryGuids: string[], category: "Competitor" | "Partner") => {
      const isSavingSupplier = allSupplierSignalCategoryGuids.includes(supplierGuid);

      tracking.logEvent(EventNames.recordQueryUpdated, {
        "Context source": contextSource,
        "Action type": isSavingSupplier ? "Added" : "Removed",
        "Supplier GUID": supplierGuid,
        "Signal type": category,
      });
    },
    [supplierGuid, tracking, contextSource],
  );

  return (
    <SaveSupplierPopover
      supplierGuids={[supplierGuid]}
      onSaveSupplierOp={supplierSignalTracking}
      competitorGuids={competitorGuids}
      partnerGuids={partnerGuids}
      isPopoverOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      contextSource={contextSource}
    >
      <div>
        {isSaved ? (
          <Tooltip
            title={`Saved as a ${
              isCompetitor && isPartner
                ? "Competitor & Partner"
                : isCompetitor
                  ? "Competitor"
                  : "Partner"
            }`}
          >
            {/* Tooltip doesn't work without this div */}
            <div>
              {triggerType === "bookmark" ? (
                <>
                  {isLoadingSavedSuppliers ? (
                    <LoadingOutlined />
                  ) : (
                    <BookmarkIcon colour="blue" filled />
                  )}
                </>
              ) : (
                <Button
                  type="default"
                  className={css.saveSupplierButton}
                  loading={isLoadingSavedSuppliers}
                >
                  <BookmarkIcon colour="blue" filled /> Saved
                </Button>
              )}
            </div>
          </Tooltip>
        ) : (
          <span className={isPopoverOpen ? undefined : SHOW_ON_HOVER}>
            <Tooltip title="Save as competitor and/or partner">
              {saveType === "bookmark" ? (
                <Button
                  type="default"
                  className={css.bookmarkButton}
                  loading={isLoadingSavedSuppliers}
                >
                  <BookmarkIcon colour="blue" />
                  <CaretDownFilled className={css.caretDown} />
                </Button>
              ) : (
                <Button
                  type="default"
                  className={css.saveSupplierButton}
                  loading={isLoadingSavedSuppliers}
                >
                  <BookmarkIcon colour="grey" /> Save supplier
                </Button>
              )}
            </Tooltip>
          </span>
        )}
      </div>
    </SaveSupplierPopover>
  );
}

export default SaveSupplier;
