import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { numberFormatLong } from "components/app_layout/Typography";
import { generateNoticeSearchUrl } from "components/notices/utils";
import { OpportunityStage } from "components/opportunities/OpportunityStage";
import {
  Qualification,
  QUALIFICATIONS,
} from "components/record_qualifications/QualificationStatus";
import EmptyState from "components/ui/empty_state/EmptyState";
import SkeletonText from "components/ui/skeleton/SkeletonText";
import { HOMEPAGE_OPPORTUNITIES_PIPELINE, useVariableValue } from "lib/featureFlags";
import { useOpportunitiesStages } from "lib/hooks/api/homepage/useOpportunitiesStages";
import { useQualificationStages } from "lib/hooks/api/notices/useQualificationStages";
import { routes } from "lib/routes";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { Box, WidgetTitle } from "./util";

function Pipelines() {
  return (
    <Flex column gap={16} flexWrap="wrap">
      <WidgetTitle>Your pipeline</WidgetTitle>
      <CustomBox>
        <PipelinesContent />
      </CustomBox>
    </Flex>
  );
}

const HIDDEN_STATES: Qualification[] = [
  "unqualified",
  "not_relevant",
  "pre_engage_not_relevant",
  "no_bid",
  "lost",
];

const HIDDEN_OPPORTUNITY_GROUPS = ["Closed", "Won"];

const eventData = {
  "Context source": "Homepage pipeline",
  "Page source": "Homepage",
} as EventData;

const PipelinesContent = () => {
  const isOpportunitiesEnabled = useVariableValue(HOMEPAGE_OPPORTUNITIES_PIPELINE, false);
  const { data: dataQualifications, isLoading: isLoadingQualifications } = useQualificationStages({
    enabled: !isOpportunitiesEnabled,
  });
  const { data: dataOpportunities, isLoading: isLoadingOpportunities } = useOpportunitiesStages({
    enabled: isOpportunitiesEnabled,
  });
  const { logEvent } = useTracking();

  const allCounts = isOpportunitiesEnabled
    ? dataOpportunities
        ?.filter((op) => !HIDDEN_OPPORTUNITY_GROUPS.includes(op.group))
        .map((op) => op.order * 10) ?? [] // TODO: Get count from each opportunity stage
    : [
        ...(dataQualifications?.tenders
          ?.filter((q) => !HIDDEN_STATES.includes(q.stage as Qualification))
          .map((q) => q.count) || []),
        ...(dataQualifications?.preEngagement
          ?.filter((q) => !HIDDEN_STATES.includes(q.stage as Qualification))
          .map((q) => q.count) || []),
      ];
  const highestCount = Math.max(...allCounts);

  if (!isLoadingQualifications && allCounts.every((count) => count === 0)) {
    const helpUrl = "https://help.stotles.com/how-to-use-expiring-contracts";
    return (
      <EmptyState
        type="userRequiredContent"
        heading="Find early pipeline opportunities"
        description="Start building proactive, early pipeline by identifying expiring contracts that are likely to renew, and engage with the buyer ahead of your competitors."
        width="100%"
        action={
          <Link
            href={helpUrl}
            target="_blank"
            onClick={() =>
              logEvent(EventNames.clickedHelpCentre, {
                ...eventData,
                "Destination url": helpUrl,
              })
            }
          >
            Learn how to build early pipeline
          </Link>
        }
      />
    );
  }

  if (isOpportunitiesEnabled) {
    const data = isLoadingOpportunities
      ? Array.from({ length: 6 }).map((_, i) => ({
          id: i,
          order: i,
          count: 0,
          text: "",
          group: "",
          color: "",
          iconName: "",
          iconColor: "",
        }))
      : dataOpportunities
          ?.filter((transition) => !HIDDEN_OPPORTUNITY_GROUPS.includes(transition.group))
          .map((transition) => ({
            id: transition.id,
            order: transition.order,
            count: transition.order * 10, // TODO: Get real value
            text: transition.stage.name,
            group: transition.group,
            color: transition.stage.color,
            iconName: transition.stage.iconName,
            iconColor: transition.stage.iconColor,
          }))
          .sort((a, b) => a.order - b.order);

    return (
      <>
        {data?.map((item) => (
          <PipelineStage
            key={item.id}
            item={item}
            highestCount={highestCount}
            isLoading={isLoadingOpportunities}
          />
        ))}
      </>
    );
  } else {
    const preEngageData = Object.entries(QUALIFICATIONS.preEngage).filter(
      ([key]) => !HIDDEN_STATES.includes(key as Qualification),
    );
    const tenderData = Object.entries(QUALIFICATIONS.tender).filter(
      ([key]) => !HIDDEN_STATES.includes(key as Qualification),
    );
    const combinedData = [...preEngageData, ...tenderData].map(([key, value]) => ({
      stage: key as Qualification,
      category: Object.values(QUALIFICATIONS.preEngage)
        .map((el) => el.text)
        .includes(value.text)
        ? ("preEngage" as const)
        : ("tender" as const),
      text: value.text === "Bid submitted" ? "Submitted" : value.text,
      icon: value.icon,
      count: dataQualifications
        ? dataQualifications.preEngagement?.find((q) => q.stage === key)?.count ??
          dataQualifications.tenders?.find((q) => q.stage === key)?.count ??
          0
        : 0,
    }));

    return (
      <>
        {combinedData.map((item) => (
          <PipelineStageQualification
            key={item.stage}
            item={item}
            highestCount={highestCount}
            isLoading={isLoadingQualifications}
          />
        ))}
      </>
    );
  }
};

const PipelineStage = ({
  item,
  highestCount,
  isLoading,
}: {
  item: {
    count: number;
    order: number;
    text: string;
    group: string;
    color: string;
    iconName: string;
    iconColor: string;
  };
  highestCount: number;
  isLoading: boolean;
}) => {
  const { logEvent } = useTracking();

  return (
    <StageBox
      href={routes.opportunities} // TODO: Once it gets filtering, link to specific stage
      onClick={() =>
        logEvent(EventNames.pipelineViewed, {
          ...eventData,
          "Opportunity stage group": item.group,
          "Opportunity stage name": item.text,
          "Opportunity stage index": item.order,
        })
      }
    >
      <ChartContainer>
        <Chart value={item.count} maxValue={highestCount} color={item.iconColor} />
      </ChartContainer>
      {isLoading ? (
        <SkeletonText width="50px" fontSize={17} />
      ) : (
        <CountText bold>{numberFormatLong(item.count)}</CountText>
      )}
      {isLoading ? (
        <SkeletonText width="100px" fontSize={17} />
      ) : (
        <OpportunityStage
          label={item.text}
          iconName={item.iconName}
          color={item.color}
          iconColor={item.iconColor}
        />
      )}
    </StageBox>
  );
};

const PipelineStageQualification = ({
  item,
  highestCount,
  isLoading,
}: {
  item: {
    stage: Qualification;
    category: "preEngage" | "tender";
    text: string;
    icon: React.ReactNode;
    count: number;
  };
  highestCount: number;
  isLoading: boolean;
}) => {
  const { logEvent } = useTracking();

  return (
    <QualificationsContainer>
      {["pre_engage_to_do", "in_review"].includes(item.stage) && (
        <TitleText h3>
          {item.stage === "pre_engage_to_do" ? "Expiring contracts" : "Open tenders"}
        </TitleText>
      )}
      <StageBox
        qualification
        href={generateNoticeSearchUrl({
          procurementStageQualifications: [item.stage],
        })}
        onClick={() =>
          logEvent(EventNames.pipelineViewed, {
            ...eventData,
            "Data type": item.category === "preEngage" ? "AWARDS" : "TENDER",
            "Qualification label": item.stage,
          })
        }
      >
        <ChartContainer>
          <Chart value={item.count} maxValue={highestCount} />
        </ChartContainer>
        {isLoading ? (
          <SkeletonText width="50px" fontSize={17} />
        ) : (
          <CountText bold>{numberFormatLong(item.count)}</CountText>
        )}
        <Flex gap="8px">
          <IconWrapper>{item.icon}</IconWrapper>
          <Text inline>{item.text}</Text>
        </Flex>
      </StageBox>
    </QualificationsContainer>
  );
};

const CustomBox = styled(Box)({
  display: "grid",
  width: "100%",
  height: "100%",
  gridTemplateColumns: "repeat(auto-fit, minmax(110px, 1fr))",
  gap: "16px",
});

const QualificationsContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  gap: "24px",
});

const StageBox = styled.a<{ qualification?: boolean }>(({ qualification, theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "8px",
  gap: "16px",
  minWidth: "110px",
  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    borderRadius: "4px",
  },
  ...(qualification && {
    justifyContent: "flex-end",
  }),
}));

// Purposefully not using chart.js for this
// did initially start with that but I ran into a few issues
// around flex and/or corners (could fix one but not both simultaneously)
// and this just works for this very simple use case
const ChartContainer = styled.div({
  height: "140px",
  display: "flex",
  alignItems: "flex-end",
});

const Chart = styled.div<{ value: number; maxValue: number; color?: string }>(
  ({ value, maxValue, color, theme }) => ({
    height: `${(Math.min(value, maxValue) / (maxValue || 1)) * 100}%`, // Prevent division by 0 & numbers greater than 100%
    width: "100%",
    backgroundColor: color ?? theme.colors.sysPrimaryDefault,
    borderRadius: "4px",
    transition: "height 1s ease-in-out",
  }),
);

const IconWrapper = styled.span(() => ({
  width: "12px",
  height: "12px",
  transform: "scale(0.6) translateY(4px)", // 12px / 20px = 0.8, 20-12/2 = 4
}));

const TitleText = styled(Text)(() => ({
  fontWeight: "500",
  paddingLeft: "8px",
  textWrap: "nowrap" as const,
}));

const CountText = styled(Text)(() => ({
  fontSize: "17px",
}));

const Link = styled.a(({ theme }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  textAlign: "center",
  color: theme.colors.sysPrimaryDefault,
  padding: "4px 12px",
  "&:hover": {
    color: theme.colors.sysPrimaryHover,
  },
  "&:active": {
    color: theme.colors.sysPrimaryPressed,
  },
}));

export default Pipelines;
