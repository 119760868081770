import React from "react";
import ReactMarkdown, { Components } from "react-markdown";
import classnames from "classnames";
import remarkGfm from "remark-gfm";

import { TargetType, TextLink } from "components/actions/Links";

import css from "./Markdown.module.scss";

type MarkdownProps = {
  text: string;
  className?: string;
  trackingLinkContext?: (recordGuid: string) => void;
  targetType?: TargetType;
  customComponents?: Components;
};

export function Markdown({
  text,
  className,
  trackingLinkContext,
  targetType,
  customComponents,
}: MarkdownProps): JSX.Element {
  return (
    <ReactMarkdown
      className={classnames(css.markdown, className)}
      plugins={[remarkGfm]}
      // If the link isn't defined then we just render regular text
      components={{
        a: ({ children, href }) => {
          if (!href) return <span>{children}</span>;

          let link = href;
          let isHash = false;
          // convert hash links to absolute links, so that they work both in PDFs and in
          // a browser (and the browser is smart enough not to reload the page)
          if (link.startsWith("#")) {
            const url = new URL(window.location.href);
            url.hash = link;
            link = url.toString();
            isHash = true;
          }

          // extract guid from link for optional tracking
          const regexToFindGuid =
            /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/;
          const guid = link.match(regexToFindGuid);

          // because we are converting hash links to absolute urls, we want to ensure that no
          // further params are added to the url if it's a hash, because otherwise this will trigger
          // a page reload
          return (
            <TextLink
              to={link}
              skipUtmParams={isHash}
              onClick={() => trackingLinkContext && guid && trackingLinkContext(guid[0])}
              targetType={targetType}
            >
              {children}
            </TextLink>
          );
        },
        img: ({ src, style, ...props }) => {
          if (!src) {
            return <img src={src} style={style} {...props} />;
          }
          //set image size via fragment eg https://example.com/image.png#200x200
          const hash = new URL(src).hash;
          const regex = /^#(\d+)x(\d+)$/;
          const match = regex.exec(hash);
          if (match) {
            const [, width, height] = match;
            style = {
              ...style,
              width: `${width}px`,
              height: `${height}px`,
            };
          }

          return <img src={src} {...props} style={style} />;
        },
        ...customComponents,
      }}
    >
      {text}
    </ReactMarkdown>
  );
}
