import React from "react";
import { CheckOutlined, CloseOutlined, ContactsOutlined, TrophyOutlined } from "@ant-design/icons";
import { Button, Popover, Tooltip } from "antd5";

import RequestContactsButton from "components/actions/RequestContactsButton";
import { OnboardingPopover } from "components/onboarding/OnboardingPopover";
import PaywallPopover from "components/paywall/PaywallPopover";
import { CreateQualificationRequestQualificationEnum } from "lib/generated/app-api";
import { useContacts } from "lib/hooks/api/contacts/useContacts";
import { useUpdateRecordQualification } from "lib/hooks/api/records/useUpdateRecordQualification";
import PaywallStar from "lib/icons/PaywallStar";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { white } from "lib/themes/colors";
import { Qualification, QualificationStatus } from "./QualificationStatus";

import css from "./QualificationActions.module.scss";

type ActionProps = {
  primaryText: string;
  primaryAction: () => void;
  primaryIcon?: React.ReactNode;
  onCancel?: () => void;
  onCancelText?: string;
};

export function QualificationActions({
  score,
  signalTypes,
  contextSource,
  procurementStage,
  notice,
  showStatusOnNoActions,
  compactDisplay,
  containerClassName,
  buttonClassName,
}: {
  procurementStage: {
    id: string;
    stage: string;
    qualification?: Qualification;
  };
  score: number | null | undefined;
  signalTypes: Record<string, string[]> | undefined;
  contextSource: string;
  notice?: {
    guid: string;
    name: string;
    buyers: { id: number; guid: string; name: string }[];
  };
  showStatusOnNoActions?: boolean; // shows the qualifcation status if no actions are available
  compactDisplay?: boolean;
  containerClassName?: string;
  buttonClassName?: string;
}) {
  const { mutate: updateRecordQualification, isLoading: isUpdatingQualification } =
    useUpdateRecordQualification();
  const dataTypesSubscription = useSubscription();
  const hasContacts = dataTypesSubscription.hasSubscribedDataTypes("CONTACTS");

  const isPreEngage = procurementStage.stage.toLowerCase() !== "tender";

  // Determining whether or not to display find contacts or request contacts
  const { data: contactsData, isLoading: isLoadingContactsData } = useContacts(
    {
      limit: 1,
      page: 1,
    },
    {
      enabled: isPreEngage && hasContacts,
    },
  );

  const qualification: Qualification = procurementStage.qualification || "unqualified";

  const noContactsFound =
    (qualification === "pre_engage_to_do" || qualification === "pre_engage_done") &&
    contactsData?.contacts.results &&
    contactsData.contacts.results.length === 0;

  const updateQualification = (qual: CreateQualificationRequestQualificationEnum) => {
    updateRecordQualification({
      procurementStage: { id: procurementStage.id, stage: procurementStage.stage },
      recordGuid: notice?.guid || "",
      signalTypes: signalTypes,
      score: score,
      qualification: qual,
      contextSource,
    });
  };

  const actionProps = isPreEngage
    ? getPreEngageQualificationAction(qualification, updateQualification, notice?.guid || "")
    : getTenderQualificationAction(qualification, updateQualification);

  if (!actionProps) {
    if (showStatusOnNoActions) {
      return (
        <QualificationStatus
          procurementStage={{
            id: procurementStage.id,
            stage: procurementStage.stage,
            qualification: procurementStage.qualification,
          }}
          noticeGuid={notice?.guid || ""}
          score={score}
          signalTypes={signalTypes}
        />
      );
    }
    return null;
  }

  const loadingContacts =
    (qualification === "pre_engage_to_do" || qualification === "pre_engage_done") &&
    hasContacts &&
    isLoadingContactsData;

  const { onCancel, onCancelText } = actionProps;

  return (
    <div className={containerClassName || css.container}>
      <PrimaryButton
        isPreEngage={isPreEngage}
        noContactsFound={!!noContactsFound}
        qualification={qualification}
        notice={notice}
        contextSource={contextSource}
        button={actionProps}
        loading={isUpdatingQualification || loadingContacts}
        defaultOpenPopover={!compactDisplay}
        buttonClassName={buttonClassName}
      />
      {onCancel &&
        (compactDisplay ? (
          <Tooltip title={onCancelText ? `Mark as ${onCancelText.toLowerCase()}` : undefined}>
            <Button
              onClick={(e) => {
                // prevent slideout from opening
                e.stopPropagation();
                onCancel();
              }}
              loading={isUpdatingQualification}
              title={onCancelText}
              danger
              icon={<CloseOutlined />}
              className={buttonClassName || css.cancelButton}
            />
          </Tooltip>
        ) : (
          <Button
            danger
            onClick={() => onCancel()}
            loading={isUpdatingQualification}
            icon={<CloseOutlined />}
            className={buttonClassName || css.cancelButton}
          >
            {onCancelText}
          </Button>
        ))}
    </div>
  );
}

type PrimaryButtonProps = {
  isPreEngage: boolean;
  noContactsFound: boolean;
  qualification: Qualification;
  contextSource: string;
  loading: boolean;
  defaultOpenPopover?: boolean;
  button: {
    primaryText: string;
    primaryAction: () => void;
    primaryIcon?: React.ReactNode;
  };
  notice?: {
    guid: string;
    name: string;
    buyer?: { guid: string; name: string };
  };
  buttonClassName?: string;
};

// This wrapper component is required as the popovers dont seem to work if there are multiple popovers in the same component
function PrimaryButton({
  button,
  noContactsFound,
  contextSource,
  isPreEngage,
  qualification,
  loading,
  defaultOpenPopover,
  buttonClassName,
  notice,
}: PrimaryButtonProps) {
  const { checkSubscription, authorised: hasContactsAccess } = useCheckSubscription("CONTACTS", {
    "Context source": contextSource,
  });

  const checkContactsDataType =
    isPreEngage && (qualification === "pre_engage_to_do" || qualification === "pre_engage_done");

  const paywallContacts = !hasContactsAccess && checkContactsDataType;

  const primaryButton = (
    <Button
      type="primary"
      className={buttonClassName || css.primaryButton}
      onClick={(e) => {
        // prevent slideout from opening
        e.stopPropagation();

        if (checkContactsDataType) {
          if (hasContactsAccess) {
            button.primaryAction();
          } else {
            checkSubscription();
          }
        } else {
          button.primaryAction();
        }
      }}
      icon={button.primaryIcon}
      disabled={hasContactsAccess ? noContactsFound : false}
      loading={loading}
    >
      {button.primaryText}
      {paywallContacts && <PaywallStar className={css.paywallStar} fill={white} />}
    </Button>
  );

  if (!hasContactsAccess && checkContactsDataType) {
    return (
      <PaywallPopover featureType="CONTACTS" contextSource={contextSource}>
        {primaryButton}
      </PaywallPopover>
    );
  }

  if (noContactsFound) {
    return (
      <Popover
        placement="bottomLeft"
        content={<RequestContactsPopoverContent notice={notice} contextSource={contextSource} />}
        overlayClassName={css.disabledPopupContainer}
      >
        {primaryButton}
      </Popover>
    );
  }

  if (isPreEngage && qualification === "unqualified") {
    return (
      <OnboardingPopover
        title="Mark notices as a pre-engagement opportunity"
        description="Contract awards and pre-tenders can now be marked as pre-engagement opportunities."
        tour="pre_engage_qualification_intro"
        enabled={false}
        defaultOpen={defaultOpenPopover}
      >
        {primaryButton}
      </OnboardingPopover>
    );
  }

  if (isPreEngage && qualification === "pre_engage_to_do") {
    return (
      <OnboardingPopover
        title="Find decision makers to engage with early."
        description="From any contract award or pre-tender marked as a pre-engagement opportunity you can find relevant contacts to start speaking to."
        tour="contacts_qualification_intro"
        defaultOpen={defaultOpenPopover}
        enabled={false}
      >
        {primaryButton}
      </OnboardingPopover>
    );
  }

  return <>{primaryButton}</>;
}

function RequestContactsPopoverContent({
  contextSource,
  notice,
}: {
  contextSource: string;
  notice?: {
    guid: string;
    name: string;
    buyer?: { guid: string; name: string };
  };
}) {
  return (
    <div className={css.disabledPopupContent}>
      <h3 className={css.disabledPopupHead}>
        Looks like we don't have contacts for this buyer just yet...
      </h3>
      <span className={css.disabledPopupTxt}>
        A member of our team is always happy to take a look and see if they can find contacts from
        other sources. Just send a request using the button below and we will be in touch soon.
      </span>
      <div>
        <RequestContactsButton contextSource={contextSource} notice={notice} />
      </div>
    </div>
  );
}

function getTenderQualificationAction(
  qualification: Qualification,
  updateQualification: (qual: CreateQualificationRequestQualificationEnum, label: string) => void,
): ActionProps | null {
  const TENDER_QUALIFICATION_ACTIONS: Record<string, ActionProps | null> = {
    unqualified: {
      primaryText: "Relevant",
      primaryIcon: <CheckOutlined />,
      primaryAction: () => updateQualification("in_review", "Relevant"),
      onCancel: () => updateQualification("not_relevant", "Not relevant"),
      onCancelText: "Not relevant",
    },
    not_relevant: {
      primaryText: "Relevant",
      primaryIcon: <CheckOutlined />,
      primaryAction: () => updateQualification("in_review", "Relevant"),
    },
    in_review: {
      primaryText: "Bid",
      primaryIcon: <CheckOutlined />,
      primaryAction: () => updateQualification("bid_prep", "Bid"),
      onCancel: () => updateQualification("no_bid", "No bid"),
      onCancelText: "No bid",
    },
    bid_prep: {
      primaryText: "Bid submitted",
      primaryIcon: <CheckOutlined />,
      primaryAction: () => updateQualification("bid_submitted", "Bid submitted"),
      onCancel: () => updateQualification("no_bid", "No bid"),
      onCancelText: "No bid",
    },
    bid_submitted: {
      primaryText: "Won",
      primaryIcon: <TrophyOutlined />,
      primaryAction: () => updateQualification("won", "Won"),
      onCancel: () => updateQualification("lost", "Lost"),
      onCancelText: "Lost",
    },
    no_bid: null,
    won: null,
    lost: null,
  };

  return TENDER_QUALIFICATION_ACTIONS[qualification];
}

function getPreEngageQualificationAction(
  qualification: Qualification,
  updateQualification: (qual: CreateQualificationRequestQualificationEnum, label: string) => void,
  recordGuid: string,
): ActionProps | null {
  const PRE_ENGAGE_QUALIFICATION_ACTIONS: Record<string, ActionProps> = {
    unqualified: {
      primaryText: "Pre-engage",
      primaryIcon: <CheckOutlined />,
      primaryAction: () => updateQualification("pre_engage_to_do", "Pre-engage"),
      onCancel: () => updateQualification("pre_engage_not_relevant", "Not relevant"),
      onCancelText: "Not relevant",
    },
    pre_engage_not_relevant: {
      primaryText: "Relevant",
      primaryIcon: <CheckOutlined />,
      primaryAction: () => updateQualification("pre_engage_to_do", "Relevant"),
    },
    pre_engage_to_do: {
      primaryText: "Find decision makers",
      primaryIcon: <ContactsOutlined />,
      primaryAction: () => (window.location.href = `/records/${recordGuid}/related_contacts`),
    },
    pre_engage_done: {
      primaryText: "View contacts",
      primaryIcon: <ContactsOutlined />,
      primaryAction: () => (window.location.href = `/records/${recordGuid}/related_contacts`),
    },
  };

  return PRE_ENGAGE_QUALIFICATION_ACTIONS[qualification];
}

type MarkAsNotRelevantButtonProps = {
  procurementStage: {
    id: string;
    stage: string;
    qualification?: Qualification;
  };
  score: number | null | undefined;
  signalTypes: Record<string, string[]> | undefined;
  contextSource: string;
  notice?: {
    guid: string;
    name: string;
    buyers: { id: number; guid: string; name: string }[];
  };
  onClick?: () => void;
};

export function MarkAsNotRelevantButton({
  score,
  signalTypes,
  contextSource,
  procurementStage,
  notice,
  onClick,
}: MarkAsNotRelevantButtonProps) {
  const onCancelText = "Mark as not relevant";

  const { mutate: updateRecordQualification, isLoading: isUpdatingQualification } =
    useUpdateRecordQualification();

  const isPreEngage = procurementStage.stage.toLowerCase() !== "tender";

  return (
    <Tooltip title={onCancelText}>
      <Button
        onClick={(e) => {
          // prevent slideout from opening
          e.stopPropagation();
          updateRecordQualification({
            procurementStage: { id: procurementStage.id, stage: procurementStage.stage },
            recordGuid: notice?.guid || "",
            signalTypes: signalTypes,
            score: score,
            qualification: isPreEngage ? "pre_engage_not_relevant" : "not_relevant",
            contextSource,
          });

          onClick && onClick();
        }}
        loading={isUpdatingQualification}
        title={onCancelText}
        danger
        icon={<CloseOutlined />}
        className={css.cancelButton}
      />
    </Tooltip>
  );
}
