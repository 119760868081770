import React from "react";

import { minus, plus } from "lib/icons/untitled_ui/SVGs";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { grey700 } from "lib/themes/colors";
import { EventData, EventNames } from "lib/tracking";
import {
  Scorecard_Result,
  Scorecard_Result_Questions_Sources,
} from "../../../lib/generated/app-service-gql/graphql";
import { Text } from "../../../styles/utility-components";
import { QuestionAndAnswer } from "./QuestionAndAnswer";

import css from "./QualificationReportSection.module.scss";

type Props = {
  scoreCardResult: Scorecard_Result;
  procurementStageId: string;
  bidId: string;
  debugMode: boolean;
  onSelectDocument: (
    source: Scorecard_Result_Questions_Sources,
    trackingContext: EventNames,
    trackingData: EventData,
  ) => Promise<void>;
  slideOutOpen: boolean;
};
export function QualificationReportSection({
  scoreCardResult,
  procurementStageId,
  bidId,
  debugMode,
  onSelectDocument,
  slideOutOpen,
}: Props) {
  const { groupName, questions } = scoreCardResult;

  const [isOpen, setOpen] = React.useState(false);

  return (
    <details key={groupName} className={css.qaReportSection}>
      <summary className={css.titleContainer} onClick={() => setOpen(!isOpen)}>
        <Text h3>{groupName}</Text>
        <span className={css.icon}>
          {isOpen ? (
            <UIcon svg={minus} size={24} color={grey700} />
          ) : (
            <UIcon svg={plus} size={24} color={grey700} />
          )}
        </span>
      </summary>
      <section className={css.qaSection}>
        {questions.map((question, idx) => (
          <QuestionAndAnswer
            question={question}
            key={idx}
            procurementStageId={procurementStageId}
            bidId={bidId}
            debugMode={debugMode}
            onSelectDocument={onSelectDocument}
            showDivider={groupName === "Strategic fit" && idx < questions.length - 1}
            slideOutOpen={slideOutOpen}
          />
        ))}
      </section>
    </details>
  );
}
