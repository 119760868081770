import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Input, Skeleton } from "antd5";

import PaywallStar from "lib/icons/PaywallStar";
import { createUseDebounce } from "../../../../lib/debounce";
import UIcon from "../../../../lib/icons/untitled_ui/UIcon";
import {
  sysBorderPrimary,
  sysPrimaryDefault,
  sysPrimarySubtle,
} from "../../../../lib/themes/colors";
import { Flex, Text } from "../../../../styles/utility-components";
import EmptyState from "../../../ui/empty_state/EmptyState";
import { SharedSaveProps } from "../types";

export type Item = {
  label: string;
  isSaved: boolean;
  id: string;
  viewUrl: string;
  paywall?: boolean;
};

export type CreateListFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  footer: React.ReactNode;
  entityIds: string[];
};

type Props = {
  items: Item[];
  onSearch: (searchValue: string) => void;
  onItemClick: (id: string, isSaved: boolean) => void;
  isLoading: boolean;
  isError: boolean;
  CreateListForm?: React.ComponentType<CreateListFormProps>;
} & SharedSaveProps;

const useDebounce = createUseDebounce(500);

export function SaveToList({
  onSearch,
  CreateListForm,
  items,
  onItemClick,
  isLoading,
  isError,
  entityId,
}: Props) {
  const [searchText, setSearchText] = useState("");
  const [isCreatingList, setIsCreatingList] = useState(false);

  const onSearchDebounced = useDebounce((text: string) => {
    onSearch(text);
  });

  if (isCreatingList) {
    return (
      <Flex column gap={16} height={"100%"} flexGrow={1}>
        <Flex gap={8}>
          <Button
            icon={<UIcon icon="arrowNarrowLeft" size={16} />}
            onClick={() => setIsCreatingList(false)}
            title="Cancel creating an opportunity"
            size="small"
          />
          <Text h3>Create new list</Text>
        </Flex>
        {CreateListForm && (
          <CreateListForm
            onCancel={() => setIsCreatingList(false)}
            onSuccess={() => setIsCreatingList(false)}
            footer={
              <Flex column gap={8} flexGrow={1} justifyContent="flex-end">
                <Button type="primary" htmlType="submit" aria-label="create-list-button">
                  Create new list
                </Button>
              </Flex>
            }
            entityIds={[entityId]}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex column gap={16} height={"100%"}>
      <Text h3>Save to a list</Text>
      <Input
        placeholder="Search"
        prefix={<UIcon icon="searchRefraction" size={16} />}
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          onSearchDebounced(e.target.value);
        }}
        allowClear
      />
      <ListOfLists
        items={items}
        isLoading={isLoading}
        isError={isError}
        onItemClick={onItemClick}
      />
      {CreateListForm && (
        <Button type="primary" onClick={() => setIsCreatingList(true)}>
          Create new list
        </Button>
      )}
    </Flex>
  );
}

function ListOfLists({
  items,
  isLoading,
  isError,
  onItemClick,
}: {
  items: Item[];
  onItemClick: (id: string, isSaved: boolean) => void;
  isLoading: boolean;
  isError: boolean;
}) {
  if (isLoading) {
    return (
      <ListContainer>
        <Skeleton active />
      </ListContainer>
    );
  }

  if (isError) {
    return (
      <EmptyState
        type="loadError"
        heading="Sorry something has gone wrong"
        description="If this error persists please contact support"
        width="100%"
        size="large"
      />
    );
  }

  if (items.length === 0) {
    return (
      <EmptyState
        heading="You haven't created any lists yet"
        // description="Create opportunities once you identify leads from Stotles, which can then be tracked and progressed our public sector pipeline."
        width="100%"
        size="large"
      />
    );
  }

  return (
    <ListContainer>
      {items.map((item) => (
        <ListItem key={item.id}>
          <IconWrapper>
            <UIcon icon="list" size={16} />
          </IconWrapper>
          <Flex minWidth={0}>
            <Text ellipsis>
              {item.label}
              {item.paywall && <PaywallStar />}
            </Text>
          </Flex>
          <ActionsContainer>
            <Button
              type="primary"
              icon={
                <UIcon
                  icon="bookmark"
                  size={16}
                  fill={item.isSaved ? "currentColor" : "none"}
                  color="currentColor"
                />
              }
              onClick={() => onItemClick(item.id, item.isSaved)}
              aria-label={
                item.isSaved ? `Remove from list ${item.label}` : `Add to list ${item.label}`
              }
            >
              {item.isSaved ? "Unsave" : "Save"}
            </Button>
            <Button target="_blank" href={item.viewUrl}>
              View
            </Button>
          </ActionsContainer>
        </ListItem>
      ))}
    </ListContainer>
  );
}

const ActionsContainer = styled.div({
  display: "none",
  gap: 4,
  marginLeft: "auto",
});

const ListContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  gap: 8,
  height: "100%",
  overflowY: "auto",
});

const IconWrapper = styled.div({
  display: "flex",
  flex: "0 0 16px",
});

const ListItem = styled.div({
  display: "flex",
  gap: 16,
  height: 54,
  border: "1px solid",
  borderRadius: 4,
  padding: "12px 16px",
  borderColor: sysBorderPrimary,
  alignItems: "center",
  transition: "background-color 140ms ease-out, border-color 140ms ease-out",
  "&:hover": {
    backgroundColor: sysPrimarySubtle,
    borderColor: sysPrimaryDefault,
    [`${ActionsContainer}`]: {
      display: "flex",
    },
  },
});
