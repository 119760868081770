import React from "react";
import styled from "@emotion/styled";

function Welcome() {
  if (window.currentUser === null) {
    return null;
  }
  const firstName = window.currentUser.first_name;

  return (
    <TitleText>
      Welcome to Stotles, <TitleTextName as="span">{firstName}</TitleTextName>
    </TitleText>
  );
}

const TitleText = styled.h1(({ theme }) => ({
  color: theme.colors.sysTextDefault,
  fontSize: "29px",
  fontWeight: "500",
  lineHeight: "125%",
  margin: 0,
}));

const TitleTextName = styled(TitleText)({
  fontWeight: "700",
});

export default Welcome;
