import * as React from "react";
import { Button, Layout, Progress } from "antd5";
import classNames from "classnames";
import { DateTime } from "luxon";

import PaywallCTAModal from "components/account_management/PaywallCTAModal";
import ActionButton from "components/actions/ActionButton";
import TextButton, { TextButtonProps } from "components/actions/TextButton";
import { MAX_SMALL_SCREEN_WARNING_SIZE } from "components/app_layout/SmallScreenWarning";
import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";
import PaywallStar from "lib/icons/PaywallStar";
import { useDialogManager } from "lib/providers/DialogManager";
import { ScreenSize, useResponsiveScreenSize } from "lib/providers/Responsive";
import { useSubscription } from "lib/providers/Subscription";
import { grey900 } from "lib/themes/colors";
import * as tracking from "lib/tracking";
import { EventNames, useTracking } from "lib/tracking";
import { User } from "lib/types/models";
import { performLogout } from "lib/userUtils";
import { pluralise } from "lib/utils";
import { useVariableValue, VERTICAL_NAV } from "../../lib/featureFlags";
import { TextLink } from "../actions/Links";
import VerticalNav from "./vertical_nav/VerticalNav";
import HelpDropdown from "./HelpDropdown";
import NotificationCentre from "./NotificationCentre";
import ProductNav from "./ProductNav";
import SettingsDropdown from "./SettingsDropdown";

import css from "./AppLayoutNavbar.module.scss";

import LogoURL from "../../../assets/images/stotles_logo.svg";
import TextLogo from "../../../assets/images/stotles_logo_blue.svg";

type Props = {
  hideMenuItems?: boolean;
  hideUserItems?: boolean;
  displayCustomAction?: React.ReactNode;
  disableLogoNav?: boolean;
  textLogo?: boolean;
};

enum CurrentTrialStatus {
  EligibleForTrial,
  OnTrial,
  IneligibleForTrial,
}

function UserItems({ user }: { user: User | null }) {
  const screenSize = useResponsiveScreenSize();
  const subscription = useSubscription();
  const trialStatus = React.useMemo(() => {
    if (subscription.onTrial()) {
      return CurrentTrialStatus.OnTrial;
    } else if (subscription.eligibleForTrial()) {
      return CurrentTrialStatus.EligibleForTrial;
    } else {
      return CurrentTrialStatus.IneligibleForTrial;
    }
  }, [subscription]);

  if (!user)
    return (
      <>
        <TextLink className={css.loginButton} to="/users/sign_in">
          Sign in
        </TextLink>
        <Button
          type="primary"
          onClick={() => {
            tracking.logEvent(tracking.EventNames.clickedSignUpCTA, {
              "Context source": "navbar",
            });
          }}
        >
          Sign up for free
        </Button>
      </>
    );

  return (
    <div
      className={classNames(css.userItemsContainer, {
        [css.onTrial]: trialStatus === CurrentTrialStatus.OnTrial,
      })}
    >
      {screenSize >= ScreenSize.MD && <PlanInfo trialStatus={trialStatus} />}
      <SettingsDropdown />
      <HelpDropdown />
      <NotificationCentre />
    </div>
  );
}

function SignOutButton({ user }: { user: User | null }) {
  if (!user) {
    return null;
  }
  return (
    <ActionButton type="link" className={css.logoutButton} onAction={performLogout}>
      Log out
    </ActionButton>
  );
}

function PlanInfo({ trialStatus }: { trialStatus: CurrentTrialStatus }) {
  const subscription = useSubscription();
  const screenSize = useResponsiveScreenSize();

  const { mutate: pqlActioned } = usePqlActioned();

  const { logEvent } = useTracking();
  const dialogManager = useDialogManager();

  const handleProClick = () => {
    logEvent(EventNames.clickedRequestPro, {
      "Context source": "Navigation bar",
    });

    pqlActioned({
      actionType: "Upgrade",
      pageUrl: window.location.href,
    });

    void dialogManager.openDialog(PaywallCTAModal, { showBookADemoForm: false });
  };

  if (trialStatus === CurrentTrialStatus.OnTrial) {
    const now = DateTime.now();
    const endDate = DateTime.fromJSDate(subscription.trialEndDate ?? new Date()).endOf("day");
    const startDate = DateTime.fromJSDate(subscription.trialStartDate ?? new Date());
    const daysRemaining = endDate.diff(now, "day");
    const trialElapsedPercent =
      (now.diff(startDate, "day").days / endDate.diff(startDate, "day").days) * 100;

    return (
      <div className={css.trialCounterContainer}>
        <div className={css.trialCounterHeader}>
          {screenSize >= ScreenSize.LG ? (
            <>
              Your {window.settings?.trialLength}-day free trial of <strong>Stotles Pro</strong>
            </>
          ) : (
            <>
              <strong>Stotles Pro</strong> trial
            </>
          )}
          :
        </div>
        <span className={css.progressBarContainer}>
          <div
            // this is wrapper is just for spacing, and allows us to apply margins
            // without specifying ant classes specifically
            className={css.antProgressWrapper}
          >
            <Progress
              strokeColor="#4f86fa"
              // though typescript recognises a trailColor prop, it appears it is not properly
              // implemented until ant 4.0
              // TODOa4: uncomment
              // trailColor="#e6f7ff"
              percent={trialElapsedPercent}
              showInfo={false} // hide the actual percentage
              trailColor="#ebf1fc"
            />
          </div>
          <TextLink to="/account-management/subscription">View Subscription</TextLink>
        </span>
        <div className={css.trialCounterCountdown}>
          {pluralise(Math.floor(daysRemaining.days), "day")} remaining
        </div>
      </div>
    );
  } else if (trialStatus === CurrentTrialStatus.EligibleForTrial) {
    return (
      <Button className={css.upgradeButton} onClick={handleProClick}>
        Upgrade <PaywallStar fill={grey900} />
      </Button>
    );
  } else {
    return null;
  }
}

function AppLayoutNavbar({
  hideMenuItems,
  hideUserItems,
  displayCustomAction,
  disableLogoNav,
  textLogo,
}: Props): JSX.Element {
  const { currentUser } = window;
  const screenSize = useResponsiveScreenSize();

  const isVerticalNavEnabled = useVariableValue(VERTICAL_NAV, false);

  const logo = React.useMemo(() => {
    const smallLogoSize = screenSize <= ScreenSize.MD;
    const src = textLogo ? TextLogo : LogoURL;
    const stotles = <img className={css.topnavLogo} src={src} alt="Stotles" />;
    return (
      <div className={css.logoWrapper}>
        {disableLogoNav ? (
          stotles
        ) : (
          <a href={currentUser ? "/" : "https://www.stotles.com"}>{stotles}</a>
        )}
        {window.partner_logo_url && screenSize > MAX_SMALL_SCREEN_WARNING_SIZE && (
          <img
            src={window.partner_logo_url}
            height={smallLogoSize ? 28 : 38}
            className={css.topnavPartnerLogo}
          />
        )}
      </div>
    );
  }, [screenSize, textLogo, disableLogoNav, currentUser]);

  const showNav = currentUser && !hideMenuItems;

  if (isVerticalNavEnabled && showNav) {
    return <VerticalNav />;
  }

  return (
    <Layout.Header className={css.pageTopnav}>
      <div className={classNames(css.flexAlignCenter, css.logoAndMenu)}>
        {logo}
        {showNav ? <ProductNav /> : null}
      </div>
      {hideUserItems ? (
        displayCustomAction ? (
          displayCustomAction
        ) : (
          <SignOutButton user={currentUser} />
        )
      ) : (
        <UserItems user={currentUser} />
      )}
    </Layout.Header>
  );
}

export function MenuButton(props: TextButtonProps) {
  return <TextButton underline {...props} className={css.menuButton} />;
}

export function NavBarButton(props: TextButtonProps) {
  return <TextButton {...props} className={css.navBarButton} />;
}

export default AppLayoutNavbar;
