import React from "react";
import { FieldValues } from "react-hook-form";
import { TreeSelect as AntTreeSelect } from "antd5";

import { useBuyerCategories } from "lib/hooks/api/buyer/useBuyerCategories";
import { BuyerCategory } from "lib/StotlesApi";
import { TreeSelect, TreeSelectProps } from "./Inputs";

type TreeData = { value: string; label: string; children: TreeData[] };
function getChildren(data: BuyerCategory[], parentId: string): TreeData[] {
  const children = data.filter((cat) => cat.parent_category_id === parentId);
  return children.map((cat) => ({
    value: cat.id,
    label: cat.name,
    children: getChildren(data, cat.id),
  }));
}

type FieldProps<T extends FieldValues> = Omit<TreeSelectProps<T>, "treeData">;
export function BuyerTypeSelect<T extends FieldValues>(props: FieldProps<T>) {
  const { data, isLoading } = useBuyerCategories();

  const treeData =
    data
      ?.filter((cat) => !cat.parent_category_id)
      .map((cat) => {
        const children = getChildren(data, cat.id);
        return { value: cat.id, label: cat.name, children };
      }) || [];

  return (
    <TreeSelect
      treeData={treeData}
      treeCheckable={true}
      treeNodeFilterProp="label"
      showSearch
      showCheckedStrategy={AntTreeSelect.SHOW_PARENT}
      loading={isLoading}
      {...props}
    />
  );
}
