import React from "react";
import { FieldValues } from "react-hook-form";

import { OpportunityWorkflow_Transitions } from "../../lib/generated/app-service-gql/graphql";
import { useOpportunityWorkflow } from "../../lib/hooks/api/opportunities/useOpportunityWorkflow";
import { Select, SelectProps } from "../form_components/Inputs";
import { OpportunityStage } from "./OpportunityStage";

type OptionType = {
  label: string;
  value: string;
  color: string;
  iconColor: string;
  iconName: string;
};

type GroupedOptionsType = {
  label: string;
  value: string;
  options: OptionType[];
};

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

function processTransitions(transitions: OpportunityWorkflow_Transitions[]): GroupedOptionsType[] {
  return transitions.reduce<GroupedOptionsType[]>((acc, transition) => {
    const groupIndex = acc.findIndex((group) => group.value === transition.group);
    const option = {
      label: transition.stage.name,
      value: transition.stage.id,
      color: transition.stage.color,
      iconColor: transition.stage.iconColor,
      iconName: transition.stage.iconName,
    };
    if (groupIndex > -1) {
      acc[groupIndex].options.push(option);
    } else {
      acc.push({
        label: transition.group.replace("_", " "),
        value: transition.group,
        options: [option],
      });
    }
    return acc;
  }, []);
}

export function OpportunityStageSelect<T extends FieldValues>(props: FieldProps<T>) {
  const { data, isLoading } = useOpportunityWorkflow();

  const options = data ? processTransitions(data.transitions) : [];

  const renderOptionWithColor = (label: React.ReactNode, value: React.ReactNode) => {
    const labelAsString = label?.toString() || "";
    const selectedOption = options
      .flatMap((group) => group.options)
      .find((option) => option.value === value);
    const color = selectedOption?.color ?? "transparent";
    const iconColor = selectedOption?.iconColor ?? "white";
    const iconName = selectedOption?.iconName ?? "";

    return (
      <OpportunityStage
        label={labelAsString}
        color={color}
        iconColor={iconColor}
        iconName={iconName}
      />
    );
  };

  return (
    <Select
      {...props}
      loading={isLoading}
      options={options}
      labelRender={(props) => renderOptionWithColor(props.label, props.value)}
      optionRender={(oriOption) => renderOptionWithColor(oriOption.label, oriOption.value)}
    />
  );
}
