import React from "react";
import styled from "@emotion/styled";

import UIcon, { IconKey } from "../../lib/icons/untitled_ui/UIcon";
import { Flex } from "../../styles/utility-components";

type OpportunityStageType = {
  label: string;
  color: string;
  iconColor: string;
  iconName: string;
};

export function OpportunityStage({ label, iconName, color, iconColor }: OpportunityStageType) {
  const icon = iconName as IconKey; // TODO: Add fallback/check rather than casting

  return (
    <StageSelectTagContainer backgroundColor={color}>
      {iconName && <Icon icon={icon} color={iconColor}></Icon>}
      {label}
    </StageSelectTagContainer>
  );
}

const StageSelectTagContainer = styled(Flex)<{ backgroundColor: string }>(
  ({ backgroundColor, theme }) => ({
    display: "flex",
    alignItems: "center",
    color: theme.colors.sysTextDefault,
    gap: 8,
    width: "fit-content",
    minHeight: 26,
    padding: "2px 8px",
    backgroundColor,
    borderRadius: 2,
  }),
);

const Icon = styled(UIcon)({
  width: 12,
  height: 12,
  flexShrink: 0,
});
