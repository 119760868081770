import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { OpportunityQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const OPPORTUNITY_QUERY = graphql(`
  query opportunity($id: String!) {
    opportunity(id: $id) {
      id
      name
      description
      value
      assignedTo {
        guid
        firstName
        lastName
      }
      createdBy {
        guid
        firstName
        lastName
      }
      notices {
        guid
        name
        value
        currency
        stage
      }
      documents {
        id
        title
      }
      frameworks {
        id
        title
        stage
      }
      suppliers {
        name
        countryCode
        id
      }
      buyers {
        id
        name
        categoryAssignments {
          categoryName
          parentCategoryName
        }
        logoUrl
      }
      comments {
        id
        content
        author {
          guid
          firstName
          lastName
        }
        createdAt
        updatedAt
      }
      stage {
        id
        name
        color
      }
      contacts {
        id
        jobTitle
        firstName
        lastName
        email
        phone
        buyer {
          id
        }
      }
      closeDate
      createdAt
      updatedAt
    }
  }
`);

export type OpportunityDetails = OpportunityQuery["opportunity"];

export function useOpportunity(
  id: string,
  options?: UseQueryOptions<OpportunityQuery, unknown, OpportunityQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["opportunity", id],
    OPPORTUNITY_QUERY,
    [{ id }],
    options,
  );
  return { data: data?.opportunity, ...rest };
}
