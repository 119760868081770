import React, { useContext, useEffect, useRef, useState } from "react";
import WebViewer, { WebViewerInstance } from "@pdftron/webviewer";
import * as Sentry from "@sentry/react";
import classNames from "classnames";

import css from "./FileViewer.module.scss";

type FileViewerContextProviderProps = {
  children: React.ReactNode;
};

type ViewerContext = {
  instance: WebViewerInstance | null;
  setInstance: React.Dispatch<React.SetStateAction<WebViewerInstance | null>>;
};

type FileViewerProps = {
  documentUrl: string;
  fileExtension?: string;
  enableAnnotations?: boolean;
  disabledElements?: string[];
};

export default function FileViewer({
  documentUrl,
  fileExtension = "pdf",
  enableAnnotations = true,
  disabledElements,
}: FileViewerProps) {
  const viewer = useRef(null);
  const { instance, setInstance } = useFileViewerInstance();

  useEffect(() => {
    if (viewer.current === null) return;
    if (!instance) {
      WebViewer(
        {
          path: "/apryse",
          licenseKey: window.apryseLicenseKey,
          enableAnnotations,
          disabledElements,
        },
        viewer.current,
      )
        .then((instance) => {
          setInstance(instance);

          const { documentViewer } = instance.Core;
          documentViewer.addEventListener("documentLoaded", function () {
            documentViewer.zoomTo(1.0);
          });

          instance.UI.hotkeys.off(instance.UI.hotkeys.Keys.P);

          if (disabledElements && !disabledElements.includes("searchPanel")) {
            // Add Ctrl + F functionality
            const handleKeyDown = (e: KeyboardEvent) => {
              if (e.ctrlKey && e.key === "f") {
                e.preventDefault();
                const isSearchPanelOpen = instance.UI.isElementOpen("searchPanel");

                if (isSearchPanelOpen) {
                  instance.UI.closeElements(["searchPanel"]);
                } else {
                  instance.UI.openElements(["searchPanel"]);
                }
              }
            };

            window.addEventListener("keydown", handleKeyDown);

            // Clean up event listener on unmount
            return () => {
              window.removeEventListener("keydown", handleKeyDown);
            };
          }
        })
        .catch((error) => Sentry.captureException(error));
    }
  }, [disabledElements, enableAnnotations, instance, setInstance]);

  useEffect(() => {
    if (instance) {
      instance.UI.loadDocument(documentUrl, { extension: fileExtension });
    }
  }, [documentUrl, fileExtension, instance]);

  return <div className={classNames("webviewer", css.fileViewer)} ref={viewer}></div>;
}

const FileViewerContext = React.createContext<ViewerContext | undefined>(undefined);

export function FileViewerContextProvider({ children }: FileViewerContextProviderProps) {
  const [instance, setInstance] = useState<WebViewerInstance | null>(null);

  return (
    <FileViewerContext.Provider value={{ instance, setInstance }}>
      {children}
    </FileViewerContext.Provider>
  );
}

export function useFileViewerInstance() {
  const context = useContext(FileViewerContext);

  if (!context) {
    throw new Error("useFileViewerInstance must be used within a FileViewerContextProvider");
  }

  return {
    instance: context.instance,
    setInstance: context.setInstance,
  };
}
