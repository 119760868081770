import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { currencyFormat, numberFormatLong } from "components/app_layout/Typography/Numbers";
import {
  DEFAULT_DOCUMENT_FILTERS,
  DocumentFilters,
  documentsUrl,
  simplifiedDocumentTypes,
} from "components/documents/utils";
import {
  DEFAULT_FILTERS as DEFAULT_FRAMEWORK_FILTERS,
  FrameworkFilters,
  frameworksUrl,
} from "components/framework/utils";
import { generateNoticeSearchUrl } from "components/notices/utils";
import SkeletonText from "components/ui/skeleton/SkeletonText";
import TooltipComponent from "components/ui/tooltip/Tooltip";
import { useOpportunitiesBox } from "lib/hooks/api/homepage/useOpportunitiesBox";
import { useAllViews } from "lib/hooks/api/notices/views/useAllViews";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import UIcon, { IconKey } from "lib/icons/untitled_ui/UIcon";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { ALL_KEYWORDS_TOKEN } from "lib/types/models";
import { Box, DEFAULT_NOTICE_FILTERS, TypedFilters, WidgetTitle } from "./util";

/**
 * @deprecated Don't use this, outside of homepage. Preferably this wouldn't be done at all, but the alternative is matching on the name
 */
export const DEFAULT_VIEW_IDS = {
  openOpportunities: "b568b291-4567-496f-bbf8-8165002f2ce5",
  upcomingContractExpiries: "c735a82a-4fea-4a97-84a8-7f9638e64c25",
};

type OpportunityBoxProps = {
  title: string | null;
  icon: IconKey;
  link: string | null;
  tooltip?: string;
} & TypedFilters;

function Opportunities() {
  const { data: allViews } = useAllViews();

  const growthOrExpert = ["growth", "expert"].includes(window.currentUser?.subscription ?? "");

  const [recentViews] = useLocalStorage<string[]>("RECENT_VIEWS", []); // For growth/expert only

  let boxes = [] as OpportunityBoxProps[];
  if (growthOrExpert) {
    const mostRecentViewOrDefault = allViews?.find(
      (view) => view.id === (recentViews[0] ?? DEFAULT_VIEW_IDS.upcomingContractExpiries),
    );

    const frameworkFilters = {
      ...DEFAULT_FRAMEWORK_FILTERS,
      keywords: [ALL_KEYWORDS_TOKEN],
    } as FrameworkFilters;
    const documentsFilters = {
      ...DEFAULT_DOCUMENT_FILTERS,
      keywords: [ALL_KEYWORDS_TOKEN],
      categories: simplifiedDocumentTypes
        .filter((type) => type.label !== "Other")
        .map((type) => type.value),
    } as DocumentFilters;

    boxes = [
      {
        type: "NOTICE",
        title: mostRecentViewOrDefault?.name ?? null,
        icon: "clockRewind",
        filters: mostRecentViewOrDefault ?? DEFAULT_NOTICE_FILTERS,
        link: mostRecentViewOrDefault?.id
          ? generateNoticeSearchUrl({}, mostRecentViewOrDefault?.id)
          : null,
        tooltip: "Potential value of all contracts matching your signal settings.",
      },
      {
        type: "FRAMEWORK",
        title: "Frameworks",
        icon: "grid01",
        filters: frameworkFilters,
        link: frameworksUrl(frameworkFilters),
      },
      {
        type: "DOCUMENT",
        title: "Documents",
        icon: "box",
        filters: documentsFilters,
        link: documentsUrl(documentsFilters),
      },
    ];
  } else {
    boxes = [
      {
        type: "NOTICE",
        title: "Expiring contracts",
        icon: "file02",
        filters:
          allViews?.find((view) => view.id === DEFAULT_VIEW_IDS.upcomingContractExpiries) ??
          DEFAULT_NOTICE_FILTERS,
        link: generateNoticeSearchUrl({}, DEFAULT_VIEW_IDS.upcomingContractExpiries),
        tooltip: "Potential value of all contracts matching your signal settings.",
      },
      {
        type: "NOTICE",
        title: "Open tenders",
        icon: "file02",
        filters:
          allViews?.find((view) => view.id === DEFAULT_VIEW_IDS.openOpportunities) ??
          DEFAULT_NOTICE_FILTERS,
        link: generateNoticeSearchUrl({}, DEFAULT_VIEW_IDS.openOpportunities),
        tooltip: "Potential value of all contracts matching your signal settings.",
      },
    ];
  }

  return (
    <Flex column gap={16} flexWrap="wrap">
      <WidgetTitle>Find opportunities</WidgetTitle>
      <Flex gap={16} flexWrap="wrap" width="100%">
        {boxes.map((box) => (
          <OpportunityBox key={box.title} {...box} />
        ))}
      </Flex>
    </Flex>
  );
}

const OpportunityBox = (props: OpportunityBoxProps) => {
  const { title, icon, link, type, filters } = props;
  const { logEvent } = useTracking();

  const disabled = link === null; // Need to disable the parent so we can have a different cursor
  return (
    <OpportunityBoxContainer disabled={disabled}>
      <ContainerLink
        href={link ?? "#"}
        disabled={disabled}
        onClick={() =>
          logEvent(EventNames.findOpportunityStarted, {
            "Context source": "Homepage find opportunities",
            "Page source": "Homepage",
            ...(type === "NOTICE" && {
              "Data type": EventDataTypes.savedView,
              "Saved view name": filters.name,
              "Saved view id": filters.id,
            }),
            ...(type === "FRAMEWORK" && {
              "Data type": EventDataTypes.frameworks,
            }),
            ...(type === "DOCUMENT" && {
              "Data type": EventDataTypes.documents,
            }),
          })
        }
      >
        <Flex column gap={24}>
          <TitleContainer gap={12}>
            <IconBackground>
              <UIcon icon={icon} size={16} color="inherit" />
            </IconBackground>
            {title === null || (type === "NOTICE" && filters.id === DEFAULT_NOTICE_FILTERS.id) ? (
              <SkeletonText width="140px" fontSize={16} lineHeight={1.5} />
            ) : (
              <Text h3 ellipsis>
                {title}
              </Text>
            )}
            <UIcon icon="arrowRight" size={16} />
          </TitleContainer>
          <OpportunityBoxContent {...props} />
        </Flex>
      </ContainerLink>
    </OpportunityBoxContainer>
  );
};

const OpportunityBoxContent = (props: OpportunityBoxProps) => {
  const { type, tooltip } = props;
  const data = useOpportunitiesBox(props);

  const subtitleValue =
    data?.__typename === "NoticesSearch"
      ? currencyFormat(data?.values?.combined?.gbp ?? null, "GBP")
      : "";
  const subtitleSuffix = type === "NOTICE" ? "total value" : "matching signals";

  if (data === undefined) {
    return (
      <Flex column gap={8}>
        <CountContainer>
          <SkeletonText width="60px" fontSize={26} lineHeight={1.25} as="span" />
        </CountContainer>
        <Flex gap={4}>
          {type === "NOTICE" && <SkeletonText width="80px" fontSize={14} lineHeight={1.57} />}
          <Text color="sysTextPlaceholder">{subtitleSuffix}</Text>
          {tooltip && (
            <TooltipComponent content={tooltip}>
              <InfoButton>
                <UIcon icon="infoCircle" size={16} color="inherit" />
              </InfoButton>
            </TooltipComponent>
          )}
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex column gap={8}>
      <CountContainer>{numberFormatLong(data.totalResults)}</CountContainer>
      <Flex gap={4}>
        <Text color="sysTextPlaceholder">
          {subtitleValue} {subtitleSuffix}
        </Text>
        {tooltip && (
          <TooltipComponent content={tooltip}>
            <InfoButton>
              <UIcon icon="infoCircle" size={16} color="inherit" />
            </InfoButton>
          </TooltipComponent>
        )}
      </Flex>
    </Flex>
  );
};

const OpportunityBoxContainer = styled(Box)<{ disabled?: boolean }>(({ theme, disabled }) => ({
  display: "flex",
  flexGrow: 1,
  flexBasis: 0,
  minWidth: "min(260px, 100%)", // Need to force to 1 column to avoid text wrapping
  padding: 0,
  color: theme.colors.sysPrimaryDefault,

  // Can't do on the actual elemnents since we need to do hover on the whole box
  [`${TitleContainer} svg`]: {
    transition: "transform 0.2s",
  },
  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    borderColor: theme.colors.sysPrimaryHover,
    color: theme.colors.sysPrimaryHover,

    [`${TitleContainer}`]: {
      textDecoration: "underline",
      textDecorationColor: theme.colors.sysTextBlack,
      "& > svg": {
        transform: "translateX(8px)",
      },
    },
  },
  "&:active": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    borderColor: theme.colors.sysPrimaryPressed,
    color: theme.colors.sysPrimaryPressed,
  },

  // Really not disabled, but we need to disable the link AND have a different cursor so it's obvious
  ...(disabled && {
    cursor: "progress",
  }),
}));

const IconBackground = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.sysBackgroundAlternative,
  color: theme.colors.sysTextPlaceholder,
  borderRadius: "4px",
  padding: "8px",
  height: "32px",
  width: "32px",
}));

const TitleContainer = styled(Flex)(() => ({
  alignItems: "center",
}));

const ContainerLink = styled.a<{ disabled?: boolean }>(({ disabled }) => ({
  padding: "24px",
  width: "100%",
  ...(disabled && {
    pointerEvents: "none",
  }),
}));

const CountContainer = styled.p(({ theme }) => ({
  color: theme.colors.sysPrimaryDefault,
  lineHeight: 1.25,
  fontWeight: 700,
  fontSize: "24px",
  margin: 0,
}));

const InfoButton = styled.button(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  color: theme.colors.sysTextPlaceholder,
  display: "flex",
  alignItems: "center",
}));

export default Opportunities;
