import { SupplierMentionType } from "lib/types/graphQLEnums";
import {
  ALL_COMPETITORS_TOKEN,
  ALL_CPV_CODES_TOKEN,
  ALL_KEYWORDS_TOKEN,
  ALL_PARTNERS_TOKEN,
} from "lib/types/models";

export function supplierMentionTypeToLabel(type?: SupplierMentionType): string {
  switch (type) {
    case SupplierMentionType.MentionedInOnly:
      return "Mentioned in only";
    case SupplierMentionType.DirectlyAwarded:
      return "Directly awarded";
    default:
      return "";
  }
}

function labelCreator(label: string, allTokens: string[], items?: string[]) {
  if (!items || items.length === 0) {
    return "";
  }

  if (items.every((item) => allTokens.includes(item))) {
    return label;
  }

  let count = items.length;
  allTokens.forEach((token) => {
    if (items.includes(token)) {
      count--;
    }
  });

  return `${label} (${count})`;
}

export function supplierGuidsFilterToLabel(guids?: string[]): string {
  return labelCreator("Suppliers", [ALL_COMPETITORS_TOKEN, ALL_PARTNERS_TOKEN], guids);
}

export function keywordsFilterToLabel(keywords?: string[]): string {
  return labelCreator("Keywords", [ALL_KEYWORDS_TOKEN], keywords);
}

export function cpvCodeFilterToLabel(codes?: string[]): string {
  return labelCreator("CPV codes", [ALL_CPV_CODES_TOKEN], codes);
}
