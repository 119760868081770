import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { AdminTeamsQuery } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const adminTeams = graphql(`
  query adminTeams($companyId: String!) {
    adminTeams(companyId: $companyId) {
      id
      name
      isPrivate
      users {
        guid
        firstName
        lastName
      }
      feedSettingsId
    }
  }
`);

export type SimpleTeamObj = AdminTeamsQuery["adminTeams"][0];

/**
 * Fetches all teams for a company. Only those with admin permissions or contractors can call this API
 * @param companyId
 * @param options
 * @returns
 */
export function useAdminTeams(
  companyId: string,
  options?: UseQueryOptions<AdminTeamsQuery, unknown, AdminTeamsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["admin", "teams", companyId],
    adminTeams,
    [{ companyId }],
    {
      ...options,
    },
  );

  return { data: data?.adminTeams, ...rest };
}
