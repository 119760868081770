import { NoticeFilters } from "components/notices/utils";
import { FrameworkFilterOptions } from "components/record_search/types";
import { ColumnSettingFieldEnum } from "lib/generated/app-api";
import { SupplierMentionType } from "lib/types/graphQLEnums";

type SortField = "relevance_score" | "name" | "value" | "supplier" | "publish_date";

const validSortFields: SortField[] = [
  "relevance_score",
  "name",
  "value",
  "supplier",
  "publish_date",
];

export function isValidSortField(field?: string): SortField | undefined {
  if (field && validSortFields.includes(field as SortField)) {
    return field as SortField;
  }
  return undefined;
}

export const DEFAULT_CALL_OFF_FILTERS: NoticeFilters = {
  text: "",
  value: {},
  publishDate: {},
  sort: {
    field: "relevance_score",
    order: "DESC",
  },
  procurementStageQualifications: [],
  signals: [],
  assignee: [],
  suppliers: [],
  supplierSme: "",
  buyerListIds: [],
  buyers: [],
  buyerCategories: [],
  buyerCountryRegions: {
    countries: [],
    regions: [],
  },
  stage: [],
  relevanceScore: [],
  closeDate: {},
  expiryDate: {},
  cpvDimensions: [],
  frameworkIds: [],
  frameworkFilterOptions: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
  supplierMentionType: SupplierMentionType.MentionedOrDirectlyAwarded,
  keywords: [],
  excludeKeywords: [],
  cpvCodes: [],
};

export const DEFAULT_CALL_OFF_NOTICE_COLUMNS: ColumnSettingFieldEnum[] = [
  "relevance_score",
  "title",
  "suppliers",
  "value",
  "published_date",
];
