import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button as AntButton, ConfigProvider, Skeleton, Tooltip } from "antd5";
import classnames from "classnames";
import { Flex } from "styles/utility-components";

import BookmarkIcon from "components/actions/Bookmark";
import { TextLink } from "components/actions/Links";
import {
  convertNoticeFiltersToFrameworkFilters,
  frameworkDetailsUrl,
} from "components/framework/utils";
import { SendNoticeToCRM } from "components/integrations/SendNoticeToCRMButton";
import ShareModal from "components/modals/ShareModal";
import {
  getFrontendQualification,
  mapToTenderStage,
  NoticeFilters,
} from "components/notices/utils";
import { MarkAsNotRelevantButton } from "components/record_qualifications/QualificationActions";
import Button from "components/ui/button/Button";
import DropdownButton from "components/ui/dropdown_menu/DropdownButton";
import DropdownMenu from "components/ui/dropdown_menu/DropdownMenu";
import SaveRecordPopoverWrapper from "components/user_lists/SaveRecord";
import RecordStage from "lib/core_components/RecordStage";
import { TextHighlighter } from "lib/core_components/TextHighlighter";
import { NEW_HIGHLIGHT_LOGIC, OPPORTUNTIES, useVariableValue } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useFrameworkUrlInformation } from "lib/hooks/api/frameworks/useFrameworkUrlInformation";
import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { useNoticeListsForRecord } from "lib/hooks/api/notices/lists/useNoticeListsForRecord";
import { NoticeDetails } from "lib/hooks/api/notices/useNotice";
import { useRecordHighlighting } from "lib/hooks/useRecordHighlighting";
import { dotsHorizontal } from "lib/icons/untitled_ui/SVGs";
import { useDialogManager } from "lib/providers/DialogManager";
import { useRecordViewer } from "lib/providers/RecordViewer";
import { useSubscription } from "lib/providers/Subscription";
import { BlueSkeleton } from "lib/themes/BlueSkeleton";
import { sysTextBlack } from "lib/themes/colors";
import { EventNames, useTracking } from "lib/tracking";
import { BasicSignal, getSignalTypes, RecordListSummary, TenderStage } from "lib/types/models";
import { assertDefined, recordDetailsUrl } from "lib/utils";
import { allowCrmIntegrationFlag } from "lib/utils/allowCrmIntegrationFlag";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { FrameworkProcessType, NoticesStages as Stages } from "../../lib/types/graphQLEnums";
import { ScheduleReminderButton } from "../reminders/ScheduleReminderButton";
import { NoticeSeenBy } from "./SeenBy";

import css from "./NoticeHeader.module.scss";

function getRecordDate(notice: NoticeDetails) {
  switch (notice.stage) {
    case Stages.Awarded:
      return notice.expiryDate;
    case Stages.Open:
    case Stages.Closed:
      return notice.closeDate;
    default:
      return notice.publishDate;
  }
}

function getRelativeTimeLabel(stage: Stages, isFuture: boolean) {
  switch (stage) {
    case Stages.Awarded:
      return isFuture ? "Expiring" : "Expired";
    case Stages.Open:
    case Stages.Closed:
      return isFuture ? "Closing" : "Closed";
    default:
      return "Published";
  }
}

function getEmptyDateLabel(stage: Stages) {
  switch (stage) {
    case Stages.Awarded:
      return "expiry";
    case Stages.Open:
    case Stages.Closed:
      return "close";
    default:
      return "publish";
  }
}

export function getRelativeTimeFromNow(notice: NoticeDetails): string | null {
  assertDefined(notice.stage);

  const recordDate = getRecordDate(notice);

  if (!recordDate) {
    return `Unknown ${getEmptyDateLabel(notice.stage)} date`;
  }

  const timeFromNow = toHumanRelativeTimeFromNow(recordDate);

  const isFuture = new Date(recordDate).getTime() > Date.now();

  const label = getRelativeTimeLabel(notice.stage, isFuture);

  return `${label} ${timeFromNow}`;
}

function getNoticeListTooltip(noticeLists: RecordListSummary[]): string {
  if (noticeLists.length > 1) {
    return `Saved to "${noticeLists[0].name}" & ${noticeLists.length - 1} more`;
  }
  if (noticeLists.length === 1) {
    return `Saved to "${noticeLists[0].name}"`;
  }
  return "Save notice";
}

type SaveNoticeProps = {
  recordGuid: string;
  recordStage: TenderStage;
  recordName: string;
  recordValue: number | null;
  procurementStageId: string;
  buyerIds: string[];
  supplierIds: string[];
  signals: BasicSignal[];
  contextSource: "Title header" | "Record preview slide-out" | "In-table row" | "In-table dropdown";
};

function SaveNoticeButton({
  recordGuid,
  recordStage,
  recordName,
  recordValue,
  procurementStageId,
  buyerIds,
  supplierIds,
  signals,
  contextSource,
}: SaveNoticeProps): JSX.Element {
  const { data: recordLists } = useNoticeListsForRecord(recordGuid);

  return (
    <SaveRecordPopoverWrapper
      recordGuid={recordGuid}
      recordStage={recordStage}
      recordName={recordName}
      recordValue={recordValue}
      procurementStageId={procurementStageId}
      buyerIds={buyerIds}
      supplierIds={supplierIds}
      signals={signals}
      placement="bottom"
      contextSource={contextSource}
      trigger={
        <Tooltip title={getNoticeListTooltip(recordLists || [])}>
          <AntButton
            icon={<BookmarkIcon colour="blue" filled={!recordLists || recordLists?.length > 0} />}
            title="Save notice"
          />
        </Tooltip>
      }
    />
  );
}

function getFrameworkRelatedAlertMessage(processType?: FrameworkProcessType | null) {
  let message = "This notice is part of a framework process,";

  if (processType == FrameworkProcessType.FrameworkCallOff) {
    message = "This notice is a call-off as part of a framework,";
  }

  return message;
}

type NoticeHeaderProps = {
  notice: NoticeDetails;
  headerHighlights: string[];
  trackingContextName: SaveNoticeProps["contextSource"];
  disableTitleLink?: boolean;
  showStage?: boolean;
  headerClassName?: string;
  scrollClassName?: string;
  isTopOfPageVisible?: boolean;
  // Filters used here to translate active filters to eventually
  // become a search request for highlighting API in notice details page
  filters?: NoticeFilters;
};

export default function NoticeHeader({
  notice,
  filters,
  headerHighlights,
  trackingContextName,
  disableTitleLink,
  showStage = true,
  headerClassName,
  scrollClassName,
  isTopOfPageVisible,
}: NoticeHeaderProps) {
  assertDefined(notice.stage);
  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);

  const { data: providerTypeResponse } = useFindIntegrationProvider();
  const providerType = providerTypeResponse?.providerType;

  const { closeRecord } = useRecordViewer();

  const { logEvent } = useTracking();
  const dialogManager = useDialogManager();

  const subscription = useSubscription();
  const hasFrameworks =
    subscription.hasDataTypes("FRAMEWORKS") && FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);

  const isNewHighlightingLogicEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  // Legacy highlighting code
  const { recordTitle } = useRecordHighlighting(
    notice.guid,
    notice.name ?? "",
    notice.description ?? "",
  );

  const frameworkId = notice.relatedFrameworkProcess?.frameworkId;
  const frameworkProcessType = notice.relatedFrameworkProcess?.processType;
  const { data: framework, isLoading } = useFrameworkUrlInformation(frameworkId ?? "", {
    enabled: !!frameworkId && hasFrameworks,
  });

  const handleShareModalOpen = React.useCallback(() => {
    logEvent(EventNames.shareEmailModalOpen, { "Record GUIDs": [notice.guid] });
    void dialogManager.openDialog(ShareModal, {
      record: {
        guid: notice.guid,
        signals: notice.signals ?? [],
        stage: notice.stage,
        score: notice.score ?? 0,
      },
    });
  }, [dialogManager, notice, logEvent]);

  const headerClass = classnames(
    headerClassName,
    scrollClassName && !isTopOfPageVisible && scrollClassName,
  );

  const timeFromNowLabel = getRelativeTimeFromNow(notice);

  const dropdownItems = [
    <Flex key="share">
      <Button
        onClick={handleShareModalOpen}
        icon="share07"
        buttonType="tertiary"
        justifyContent="left"
        fullWidth={true}
        as="button"
        blackText
        iconColorOverride={sysTextBlack}
      >
        Share
      </Button>
    </Flex>,
    <SendNoticeToCRM
      key="send"
      containerClassName={css.crmContainer}
      notice={{
        guid: notice.guid,
        name: notice.name ?? "",
        value: notice.value ?? undefined,
        url: notice.url,
        closeDate: notice.closeDate ?? "",
        type: notice.entityType ?? undefined,
      }}
      type="tertiary"
      align="left"
      fullWidth={true}
    />,
  ];

  return (
    <div className={headerClass}>
      <div className={css.headerColumn}>
        <div className={css.recordHeaderContent}>
          <div className={css.titleContainer}>
            {showStage && (
              <div className={css.stage}>
                <RecordStage stage={mapToTenderStage(notice.stage)} />
                <div className={css.date}>
                  •<div>{timeFromNowLabel}</div>
                </div>
              </div>
            )}
            <div aria-label="Record link">
              <TextLink
                className={css.title}
                targetType="new-tab"
                to={recordDetailsUrl(
                  notice.guid,
                  isNewHighlightingLogicEnabled ? filters : undefined,
                )}
                disabled={disableTitleLink}
              >
                {/* TODO: When legacy code is deleted, just use  TextHighlighter component */}
                {isNewHighlightingLogicEnabled && notice.name ? (
                  <TextHighlighter content={notice.name} wordsToHighlight={headerHighlights} />
                ) : (
                  recordTitle
                )}
              </TextLink>
              <NoticeSeenBy
                seenByUsers={notice.seenByUsers}
                displayNumber={true}
                className={css.seenBy}
              />
            </div>
          </div>

          {!window.guestUser && (
            <div className={css.actions}>
              <SaveNoticeButton
                recordGuid={notice.guid}
                recordStage={mapToTenderStage(notice.stage)}
                recordName={notice.name ?? ""}
                recordValue={notice.value ?? null}
                procurementStageId={notice.procurementStage.id}
                buyerIds={notice.buyers.map((buyer) => buyer.guid)}
                supplierIds={notice.lots.reduce<string[]>(
                  (acc, lot) => acc.concat(lot.suppliers?.map((supplier) => supplier.guid) || []),
                  [],
                )}
                signals={notice.signals ?? []}
                contextSource={trackingContextName}
              />
              {isOpportunitiesEnabled ? (
                <>
                  <MarkAsNotRelevantButton
                    procurementStage={{
                      id: notice.procurementStage.id,
                      stage: notice.procurementStage.stage
                        ? notice.procurementStage.stage.toString()
                        : "",
                      qualification: notice.qualification
                        ? getFrontendQualification(notice.qualification)
                        : undefined,
                    }}
                    notice={{
                      guid: notice.guid,
                      name: notice.name || "--",
                      buyers: notice.buyers,
                    }}
                    //TODO: replace with signals
                    signalTypes={getSignalTypes([])}
                    score={notice.score}
                    contextSource={"In-row"}
                    onClick={closeRecord}
                  />
                  {providerType ? (
                    <DropdownMenu
                      trigger={<DropdownButton icon={dotsHorizontal} iconSize={16} />}
                      items={dropdownItems}
                      align="end"
                      width={170}
                      itemPadding={0}
                    />
                  ) : (
                    <ShareViaEmailButton onClick={handleShareModalOpen} />
                  )}
                </>
              ) : (
                <>
                  <ShareViaEmailButton onClick={handleShareModalOpen} />
                  {allowCrmIntegrationFlag() && (
                    <SendNoticeToCRM
                      notice={{
                        guid: notice.guid,
                        name: notice.name ?? "",
                        value: notice.value ?? undefined,
                        url: notice.url,
                        closeDate: notice.closeDate ?? "",
                        type: notice.entityType ?? undefined,
                      }}
                    />
                  )}
                  <ScheduleReminderButton
                    dataType="notice"
                    anchorDate={notice.expiryDate || notice.closeDate}
                    dataId={notice.guid}
                    dataTitle={notice.name ?? ""}
                    anchorType={notice.expiryDate ? "expiry" : "close"}
                  />
                </>
              )}
            </div>
          )}
        </div>
        {!!framework && (
          <Alert
            className={css.frameworkInfo}
            message={
              <span aria-label="Framework agreement info">
                <InfoCircleOutlined className={css.infoIcon} />
                {isLoading ? (
                  <ConfigProvider theme={BlueSkeleton}>
                    <Skeleton.Input active style={{ height: "16px", width: "100%" }} />
                  </ConfigProvider>
                ) : (
                  <>
                    {getFrameworkRelatedAlertMessage(frameworkProcessType)}{" "}
                    <TextLink
                      className={css.frameworkLink}
                      to={frameworkDetailsUrl(
                        framework.id,
                        isNewHighlightingLogicEnabled
                          ? convertNoticeFiltersToFrameworkFilters(filters)
                          : undefined,
                      )}
                    >
                      {framework.title}
                    </TextLink>
                  </>
                )}
              </span>
            }
            type="info"
          />
        )}
      </div>
    </div>
  );
}

function ShareViaEmailButton({ onClick }: { onClick: () => void }) {
  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);
  return (
    <Tooltip title="Share via email">
      <Button
        as="button"
        buttonType={isOpportunitiesEnabled ? "tertiary" : "secondary"}
        onClick={onClick}
        icon="share07"
        blackText
        iconColorOverride={isOpportunitiesEnabled ? sysTextBlack : undefined}
        title="Share via email"
      />
    </Tooltip>
  );
}
