import React from "react";
import styled from "@emotion/styled";
import { Flex } from "styles/utility-components";

import SendInviteModal from "components/modals/SendInviteModal";
import Signal from "lib/icons/Signal";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { useDialogManager } from "lib/providers/DialogManager";
import { routes, settingsRoutes } from "lib/routes";
import { EventData, EventNames, logEvent } from "lib/tracking";
import { WidgetTitle } from "./util";

const eventData = {
  "Context source": "Homepage quick links",
  "Page source": "Homepage",
} as EventData;

function QuickLinks() {
  const dialogManager = useDialogManager();

  return (
    <Flex column gap={16}>
      <WidgetTitle>Quick links</WidgetTitle>
      <Links>
        <Link
          href={settingsRoutes.subscription}
          onClick={() => logEvent(EventNames.paywallActioned, { ...eventData, Action: "Upgrade" })}
        >
          <UIcon icon="arrowUpRight" size={16} color="inherit" />
          <LinkText>Upgrade plan</LinkText>
        </Link>
        <Link as="button" onClick={() => dialogManager.openDialog(SendInviteModal, {})}>
          <UIcon icon="usersPlus02" size={16} color="inherit" />
          <LinkText>Add team members</LinkText>
        </Link>
        <Link
          href={settingsRoutes.signalSettings}
          onClick={() => logEvent(EventNames.relevanceScoreUpdateSettings, eventData)}
        >
          <Signal size={16} background="inherit" fill="inherit" />
          <LinkText>Change signal settings</LinkText>
        </Link>
        <Link href={settingsRoutes.integrations}>
          <UIcon icon="integration" size={16} color="inherit" />
          <LinkText>Setup an integration</LinkText>
        </Link>
        <Link
          href={routes.help}
          target="_blank"
          onClick={() =>
            logEvent(EventNames.clickedHelpCentre, {
              ...eventData,
              "Destination url": routes.help,
            })
          }
        >
          <UIcon icon="helpCircle" size={16} color="inherit" />
          <LinkText>Visit our help centre</LinkText>
        </Link>
      </Links>
    </Flex>
  );
}

const Links = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(216px, 1fr))",
  gap: "8px",
  marginLeft: "-8px",
});

const Link = styled.a(({ theme }) => ({
  display: "flex",
  gap: 8,
  padding: "4px 0 4px 8px",
  borderRadius: "4px",
  alignItems: "center",
  textWrap: "nowrap",
  color: theme.colors.sysTextDefault,
  "&:hover": {
    color: theme.colors.sysTextDefault,
    backgroundColor: theme.colors.sysPrimarySubtle,
  },
  // Below are for the button link
  border: 0,
  backgroundColor: theme.colors.sysBackgroundDefault,
  cursor: "pointer",
}));

const LinkText = styled.span({
  flexShrink: 10, // Ensures the icon doesn't shrink
});

export default QuickLinks;
