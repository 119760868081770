import React, { useCallback, useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { Button, Input } from "antd5";

import { useDebouncedValue } from "lib/debounce";
import { useSignalSettingsGQL } from "lib/hooks/api/teams/useSignalSettingsGQL";
import { ALL_CPV_CODES_TOKEN } from "lib/types/models";
import { isArrayInArray, simpleArrayDedupe } from "lib/utils";
import { Option } from "./dropdown_list_selects/ClassificationListSelect";
import { getCpvPrefix } from "./dropdown_list_selects/cpv_code_selects/cpv_code_utils";
import CpvDropdown from "./dropdown_list_selects/cpv_code_selects/CpvDropdown";
import { useCpvTree } from "./dropdown_list_selects/cpv_code_selects/useCpvTree";
import SelectionTags from "./dropdown_list_selects/SelectionTags";
import { SelectProps } from "./Inputs";
import SaveSignalsButton from "./SaveSignalsButton";

import css from "./CpvCodeSelect.module.scss";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  hideLabel?: boolean;
  hideClear?: boolean;
};

function CpvCodeSelect<T extends FieldValues>(props: FieldProps<T>) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);
  const [isOpen, setIsOpen] = useState(false);

  const { hideLabel, hideClear } = props;

  const { field } = useController(props);

  const { value, onChange } = field;

  const { data: signalSettings, isLoading: isSignalsLoading } = useSignalSettingsGQL();

  /**
   * An array of code strings that come from the user's signal settings
   */
  const signalCodes = useMemo((): string[] => {
    if (!signalSettings?.cpvCodes) {
      return [];
    }
    return signalSettings.cpvCodes;
  }, [signalSettings?.cpvCodes]);

  const { cpvTree, allCpvCodes, signalCpvCodes, isLoading } = useCpvTree(signalCodes);

  /**
   * Basically the same as the actual filter contents, just removes the ALL_CPV_CODES_TOKEN for display purposes
   */
  const selectedCpvCodes = useMemo((): string[] => {
    if (!value || !Array.isArray(value)) {
      return [];
    }

    // We can safely assume that the value is an array of strings
    const valueArray = value as string[];

    // Just in case, we will make absolutely sure that if this token is present in the array,
    // cpv signals are added for sure and the token is removed
    if (valueArray.includes(ALL_CPV_CODES_TOKEN)) {
      return simpleArrayDedupe([...valueArray, ...signalCodes]).filter(
        (t: string) => t !== ALL_CPV_CODES_TOKEN,
      );
    }
    return value;
  }, [signalCodes, value]);

  const displayedCpvTags = useMemo((): Option[] => {
    if (!selectedCpvCodes || !allCpvCodes) {
      return [];
    }

    // get the label for the selected cpv codes
    return selectedCpvCodes.map((v: string) => {
      const code = allCpvCodes.find((c) => c.code === v);
      return {
        label: `${code?.code}: ${code?.name}`,
        value: v,
      };
    });
  }, [selectedCpvCodes, allCpvCodes]);

  /**
   * A bit of an extension onto the normal onChange function, this will add or remove the ALL_KEYWORDS_TOKEN
   * from the filter value, depending on whather or not all keywords are selected
   */
  const onCpvCodeChange = useCallback(
    (newCpvCodes: string[]) => {
      const containsAllCpvCodes = signalCodes.every((s) => newCpvCodes.includes(s));

      if (containsAllCpvCodes) {
        newCpvCodes.push(ALL_CPV_CODES_TOKEN);
      } else {
        newCpvCodes = newCpvCodes.filter((t: string) => t !== ALL_CPV_CODES_TOKEN);
      }

      onChange(newCpvCodes);
    },
    [signalCodes, onChange],
  );

  return (
    <div
      aria-label="cpvCodeSelect"
      onBlur={(e) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
          return;
        }
        setIsOpen(false);
      }}
    >
      <div className={css.filterLabelDiv} aria-label="filterLabel" data-label={!hideLabel}>
        {!hideLabel && <span>CPV Codes</span>}
        {selectedCpvCodes.length > 0 && !hideClear && (
          <Button className={css.clearButton} onClick={() => onChange([])}>
            Clear
          </Button>
        )}
      </div>

      <Input
        aria-label="cpvSearchInput"
        className={css.inputField}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        placeholder={isOpen ? undefined : "Search CPV Codes"}
        onChange={(e) => {
          const newValue = e.currentTarget.value.trim();
          if (newValue.length === 0 && searchText.length > 0) {
            setIsOpen(false);
          }
          if (newValue.length > 0 && !isOpen) {
            setIsOpen(true);
          }
          setSearchText(e.currentTarget.value.trim());
        }}
      />
      <CpvDropdown
        aria-label="cpvDropdown"
        textSearch={debouncedText}
        isOpen={isOpen}
        cpvTree={cpvTree}
        value={selectedCpvCodes}
        isLoading={isSignalsLoading || isLoading}
        allCpvCodes={allCpvCodes}
        signalCpvCodes={signalCpvCodes}
        signalCodeIds={signalCodes}
        onChange={onCpvCodeChange}
      />
      {!isOpen && (
        <>
          <SelectionTags
            selections={displayedCpvTags}
            handleRemoval={(removedSelection: Option) => {
              const prefix = getCpvPrefix(removedSelection.value);
              const codesToRemove = field.value.filter((code: string) => code.startsWith(prefix));
              const updateValue = field.value.filter((v: string) => !codesToRemove.includes(v));
              onCpvCodeChange(updateValue);
            }}
            // hide for now while we test with users
            // TODO: cleanup once we decide to keep or remove
            // icon={<Signal />}
            iconedSelectionIds={signalCodes}
          />
          {/* Only show button if there is a cpv code that isn't in the existing cpv code signals */}
          {!isArrayInArray(selectedCpvCodes, signalCodes) && (
            <SaveSignalsButton
              signalType="CPV code"
              existingSignalValues={signalCodes}
              addedSignalValues={selectedCpvCodes}
            />
          )}
        </>
      )}
    </div>
  );
}

export default CpvCodeSelect;
