import React from "react";
import styled from "@emotion/styled";

import RecordStage from "../../../lib/core_components/RecordStage";
import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import { useRecordViewer } from "../../../lib/providers/RecordViewer";
import { Flex, Text } from "../../../styles/utility-components";
import { Currency } from "../../app_layout/Typography";
import { mapToTenderStage } from "../../notices/utils";
import { opportunitySavedCardContainer } from "./shared/styles";
import OpportunitySavedEmptyState from "./OpportunitySavedEmptyState";

type Props = {
  notices: OpportunityDetails["notices"];
};

export function OpportunityNotices({ notices }: Props) {
  const { viewRecord } = useRecordViewer();

  const handleAddNotice = () => {
    window.location.href = "/notices";
  };

  const handleView = (guid: string) => () => {
    viewRecord(guid, { "Context source": "Opportunity details" });
  };

  return (
    <Flex column gap={16}>
      {notices.map((notice) => (
        <NoticeCard key={notice.guid} notice={notice} onView={handleView(notice.guid)} />
      ))}
      <OpportunitySavedEmptyState entity="notice" onClick={handleAddNotice} />
    </Flex>
  );
}

function NoticeCard({
  notice,
  onView,
}: {
  notice: OpportunityDetails["notices"][0];
  onView: () => void;
}) {
  return (
    <Container onClick={onView}>
      <Flex column gap={16}>
        {notice.stage && <RecordStage stage={mapToTenderStage(notice.stage)} />}
        <Flex column gap={2}>
          <Text>{notice.name || "Untitled Notice"}</Text>
          {notice.currency && <Currency value={notice.value} currency={notice.currency} />}
        </Flex>
      </Flex>
    </Container>
  );
}

const Container = styled(Flex)(({ theme }) => ({
  height: 121,

  ...opportunitySavedCardContainer(theme),
}));
