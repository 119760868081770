import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { App, Button } from "antd5";

import SaveSignalsModal, {
  HIDE_SAVE_CPV_CODE_MODAL,
  HIDE_SAVE_KEYWORD_MODAL,
  SaveSignalsModalProps,
} from "components/modals/SaveSignalsModal";
import { useUpdateSignalSettings } from "lib/hooks/api/signals/useUpdateSignalSettings";
import { ExternalLink } from "lib/icons/ExternalLink";
import Signal from "lib/icons/Signal";
import { useDialogManager } from "lib/providers/DialogManager";
import { white } from "lib/themes/colors";
import { EventNames, logEvent } from "lib/tracking";
import { capitalize, simpleArrayDedupe } from "lib/utils";

import css from "./SaveSignalsButton.module.scss";

type Props = {
  signalType: "keyword" | "CPV code";
  existingSignalValues: string[];
  addedSignalValues: string[];
};
export default function SaveSignalsButton({
  signalType,
  existingSignalValues,
  addedSignalValues,
}: Props) {
  const { message } = App.useApp();
  const dialogManager = useDialogManager();

  const { mutate: updateSignalSettings, isLoading: isUpdatingSignalSettings } =
    useUpdateSignalSettings({
      onSuccess: (_) => {
        logEvent(EventNames.recordQueryUpdated, {
          "Context source": `${capitalize(signalType)} filter`,
          "Action type": "Added",
          [`New ${signalType}s`]: addedSignalValues,
          "Signal type": capitalize(signalType),
        });
        void message.success({
          content: (
            <>
              Signal settings updated
              <Button
                type="primary"
                className={css.viewSigSettings}
                icon={<ExternalLink fill={white} className={css.external} />}
                target="_blank"
                href="/account-management/feed-settings"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                View signal settings
              </Button>
            </>
          ),
          duration: 3,
          icon: <CheckCircleTwoTone />,
        });
      },
      onError: (_) => {
        message.error(
          `Failed to update ${signalType} signals. Please contact an admin if this issue persists.`,
        );
      },
    });

  const updateSignals = (newSignalVals: string[]) => {
    if (signalType === "keyword") {
      updateSignalSettings({ input: { keywords: newSignalVals } });
    } else if (signalType === "CPV code") {
      updateSignalSettings({ input: { cpvCodes: newSignalVals } });
    }
  };

  /**
   * Depending on user's history, either opens an informative modal, or
   *  just saves/unsaves the free text keyword(s) immediately
   * @param type
   * @param savedGuids
   */
  const saveSignalValues = () => {
    // Make sure we maintain the previous keywords and add the new free-text ones
    const newSignalVals = simpleArrayDedupe(existingSignalValues.concat(addedSignalValues));
    const hideModalKey =
      signalType === "keyword" ? HIDE_SAVE_KEYWORD_MODAL : HIDE_SAVE_CPV_CODE_MODAL;

    if (localStorage.getItem(hideModalKey) === "true") {
      updateSignals(newSignalVals);
    } else {
      void dialogManager.openDialog(SaveSignalsModal, {
        signalType,
        onConfirm: () => updateSignals(newSignalVals),
      } as Omit<SaveSignalsModalProps, "isOpen" | "onClose">);
    }
  };

  return (
    <Button
      className={css.saveSignalBtn}
      onClick={saveSignalValues}
      loading={isUpdatingSignalSettings}
    >
      <Signal className={css.signalIcon} size={16} label="signalIcon" />
      Save as signals
    </Button>
  );
}
