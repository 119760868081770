import React from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Card } from "antd5";

import { EventNames, useTracking } from "lib/tracking";
import { BetaTag } from "../../lib/core_components/Labels";
import { useBids } from "../../lib/hooks/api/bid/bid";
import { stars2 } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { grey0 } from "../../lib/themes/colors";

import css from "./AutoQualifyCard.module.scss";

export function AutoQualifyCard({
  noticeId,
  procurementStageId,
}: {
  noticeId: string;
  procurementStageId: string;
}) {
  const isEnabled = useVariableValue("notice-auto-qualification", false);

  const { logEvent } = useTracking();

  const { data: bidData } = useBids(
    {
      procurementStageIds: [procurementStageId],
    },
    {
      enabled: isEnabled,
    },
  );

  if (!isEnabled) {
    return null;
  }

  return (
    <div>
      <Card className={`${css.upperCard}`}>
        <div className={css.upperCardContent}>
          <h2 className={css.mediumTitle}>Auto-qualify</h2>
          <BetaTag />
        </div>
      </Card>
      <Card className={css.lowerCard}>
        <div className={css.lowerCardContent}>
          {bidData?.results && bidData.results.length > 0 ? (
            <>
              <p>Auto-qualification is complete on this tender.</p>
              <div>
                <Button
                  onClick={() => {
                    logEvent(EventNames.qualificationReportActioned, {
                      "Report status": "Existing",
                      "Record GUID": noticeId,
                    });
                  }}
                  href={`/records/${noticeId}/bid_qualification/${bidData.results?.[0].id}`}
                  target="_blank"
                  type="primary"
                  icon={<UIcon svg={stars2} color={grey0} size={16} />}
                >
                  View qualification report
                </Button>
              </div>
            </>
          ) : (
            <>
              <p>
                Make a quick bid/no bid decision. Auto-qualify reads your tender documents and
                generates a qualification report.
              </p>
              <div>
                {/* ToDo: use a wouter link in the right context so that the whole app doesn't reload */}
                <Button
                  type="primary"
                  onClick={() => {
                    logEvent(EventNames.qualificationReportActioned, {
                      "Report status": "New",
                      "Record GUID": noticeId,
                    });
                  }}
                  target="_blank"
                  href={`/records/${noticeId}/bid_qualification`}
                  icon={<UIcon svg={stars2} color={grey0} size={16} />}
                >
                  Run auto-qualify
                </Button>
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  );
}
