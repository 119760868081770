import * as React from "react";

import { BetaIcon } from "lib/core_components/Labels";
import { ProviderTypes } from "lib/generated/integration-api";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { providerIconColorMap, providerIconKeyMap } from "./ProviderButtons";

import css from "./IntegrationModalHeader.module.scss";

type Props = {
  providerType: ProviderTypes;
  title?: string;
  subtitle?: string;
};

export function IntegrationModalHeader({ providerType, title, subtitle }: Props) {
  return (
    <div className={css.modalHeader}>
      <UIcon
        icon={providerIconKeyMap[providerType]}
        color={providerIconColorMap[providerType]}
        size={24}
      />
      <div>
        {title && (
          <h1>
            {title} <BetaIcon />
          </h1>
        )}
        {subtitle && <h2>{subtitle}</h2>}
      </div>
    </div>
  );
}
