import React, { useState } from "react";
import styled from "@emotion/styled";

import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextSecondary } from "../../../lib/themes/colors";
import { Flex, Text } from "../../../styles/utility-components";
import DocumentDrawer from "../../document_details/DocumentDrawer";
import { DEFAULT_DOCUMENT_FILTERS } from "../../documents/utils";
import { opportunitySavedCardContainer, opportunitySavedCardIcon } from "./shared/styles";
import OpportunitySavedEmptyState from "./OpportunitySavedEmptyState";

type Props = {
  documents: OpportunityDetails["documents"];
};

export default function OpportunityDocuments({ documents }: Props) {
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>();

  const handleAddDocument = () => {
    window.location.href = "/documents";
  };

  const handleViewDocument = (id: string) => () => {
    setSelectedDocumentId(id);
  };

  return (
    <Flex column gap={16}>
      {documents.map((document) => (
        <DocumentCard
          key={document.id}
          document={document}
          onView={handleViewDocument(document.id)}
        />
      ))}
      <OpportunitySavedEmptyState entity="document" onClick={handleAddDocument} />
      <DocumentDrawer
        open={!!selectedDocumentId}
        onClose={() => setSelectedDocumentId(undefined)}
        documentId={selectedDocumentId ?? undefined}
        filters={DEFAULT_DOCUMENT_FILTERS}
      />
    </Flex>
  );
}

function DocumentCard({
  document,
  onView,
}: {
  document: OpportunityDetails["documents"][0];
  onView: () => void;
}) {
  return (
    <Container key={document.id} onClick={onView}>
      <Flex gap={12}>
        <Icon>
          <UIcon icon="file02" color={sysTextSecondary} />
        </Icon>
        <Text>{document.title || "Untitled Document"}</Text>
      </Flex>
    </Container>
  );
}

const Container = styled(Flex)(({ theme }) => ({
  height: 64,

  ...opportunitySavedCardContainer(theme),
}));

const Icon = styled(Flex)(({ theme }) => ({
  ...opportunitySavedCardIcon(theme),
}));
