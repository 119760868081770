import * as React from "react";

import {
  convertNoticeFiltersToFrameworkFilters,
  frameworkDetailsUrl,
} from "components/framework/utils";
import { NoticeFilters } from "components/notices/utils";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum as ProcessType } from "lib/generated/app-api";
import { useFrameworkUrlInformation } from "lib/hooks/api/frameworks/useFrameworkUrlInformation";

import css from "./FrameworkCell.module.scss";

type Props = {
  processType: ProcessType;
  frameworkId: string;
  frameworkName?: string;
  filters?: NoticeFilters;
};
export function FrameworkCell({
  processType,
  frameworkId,
  frameworkName,
  filters,
}: Props): JSX.Element | null {
  // TODO: Remove query below once the old notices table is removed
  const { data: framework, isLoading } = useFrameworkUrlInformation(frameworkId, {
    enabled: !frameworkName,
  });

  const frameworkTitle = frameworkName ?? framework?.title;

  if (!frameworkName && isLoading) {
    return <CentredSpinner />;
  }

  return frameworkTitle ? (
    <div className={css.frameworkColumnContainer}>
      <EllipsisTooltipTextLink
        fullText={frameworkTitle}
        linkProps={{
          to: frameworkDetailsUrl(frameworkId, convertNoticeFiltersToFrameworkFilters(filters)),
          className: css.titleText,
        }}
        linkText={frameworkTitle}
        tooltipProps={{ overlayClassName: css.tooltip }}
        containerClassname={css.frameworkTitle}
      />
      <span className={css.processTypeCell}>
        {processType === ProcessType.Framework ? "Framework agreement" : "Call-off"}
      </span>
    </div>
  ) : null;
}
