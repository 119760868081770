import React, { useContext } from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button } from "antd5";

import { useDialogManager } from "lib/providers/DialogManager";
import { EventNames, TrackingContext, TrackingProvider, useTracking } from "lib/tracking";
import { OPPORTUNTIES } from "../../../lib/featureFlags/generated/dvcVariableTypes";
import { useEntityOpportunities } from "../../../lib/hooks/api/opportunities/useEntityOpportunities";
import { UIcon } from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextWhite } from "../../../lib/themes/colors";
import { SaveToDrawer } from "./SaveToDrawer";
import { SharedSaveProps } from "./types";

type Props = SharedSaveProps & { listCount?: number; compactDisplay?: boolean };

export function SaveToButton(props: Props) {
  const { logEvent } = useTracking();
  const { data: inheritedData } = useContext(TrackingContext);

  const isOpportunitiesEnabled = useVariableValue(OPPORTUNTIES, false);
  const { isLoading, data } = useEntityOpportunities(props.entityId, {
    enabled: isOpportunitiesEnabled,
  });
  const listCount = props.listCount ?? 0;
  const totalSaved = data.length + listCount;
  const isSaved = totalSaved > 0;
  const dialogManager = useDialogManager();

  if (!isOpportunitiesEnabled) {
    return null;
  }

  return (
    <Button
      type="primary"
      onClick={(e) => {
        e.stopPropagation();
        void dialogManager.openDialog(
          ({ isOpen, onClose }) => (
            <TrackingProvider data={{ ...inheritedData }}>
              <SaveToDrawer {...props} isOpen={isOpen} onClose={() => onClose?.(undefined)} />
            </TrackingProvider>
          ),
          {},
        );
        logEvent(EventNames.saveDataClicked, { "Data type": props.entityType.toUpperCase() });
      }}
      loading={isLoading}
      icon={
        <UIcon
          icon="bookmark"
          color={sysTextWhite}
          fill={isSaved ? sysTextWhite : undefined}
          size={16}
        />
      }
    >
      {!props.compactDisplay && (isSaved ? `Saved (${totalSaved})` : "Save")}
    </Button>
  );
}
