import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { filterObject, getOpportunityStageById } from "components/opportunities/utils";
import { EventNames, useTracking } from "lib/tracking";
import { graphql } from "../../../generated/app-service-gql";
import {
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";
import { useOpportunityWorkflow } from "./useOpportunityWorkflow";

type TrackingData = { startStageId?: string; buyerNames?: string[] };

const UPDATE_OPPORTUNITY_MUTATION = graphql(`
  mutation updateOpportunity($opportunity: OpportunityUpdateInput!) {
    updateOpportunity(opportunity: $opportunity) {
      id
      name
      description
      createdAt
      updatedAt
      value
      closeDate
    }
  }
`);

export function useUpdateOpportunity(
  options: UseMutationOptions<
    UpdateOpportunityMutation,
    unknown,
    UpdateOpportunityMutationVariables,
    unknown
  >,
  trackingData?: TrackingData,
) {
  const { logEvent } = useTracking();
  const { data: workflow } = useOpportunityWorkflow();

  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(UPDATE_OPPORTUNITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["opportunities"]);
      void queryClient.invalidateQueries(["opportunity", variables.opportunity.id]);
      options?.onSuccess?.(data, variables, context);

      const trackedFields = filterObject(variables.opportunity, [
        "name",
        "description",
        "assignedToId",
        "stageId",
        "value",
        "closeDate",
      ]);

      for (const [key, value] of Object.entries(trackedFields)) {
        value !== undefined &&
          logEvent(EventNames.opportunityDetailsUpdated, {
            "Field updated": keyToLabel[key],
            "Updated field content": value,
          });

        const startStageId = trackingData?.startStageId;

        if (key === "stageId" && startStageId) {
          const stageTransitions = workflow?.transitions ?? [];

          const startOpportunityStage = getOpportunityStageById(startStageId, stageTransitions);
          const endOpportunityStage = getOpportunityStageById(value as string, stageTransitions);

          const isLostStage = endOpportunityStage?.stage.name === "Lost";

          const isForwardProgression =
            (endOpportunityStage?.order ?? 0) > (startOpportunityStage?.order ?? 0);

          if (isForwardProgression && !isLostStage) {
            logEvent(EventNames.opportunityProgressed, {
              "Start opportunity stage name": startOpportunityStage?.stage.name,
              "End opportunity stage name": endOpportunityStage?.stage.name,
              "Start opportunity stage index": startOpportunityStage?.order,
              "End opportunity stage index": endOpportunityStage?.order,
              "Opportunity GUID": variables.opportunity.id,
              "Opportunity value": variables.opportunity.value,
              "Buyer names": trackingData?.buyerNames,
              "Opportunity stage group name": endOpportunityStage?.group,
            });
          }
        }
      }
    },
  });
  return { data, ...rest };
}

const keyToLabel: Record<string, string> = {
  name: "Title",
  description: "Description",
  assignedToId: "Assignee",
  stageId: "Stage",
  value: "Value",
  closeDate: "Close date",
};
