import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";

import { Text, TextProps } from "../../../styles/utility-components";

interface EditableTextProps {
  textProps: TextProps;
  placeholder?: string;
  emptyText?: string;
  onSave?: (value: string) => void;
}

export default function EditableText({
  textProps,
  placeholder,
  emptyText = "Empty text",
  onSave,
}: EditableTextProps) {
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState("");

  const handleClick = () => {
    setIsEditing(true);
    setValue(textProps.children?.toString() || "");
  };

  const handleChange = (val: React.ChangeEvent<HTMLTextAreaElement> | FieldValues) => {
    setValue(typeof val === "object" && "target" in val ? val.target.value : val.toString());
  };

  const handleSave = () => {
    setIsEditing(false);

    if (value.trim() === "") {
      return;
    }

    if (value === textProps.children?.toString()) {
      return;
    }

    setIsEditing(false);
    onSave?.(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const textToDisplay = () => {
    if (textProps.children?.toString() === "") {
      return (
        <EmptyText body color={theme.colors.sysTextSecondary}>
          {emptyText}
        </EmptyText>
      );
    }

    return <Text {...textProps} />;
  };

  return (
    <>
      {isEditing ? (
        <StyledTextArea
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleSave}
          rows={1}
          placeholder={placeholder}
          style={{ height: "auto" }}
          ref={(textarea) => {
            if (textarea) {
              textarea.style.height = "auto";
              textarea.style.height = `${textarea.scrollHeight}px`;
            }
          }}
        />
      ) : (
        <TextContainer onClick={handleClick}>{textToDisplay()}</TextContainer>
      )}
    </>
  );
}

const StyledTextArea = styled.textarea({
  width: "100%",
  padding: "8px 12px",
  fontSize: "14px",
  lineHeight: "22px",
  border: "1px solid #d9d9d9",
  borderRadius: "4px",
  backgroundColor: "#fff",
  transition: "all 0.2s ease-in-out",
  outline: "none",
  resize: "none",
  overflow: "hidden",
  minHeight: "38px",

  "&:hover": {
    borderColor: "#40a9ff",
  },

  "&:focus": {
    borderColor: "#1890ff",
    boxShadow: "0 0 0 2px rgba(24, 144, 255, 0.2)",
  },
});

const EmptyText = styled(Text)(({ theme }) => ({
  color: theme.colors.sysTextSecondary,

  fontStyle: "italic",
}));

const TextContainer = styled.div(({ theme }) => ({
  cursor: "pointer",
  padding: "8px 12px",

  "&:hover": {
    borderRadius: "4px",
    backgroundColor: theme.colors.sysPrimarySubtle,
  },

  transition: "all 0.2s ease-in-out",
}));
