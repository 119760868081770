import React, { useState } from "react";
import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";
import { App, Mentions } from "antd5";

import UserInitials from "components/comments/UserInitials";
import Button from "components/ui/button/Button";
import { assertCurrentUser } from "lib/currentUser";
import { OpportunityQuery } from "lib/generated/app-service-gql/graphql";
import { useCreateComment } from "lib/hooks/api/comments/useCreateComment";
import { useDeleteComment } from "lib/hooks/api/comments/useDeleteComment";
import { useUpdateComment } from "lib/hooks/api/comments/useUpdateComment";
import { useOpportunity } from "lib/hooks/api/opportunities/useOpportunity";
import { useUsers } from "lib/hooks/api/useUsers";
import { CommentObjectType } from "../../../lib/types/graphQLEnums";
import { Flex, Spacer, Text } from "../../../styles/utility-components";
import { CommentComponent } from "./CommentComponent";

type OpportunityCommentsSidebarProps = {
  opportunityId: string;
};

export default function OpportunityCommentsSidebar({
  opportunityId,
}: OpportunityCommentsSidebarProps) {
  const { data: opportunity } = useOpportunity(opportunityId);
  if (!opportunity) {
    throw new Error("Opportunity not found");
  }

  const { data: users } = useUsers();

  const userOptions = users?.map((user) => ({
    value: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const { message } = App.useApp();
  const [newComment, setNewComment] = useState("");
  const [editCommentId, setEditCommentId] = useState("");
  const [editedComment, setEditedComment] = useState("");
  const user = assertCurrentUser();

  const queryClient = useQueryClient();

  const { mutate: createComment } = useCreateComment({
    onSuccess: async (data) => {
      queryClient.setQueryData(
        ["opportunity", opportunity.id],
        (prevData: OpportunityQuery | undefined) => {
          if (!prevData) return prevData;
          return {
            opportunity: {
              ...prevData.opportunity,
              comments: [
                {
                  id: data.createComment.id,
                  content: data.createComment.content,
                  author: {
                    guid: user.guid,
                    firstName: user.first_name,
                    lastName: user.last_name,
                  },
                  createdAt: data.createComment.createdAt,
                  updatedAt: data.createComment.updatedAt,
                },
                ...prevData.opportunity.comments,
              ],
            },
          };
        },
      );

      message.success("Comment added successfully");

      setNewComment("");
    },
  });

  const { mutate: deleteComment } = useDeleteComment({
    onSuccess: (data) => {
      // TODO: Add amplitude tracking when designs are finalised
      queryClient.setQueryData(
        ["opportunity", opportunity.id],
        (prevData: OpportunityQuery | undefined) => {
          if (!prevData) return prevData;
          return {
            opportunity: {
              ...prevData.opportunity,
              comments: prevData.opportunity.comments.filter(
                (comment) => comment.id !== data.deleteComment.id,
              ),
            },
          };
        },
      );
      message.success("Comment deleted successfully");
    },
  });

  const { mutate: updateComment } = useUpdateComment({
    onSuccess: (data) => {
      queryClient.setQueryData(
        ["opportunity", opportunity.id],
        (prevData: OpportunityQuery | undefined) => {
          if (!prevData) return prevData;
          return {
            opportunity: {
              ...prevData.opportunity,
              comments: prevData.opportunity.comments.map((comment) =>
                comment.id === data.updateComment.id
                  ? {
                      ...comment,
                      content: data.updateComment.content,
                      updatedAt: data.updateComment.updatedAt,
                    }
                  : comment,
              ),
            },
          };
        },
      );
      message.success("Comment updated successfully");
    },
  });

  const handleSubmit = () => {
    createComment({
      request: {
        content: newComment,
        objectId: opportunity.id,
        objectType: CommentObjectType.Opportunity,
      },
    });

    setNewComment("");
  };

  const handleDelete = (id: string) => {
    deleteComment({ request: { id } });
  };

  const enabledEdit = (id: string) => {
    setEditCommentId(id);
    setEditedComment(opportunity.comments.find((comment) => comment.id === id)?.content || "");
  };

  const handleUpdate = (id: string) => {
    updateComment({ request: { id, content: editedComment } });
    setEditCommentId("");
    setEditedComment("");
  };

  return (
    <Flex flexDirection="column">
      <SidebarContentItem column gap="16px">
        <Text h2>Comments</Text>
        <Flex column gap="8px">
          <Flex gap="8px">
            <UserInitials firstName={user.first_name} lastName={user.last_name} />
            <Mentions
              options={userOptions}
              value={newComment}
              onChange={(e) => setNewComment(e)}
              placeholder="Add comment"
              autoSize
            />
          </Flex>
          <Flex gap="8px" alignSelf="flex-end">
            {newComment && (
              <Button
                as="button"
                onClick={handleSubmit}
                buttonType="primary"
                disabled={!newComment}
                icon="cornerDownLeft"
              >
                Submit
              </Button>
            )}
          </Flex>
        </Flex>
        <Spacer vertical={4} />
        <Flex flexDirection="column" gap="24px">
          {opportunity.comments.map((comment) => (
            <CommentComponent
              key={comment.id}
              comment={comment}
              user={user}
              enabledEdit={enabledEdit}
              handleDelete={handleDelete}
              editCommentId={editCommentId}
              editedComment={editedComment}
              setEditedComment={setEditedComment}
              handleUpdate={handleUpdate}
              setEditCommentId={setEditCommentId}
            />
          ))}
        </Flex>
      </SidebarContentItem>
    </Flex>
  );
}

const SidebarContentItem = styled(Flex)({
  padding: "20px 24px",
});
