import React from "react";
import styled from "@emotion/styled";
import { Modal } from "antd5";

import { Text } from "../../../styles/utility-components";
import Button from "../../ui/button/Button";

type OpportunityDiscardModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
};

export default function OpportunityDiscardModal({
  isOpen,
  onCancel,
  onSubmit,
}: OpportunityDiscardModalProps) {
  return (
    <Modal
      open={isOpen}
      centered
      footer={
        <Footer>
          <Button as="button" buttonType="secondary" onClick={onCancel}>
            Return to Opportunity
          </Button>
          <Button as="button" buttonStyle="destructive" onClick={onSubmit}>
            Delete Opportunity
          </Button>
        </Footer>
      }
      onCancel={onCancel}
      onOk={onSubmit}
    >
      <Content>
        <Text h3 bold>
          Delete Opportunity
        </Text>
        <Text>Are you sure you want to delete this opportunity? This action cannot be undone.</Text>
      </Content>
    </Modal>
  );
}

const Content = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 16,
});

const Footer = styled.div({
  display: "flex",
  gap: 16,
  justifyContent: "flex-end",
});
