import React, { useCallback, useState } from "react";
import { useIsDevCycleInitialized } from "@devcycle/react-client-sdk";
import { Button, Tooltip } from "antd5";
import { Redirect } from "wouter";

import { DocumentSlideout } from "components/bid_module/qualification_report_section/DocumentSlideout";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { useVariableValue } from "lib/featureFlags";
import { useGetBidDocumentUrl } from "lib/hooks/api/bid/bid-document";
import { useGetOrQualifyBid, useQualifyBid } from "lib/hooks/api/bid/scorecard";
import { folder, refreshCw03, trash03 } from "lib/icons/untitled_ui/SVGs";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { exportDataToCsv } from "../../../lib/exportArrayToCsv";
import { Scorecard_Result_Questions_Sources } from "../../../lib/generated/app-service-gql/graphql";
import { red500 } from "../../../lib/themes/colors";
import { Flex, Text } from "../../../styles/utility-components";
import { DetailsContent, DetailsHeader } from "../../app_layout/DetailsLayout";
import LoadingState from "../../buyer_details/question_panel/LoadingState";
import DocumentDrawer from "../DocumentViewer";
import { Scorecard_Source } from "../types";
import { QualificationReportSection } from "./QualificationReportSection";
import { DeleteModal, RegenerateScorecardModal } from "./ReportModals";

import css from "./QualificationReport.module.scss";

export function QualificationReport({
  noticeId,
  procurementStageId,
  noticeTitle,
  buyerName,
  bidId,
}: {
  noticeId: string;
  procurementStageId: string;
  noticeTitle: string;
  buyerName: string;
  bidId: string;
}) {
  const { logEvent } = useTracking();
  const [resultData, setResultData] = useState<Scorecard_Source | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [slideOutOpen, setSlideOutOpen] = useState(false);
  const [debug, setDebug] = useState(false);
  const devCycleReady = useIsDevCycleInitialized();
  const isEnabled = useVariableValue("notice-auto-qualification", false);
  const { mutate, isLoading: isQualifying } = useQualifyBid();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRegenerateModalOpen, setIsRegenerateModalOpen] = useState(false);

  const {
    data,
    isLoading: isQuerying,
    refetch,
  } = useGetOrQualifyBid(
    {
      bidId,
      procurementStageId,
    },
    {
      enabled: !!isEnabled,
    },
  );
  const { mutateAsync: getBidDocumentUrlMutation } = useGetBidDocumentUrl();

  const onSelectDocument = useCallback(
    async (
      source: Scorecard_Result_Questions_Sources,
      trackingContext: EventNames,
      trackingData: EventData,
    ) => {
      if (!source.documentId) {
        return;
      }

      const { getBidDocumentUrl } = await getBidDocumentUrlMutation({ id: source.documentId });
      setDrawerOpen(true);
      setResultData({
        ...source,
        documentLink: getBidDocumentUrl.documentUrl,
      });

      logEvent(trackingContext, {
        ...trackingData,
        "Record GUID": noticeId,
      });
    },
    [getBidDocumentUrlMutation, logEvent, noticeId],
  );

  const isAdmin = window.currentUser?.admin || false;

  const handleCSVExport = React.useCallback(() => {
    if (data) {
      const questionsAndAnswers = data.flatMap((item) =>
        item.questions.map((question) => ({
          question: question.title,
          answer: question.answer?.answer || null,
        })),
      );
      const csvData = [
        { question: "Scorecard link", answer: window.location.href },
        { question: "Question", answer: "Answer" },
        ...questionsAndAnswers,
      ];
      exportDataToCsv("scorecard.csv", csvData, false);

      logEvent(EventNames.exportedData, {
        "Record GUID": noticeId,
        "Action type": "Button clicked",
        "Data type": "Bid qualification scorecard",
      });
    }
  }, [data, logEvent, noticeId]);

  if (devCycleReady && !isEnabled) {
    return <Redirect to="/" />;
  }

  const isLoading = isQuerying || isQualifying;

  if (isLoading) {
    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100vw"
        flexDirection="column"
      >
        <LoadingState />
        <Text h2>Analysing documents...</Text>
      </Flex>
    );
  }

  return (
    <div className={css.qualificationReportPage}>
      <DetailsHeader className={css.header}>
        <div className={css.headerActions}>
          <Tooltip title={"Regenerate report"} placement="bottom">
            <Button
              ghost
              onClick={() => setIsRegenerateModalOpen(true)}
              className={css.actionButton}
            >
              <UIcon svg={refreshCw03} size={16} color={"inherit"} />
            </Button>
          </Tooltip>
          <Tooltip title={"View documents"} placement="bottom">
            <Button
              className={css.actionButton}
              ghost
              onClick={() => {
                if (!slideOutOpen) {
                  logEvent(EventNames.tenderDocumentsTrayOpened, {
                    "Record GUID": noticeId,
                    "Action type": "Button clicked",
                  });
                }
                setSlideOutOpen(!slideOutOpen);
              }}
            >
              <UIcon svg={folder} size={16} color={"inherit"} />
            </Button>
          </Tooltip>
          <Button type="primary" onClick={handleCSVExport} disabled={!data || data.length == 0}>
            Download CSV
          </Button>
          <Tooltip title={"Delete report"} placement="bottom">
            <Button
              onClick={() => setIsDeleteModalOpen(true)}
              danger
              className={css.deleteButton}
              icon={<UIcon svg={trash03} size={16} color={red500} />}
            />
          </Tooltip>
        </div>
      </DetailsHeader>
      <DetailsContent className={css.pageContent}>
        <div className={css.scoreCardWrapper}>
          <div className={css.noticeHeader}>
            <div className={css.title}>
              <Text h1 className={css.scorecardTitle}>
                Qualification Report
              </Text>
              <EllipsisTooltipText fullText={noticeTitle} containerClassname={css.noticeTitle} />

              <p className={css.buyerName}>{buyerName}</p>
            </div>
          </div>
          <p className={css.disclaimerText}>
            AI-generated responses may be inaccurate. Verify important information.
          </p>
          <div className={css.qaReportContent}>
            {data?.map((section) => (
              <QualificationReportSection
                key={section.groupName}
                scoreCardResult={section}
                procurementStageId={procurementStageId}
                bidId={bidId}
                debugMode={debug}
                onSelectDocument={onSelectDocument}
                slideOutOpen={slideOutOpen}
              />
            ))}
          </div>
          {isAdmin && (
            <Flex gap="24px">
              <Button onClick={() => setDebug(!debug)}>Toggle debug</Button>
            </Flex>
          )}
        </div>
        {slideOutOpen && (
          <DocumentSlideout
            bidId={bidId}
            onSelectDocument={(source) =>
              onSelectDocument(source, EventNames.tenderDocumentOpened, {
                "Action type": "Document reader opened",
                "Document name": source.document,
              })
            }
          />
        )}
        {resultData && (
          <DocumentDrawer
            open={drawerOpen}
            source={resultData}
            onClose={() => setDrawerOpen(false)}
          />
        )}
      </DetailsContent>
      <DeleteModal
        bidId={bidId}
        noticeId={noticeId}
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <RegenerateScorecardModal
        isOpen={isRegenerateModalOpen}
        onClose={() => setIsRegenerateModalOpen(false)}
        regenerateBidMutation={() =>
          mutate(
            { input: { procurementStageId, bidId } },
            {
              onSuccess: async () => {
                await refetch();
              },
            },
          )
        }
      />
    </div>
  );
}
