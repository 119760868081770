import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  DocumentsCountQuery,
  DocumentsSearchRequest,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const documentsCountQuery = graphql(`
  query documentsCount($request: DocumentsSearchRequest!) {
    documents(DocumentsSearchRequest: $request) {
      __typename
      totalResults
    }
  }
`);

export function useDocumentsCount(
  searchDocumentsRequest: DocumentsSearchRequest,
  options?: UseQueryOptions<DocumentsCountQuery, unknown, DocumentsCountQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["documentsCount", searchDocumentsRequest],
    documentsCountQuery,
    [{ request: searchDocumentsRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: data?.documents,
    ...rest,
  };
}
