import React, { useCallback, useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

import { useDebouncedValue } from "../../../../../lib/debounce";
import { useSearchOrganisations } from "../../../../../lib/hooks/api/organisations/useSearchOrganisations";
import {
  OrgSortBy,
  OrgSortOrder,
  SearchOrgPrimaryRole,
} from "../../../../../lib/types/graphQLEnums";
import InfiniteSuppliersChecklist from "../../../../form_components/dropdown_list_selects/new_supplier_selects/InfiniteSuppliersChecklist";
import { SupplierOption } from "../../../../form_components/dropdown_list_selects/new_supplier_selects/types";
import { Select, SelectProps } from "../../../../form_components/Inputs";
import { MatchCountMap } from "../../../../onboarding/SharedOnboarding";
import SkeletonText from "../../../../ui/skeleton/SkeletonText";
import Tag, { TagVariant } from "../../../../ui/tag/Tag";

import css from "./SimpleSupplierSelect.module.scss";

type SimpleSupplierSelectProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  tagVariant?: TagVariant;
  counts?: MatchCountMap;
};

const dedupeSuppliers = (suppliers: SupplierOption[]): SupplierOption[] => {
  return Array.from(new Map(suppliers.map((item) => [item.value, item])).values());
};

export default function SimpleSupplierSelect<T extends FieldValues>({
  tagVariant,
  counts,
  ...props
}: SimpleSupplierSelectProps<T>) {
  const { field } = useController(props);
  const [searchInput, setSearchInput] = useState("");
  const [debouncedSearch] = useDebouncedValue(searchInput);

  const {
    data: initialSuppliers,
    isInitialLoading,
    isError,
  } = useSearchOrganisations(
    {
      textSearch: "",
      ids: field.value,
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: SearchOrgPrimaryRole.Supplier,
      limit: field.value.length,
      page: 1,
    },
    true,
    true,
    { enabled: field.value.length > 0, keepPreviousData: true },
  );

  const supplierOptions = useMemo(() => {
    const suppliers =
      initialSuppliers?.searchOrganisations.orgs.map((org) => ({
        label: org.name,
        value: org.id,
      })) ?? [];
    return dedupeSuppliers(suppliers);
  }, [initialSuppliers]);

  const handleSupplierChange = (suppliers: SupplierOption[]) => {
    const uniqueSuppliers = dedupeSuppliers(suppliers);
    field.onChange(uniqueSuppliers.map((supplier) => supplier.value));
  };

  const TagRender = useCallback(
    ({ value }: CustomTagProps) => {
      const supplier = supplierOptions.find((s) => s.value === value);
      const label = counts?.[value]
        ? `${supplier?.label} (${counts[value]})`
        : supplier?.label || value;

      const handleRemove = () => {
        const updatedValues = field.value.filter((v: string) => v !== value);
        field.onChange(updatedValues);
      };

      return (
        <Tag
          label={label}
          icon={<CloseOutlined />}
          variant={tagVariant}
          onClick={handleRemove}
          isLoading={isInitialLoading}
        />
      );
    },
    [counts, field, isInitialLoading, supplierOptions, tagVariant],
  );

  if (isInitialLoading) return <SkeletonText width="100%" />;
  if (isError) return <div>Error loading suppliers</div>;

  return (
    <Select
      {...props}
      className={css.select}
      selectAll={!!debouncedSearch}
      options={supplierOptions}
      value={field.value}
      dropdownRender={() => (
        <InfiniteSuppliersChecklist
          signalSuppliers={[]}
          selectedSuppliers={supplierOptions.filter((s) => field.value.includes(s.value))}
          excludeSignals
          textSearch={debouncedSearch}
          onChange={handleSupplierChange}
        />
      )}
      onSearch={setSearchInput}
      tagRender={TagRender}
      dropdownStyle={debouncedSearch === "" ? { display: "none" } : undefined}
      onChange={(value) => {
        const values = Array.isArray(value) ? value : [value];
        const validSuppliers = values.filter(
          (v): v is SupplierOption =>
            typeof v === "object" && v !== null && "value" in v && "label" in v,
        );
        field.onChange(validSuppliers.map((s) => s.value));
      }}
    />
  );
}
