import React from "react";
import { Button } from "antd5";
import { Flex } from "styles/utility-components";

import UIcon from "lib/icons/untitled_ui/UIcon";

export function ActionMessageContent({
  message,
  objectType,
  url,
}: {
  message: string;
  objectType: "list" | "opportunity";
  url: string;
}) {
  return (
    <Flex gap={16}>
      <Flex gap={8} alignItems="center">
        <UIcon icon="checkCircle" />
        <span>{message}</span>
      </Flex>
      <Button type="primary" href={url} target="_blank">
        View {objectType}
      </Button>
    </Flex>
  );
}
