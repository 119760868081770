import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { OpportunitiesStagesQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const opportunitiesStagesQuery = graphql(`
  query opportunitiesStages {
    opportunityWorkflow {
      transitions {
        id
        group
        order
        stage {
          name
          color
          iconColor
          iconName
        }
      }
    }
  }
`);

export function useOpportunitiesStages(
  options?: UseQueryOptions<OpportunitiesStagesQuery, unknown, OpportunitiesStagesQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["opportunitiesStages"], opportunitiesStagesQuery, [], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return {
    data: data?.opportunityWorkflow.transitions,
    ...rest,
  };
}
