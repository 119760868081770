import * as React from "react";
import { Route, Switch } from "wouter";

import { useBids } from "../../lib/hooks/api/bid/bid";
import { QualificationReport } from "./qualification_report_section/QualificationReport";
import { DocumentUploadPage } from "./DocumentUploadPage";

export function BidQualificationPage({
  noticeId,
  procurementStageId,
  noticeTitle,
  buyerName,
  portalUrl,
}: {
  noticeId: string;
  procurementStageId: string;
  noticeTitle: string;
  buyerName: string;
  portalUrl?: string;
}) {
  const { data: bids, isLoading } = useBids({ procurementStageIds: [procurementStageId] });

  if (isLoading) {
    return null;
  }

  return (
    <Switch>
      <Route path="/bid_qualification">
        <DocumentUploadPage portalUrl={portalUrl} procurementStageId={procurementStageId} />
      </Route>
      <Route path={`/bid_qualification/:bidId`}>
        {({ bidId }) => (
          <QualificationReport
            noticeId={noticeId}
            bidId={bidId ?? bids?.results?.[0].id ?? ""}
            procurementStageId={procurementStageId}
            noticeTitle={noticeTitle}
            buyerName={buyerName}
          />
        )}
      </Route>
    </Switch>
  );
}
