import * as React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import Button from "components/ui/button/Button";
import EmptyState from "components/ui/empty_state/EmptyState";
import { settingsRoutes } from "lib/routes";

interface Props {
  setIsFilterDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function EmptyNotices({ setIsFilterDrawerOpen }: Props): JSX.Element {
  return (
    <EmptyState
      type="noResults"
      heading="Empty, but full of potential!"
      description="Try changing your settings below to get back on track"
      size="large"
      width="100%"
      yAxisMargin="64px"
      action={
        <Flex column gap={16}>
          <Action
            title="Edit your current filters"
            description="Try making some changes to your filters to widen your current search."
            button={
              <Button
                as="button"
                buttonType="primary"
                icon="filterFunnel02"
                onClick={() => setIsFilterDrawerOpen(true)}
              >
                Filters
              </Button>
            }
          />
          <Action
            title="Add to your signal settings"
            description="Add more signals to widen your overall view of public sector on Stotles "
            button={
              <Button
                as="a"
                buttonType="secondary"
                icon="signal01"
                href={settingsRoutes.signalSettings}
              >
                Signal settings
              </Button>
            }
          />
        </Flex>
      }
    />
  );
}

const Action = ({
  title,
  description,
  button,
}: {
  title: string;
  description: string;
  button: React.ReactElement;
}) => {
  return (
    <ActionWrapper justifyContent="space-between" alignItems="center">
      <Flex column gap={4} alignItems="flex-start">
        <Text h3>{title}</Text>
        <Text color="sysTextSecondary">{description}</Text>
      </Flex>
      {button}
    </ActionWrapper>
  );
};

const ActionWrapper = styled(Flex)(({ theme }) => ({
  borderColor: theme.colors.sysBorderPrimary,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "4px",
  padding: "16px",
  gap: "64px",
  textAlign: "left",
  flexDirection: "row",

  "@media screen and (max-width: 768px)": {
    flexDirection: "column",
    gap: "8px",
  },
}));
