import styled from "@emotion/styled";
import { Text } from "styles/utility-components";

import { DocumentFilters } from "components/documents/utils";
import { FrameworkFilters } from "components/framework/utils";
import { SavedViewType } from "lib/generated/app-api";

export const Box = styled.div(({ theme }) => ({
  borderColor: theme.colors.sysBorderPrimary,
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "8px",
  flexGrow: 1,
  padding: "24px",
  backgroundColor: theme.colors.sysBackgroundDefault,
  color: theme.colors.sysTextDefault,
}));

export const WidgetTitle = styled(Text)(({ theme }) => ({
  color: theme.colors.sysTextSecondary,
  fontWeight: "500",
}));

export const DEFAULT_NOTICE_FILTERS = {
  id: "fallback", // Should be a UUID so impossible for it to match
  isStandardView: false,
  view: {
    filterSettings: {},
    sortOrder: {},
    tableSettings: {},
  },
} as SavedViewType;

export type TypedFilters =
  | {
      type: "NOTICE";
      filters: SavedViewType;
    }
  | {
      type: "FRAMEWORK";
      filters: FrameworkFilters;
    }
  | {
      type: "DOCUMENT";
      filters: DocumentFilters;
    };
