import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { EventNames, useTracking } from "lib/tracking";
import { graphql } from "../../../generated/app-service-gql";
import {
  AddOpportunityEntityMutation,
  AddOpportunityEntityMutationVariables,
  EntityOpportunitiesQuery,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const ADD_OPPORTUNITY_ENTITY_MUTATION = graphql(`
  mutation addOpportunityEntity($id: String!, $entites: OpportunityEntitiesInput!) {
    addOpportunityEntities(id: $id, entities: $entites) {
      id
    }
  }
`);

export function useAddOpportunityEntity(
  options?: UseMutationOptions<
    AddOpportunityEntityMutation,
    unknown,
    AddOpportunityEntityMutationVariables,
    unknown
  >,
) {
  const { logEvent } = useTracking();

  const queryClient = useQueryClient();
  return useGraphQlMutation(ADD_OPPORTUNITY_ENTITY_MUTATION, {
    ...options,
    onSuccess: (data, variables, context) => {
      for (const entity of variables.entites.entities) {
        queryClient.setQueryData<EntityOpportunitiesQuery>(
          ["entityOpportunities", entity.entityId],
          (oldData) => {
            if (!oldData) {
              return { entityOpportunites: [variables.id] };
            }
            return { entityOpportunites: [...oldData.entityOpportunites, variables.id] };
          },
        );
      }
      options?.onSuccess?.(data, variables, context);
      logEvent(EventNames.opportunityDataAdded);
    },
  });
}
