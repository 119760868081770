import * as React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { ConfigProvider, Modal } from "antd5";

import { ButtonLink } from "components/actions/Links";
import Button from "components/ui/button/Button";
import { ProviderTypes } from "lib/generated/integration-api";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { BrandIconKey } from "lib/icons/untitled_ui/UIcon";
import { useDialogManager } from "lib/providers/DialogManager";
import { hubspotOrange, salesforceBlue } from "lib/themes/colors";
import { NoPaddingModal } from "lib/themes/NoPaddingModal";
import { EventData, EventNames, logEvent, useTracking } from "lib/tracking";

// eslint-disable-next-line css-modules/no-unused-class
import css from "./ProviderButtons.module.scss";

import crmConnectorDesign from "../../../assets/images/crm_connectors.svg";

export type ButtonTypes = "primary" | "secondary" | "tertiary";

export const providerIconKeyMap: Record<ProviderTypes, BrandIconKey> = {
  HUBSPOT: "hubspot",
  SALESFORCE: "salesforce",
};

export const providerIconColorMap: Record<ProviderTypes, string> = {
  HUBSPOT: hubspotOrange,
  SALESFORCE: salesforceBlue,
};

type ViewInCRMProps = {
  providerType: ProviderTypes;
  externalUrl: string;
  buttonType?: ButtonTypes;
  disabled?: boolean;
  type?: ButtonTypes;
  fullWidth?: boolean;
  align?: "center" | "left";
};

/**
 * Link that looks like a button and lets you view the resource in the CRM
 */
export function ViewInCRMButton({
  providerType,
  externalUrl,
  type = "primary",
  fullWidth = false,
  align,
}: ViewInCRMProps) {
  const { logEvent } = useTracking();
  const trackingAttributes = React.useMemo(() => {
    return {
      "Connected provider": providerType,
    };
  }, [providerType]);
  return (
    <Button
      buttonType={type}
      icon={providerIconKeyMap[providerType]}
      iconColorOverride={type === "primary" ? undefined : providerIconColorMap[providerType]}
      as="a"
      target="new-tab"
      href={externalUrl}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        logEvent(EventNames.viewInCRMButtonClicked, trackingAttributes);
      }}
      fullWidth={fullWidth}
      justifyContent={align}
    >
      View
    </Button>
  );
}

type SendToCRMProps = {
  providerType: ProviderTypes;
  buttonType?: ButtonTypes;
  loading?: boolean;
  onClick: (
    e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void | Promise<void>;
  disabled?: boolean;
  type?: ButtonTypes;
  fullWidth?: boolean;
  align?: "center" | "left";
  eventName: EventNames; // This button can be used to open the modal or send the form
};

/**
 * Button that is used to send items to the CRM depending on onSend passed in
 */
export function SendToCRMButton({
  providerType,
  onClick,
  loading,
  disabled,
  type = "secondary",
  fullWidth = false,
  eventName,
  align,
}: SendToCRMProps) {
  const { logEvent } = useTracking();
  const trackingAttributes = React.useMemo(() => {
    return {
      "Connected provider": providerType,
      "Data types": "Notice",
    };
  }, [providerType]);

  return (
    <Button
      buttonType={type}
      onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
        logEvent(eventName, trackingAttributes);
        await onClick(e);
      }}
      disabled={disabled}
      icon={providerIconKeyMap[providerType]}
      iconColorOverride={providerIconColorMap[providerType]}
      as="button"
      fullWidth={fullWidth}
      justifyContent={align}
      blackText
    >
      {loading ? "Loading" : "Send"}
    </Button>
  );
}

type Props = {
  showingTour?: boolean;
};
export function ConnectToCRMButton({ showingTour }: Props) {
  const { trackingData } = useTracking();

  const openGuestAccessPassPaywall = useRestrictedGuestAccess(trackingData);

  const dialogManager = useDialogManager();

  const handleConnectToCRM = (e: React.MouseEvent) => {
    if (window.guestUser) {
      e.preventDefault();
      openGuestAccessPassPaywall();
    } else if (!showingTour) {
      logEvent(EventNames.connectToCRMButtonClicked, trackingData);
      void dialogManager.openDialog(ConnectToCRMModal, { trackingData });
    }
    e.stopPropagation();
  };

  return (
    <Button
      as="button"
      buttonType="secondary"
      onClick={handleConnectToCRM}
      icon="plus"
      className={css.crmButton}
    >
      CRM
    </Button>
  );
}

function ConnectToCRMModal({
  isOpen,
  onClose,
  trackingData,
}: {
  isOpen: boolean;
  onClose: () => void;
  trackingData: EventData;
}): JSX.Element {
  const closeModal = () => {
    onClose();
    logEvent(EventNames.closeCRMModelButtonClicked, trackingData);
  };

  return (
    <ConfigProvider theme={NoPaddingModal}>
      <Modal
        className={css.modal}
        open={isOpen}
        footer={
          <div className={css.footer}>
            <ButtonLink
              to="/account-management/integrations"
              type="primary"
              className={css.goToIntegrationsPageButton}
              onClick={() => {
                logEvent(EventNames.goToIntegrationsPageClicked, trackingData);
              }}
            >
              Connect your CRM
            </ButtonLink>
          </div>
        }
        closeIcon={<CloseOutlined className={css.closeIcon} onClick={closeModal} />}
        onCancel={closeModal}
        okText="Connect my CRM"
        title={
          <div className={css.modalHeading}>
            <img src={crmConnectorDesign}></img>
          </div>
        }
      >
        <div className={css.modalContent}>
          <h1>Insights instantly pushed to your CRM</h1>
          <p>
            Push notices and contacts direct to your Salesforce or HubSpot account. Achieve your
            sales goals more efficiently and faster by simply integrating your CRM, and clicking
            send on the relevant notice or contact you want to store.
          </p>
        </div>
      </Modal>
    </ConfigProvider>
  );
}
