import React from "react";
import styled from "@emotion/styled";

import Banner from "./Banner";
import Opportunities from "./Opportunities";
import Pipelines from "./Pipelines";
import QuickLinks from "./QuickLinks";
import Welcome from "./Welcome";

function HomepageContent() {
  return (
    <PageWrapper>
      <Welcome />
      <Banner />
      <Opportunities />
      <Pipelines />
      <QuickLinks />
    </PageWrapper>
  );
}

const PageWrapper = styled.div(() => ({
  display: "flex",
  flexDirection: "column" as const,
  flexGrow: 1,
  flexShrink: 0,
  gap: "56px",
  height: "fit-content",
  width: "100%",
  maxWidth: "1200px",
  justifyContent: "center",
  margin: "0 auto",
  padding: "48px 24px",

  "@media screen and (min-width: 992px)": {
    padding: "48px",
  },
}));

export default HomepageContent;
