import React, { useEffect, useRef } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { fadeIn } from "styles/animations";

import UIcon from "lib/icons/untitled_ui/UIcon";

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  modalClose: () => void; // Function which closes the modal
  onOpen?: () => void; // Function which runs when modal is opened
  onClose?: () => void; // Function which runs when modal is closed
  showCloseButton?: boolean; // Whether to show the close button in the top right corner
  backgroundClickClose?: boolean; // Whether if the modal should close when clicking on the background
}

export const ModalBackgroundless = ({
  children,
  isOpen,
  modalClose,
  onOpen,
  onClose,
  showCloseButton = true,
  backgroundClickClose = true,
}: ModalProps) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
      onOpen?.();
    } else {
      dialogRef.current?.close();
      onClose?.();
    }
  }, [isOpen, onOpen, onClose]);

  return (
    <DialogBackgroundless
      onClick={backgroundClickClose ? modalClose : undefined}
      onClose={modalClose}
      ref={dialogRef}
    >
      {children}
      {showCloseButton && (
        <CloseModalButtonGlobal onClick={modalClose}>
          <UIcon icon="xClose" size={32} color="inherit" />
        </CloseModalButtonGlobal>
      )}
    </DialogBackgroundless>
  );
};

const fadeInBackground = keyframes({
  from: { opacity: 0 },
  to: { opacity: 0.5 },
});

const DialogBackgroundless = styled.dialog(({ theme }) => ({
  backgroundColor: "transparent",
  borderWidth: 0,
  maxWidth: "min(100vw, 100vh)",
  padding: 0,

  // Using both animation and transition, since animation runs when modal is opened while transition runs when modal is closed
  // Should be possible to only use one of them but I couldn't get it to work without side effects (namely dialog contents being visible inline on the page)
  "&::backdrop": {
    animation: `${fadeInBackground} 0.3s ease-out`,
    transition: "all 0.3s allow-discrete ease-out",
    backgroundColor: "transparent",
    opacity: 0,
  },
  animation: `${fadeIn} 0.3s ease-out`,
  transition: "all 0.3s allow-discrete ease-out",
  opacity: 0,
  "&[open]": {
    opacity: 1,
    "&::backdrop": {
      backgroundColor: theme.colors.sysBackgroundOverlay,
      opacity: 0.5,
    },
  },
}));

const CloseModalButtonGlobal = styled.button(({ theme }) => ({
  position: "fixed",
  top: "0px",
  right: "0px",
  padding: "16px",
  color: theme.colors.sysTextSecondary,
  backgroundColor: "transparent",
  border: "none",
  lineHeight: 0,
  cursor: "pointer",
  zIndex: 2,
  "&:hover": {
    color: theme.colors.sysTextBlack,
  },
}));
