import React from "react";
import styled from "@emotion/styled";

import { EventNames, useTracking } from "lib/tracking";
import { plus } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysPrimaryDefault } from "../../../lib/themes/colors";
import { Flex } from "../../../styles/utility-components";

type Props = {
  entity: "notice" | "document" | "framework" | "buyer" | "supplier" | "contact";
  onClick: () => void;
};

export default function OpportunitySavedEmptyState({ entity, onClick }: Props) {
  const { logEvent } = useTracking();

  return (
    <Card>
      <StyledButton
        onClick={() => {
          logEvent(EventNames.addOpportunityDataClicked, { "Data type": entity.toUpperCase() });
          onClick();
        }}
      >
        <UIcon svg={plus} color={sysPrimaryDefault} />
        Add {entity}s
      </StyledButton>
    </Card>
  );
}

const Card = styled(Flex)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  height: 32,
  width: "100%",

  padding: 24,

  borderRadius: 4,
  backgroundImage: `repeating-linear-gradient(90deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(90deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysBorderPrimary}, ${theme.colors.sysBorderPrimary} 12px, transparent 12px, transparent 28px)`,
  backgroundPosition: "left top, right top, left bottom, left top",
  backgroundRepeat: "repeat-x, repeat-y, repeat-x, repeat-y",
  backgroundSize: "100% 1px, 1px 100%, 100% 1px, 1px 100%",

  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    backgroundImage: `repeating-linear-gradient(90deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(90deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px), repeating-linear-gradient(180deg, ${theme.colors.sysPrimaryDefault}, ${theme.colors.sysPrimaryDefault} 12px, transparent 12px, transparent 28px)`,
  },

  transition: "all 0.2s ease-in-out",
}));

const StyledButton = styled.button(({ theme }) => ({
  all: "unset",
  cursor: "pointer",

  display: "flex",
  alignItems: "center",
  gap: 8,

  color: theme.colors.sysPrimaryDefault,
}));
