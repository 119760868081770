import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  FrameworksCountQuery,
  SearchFrameworksRequest,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const frameworksCountQuery = graphql(`
  query frameworksCount($request: SearchFrameworksRequest!) {
    frameworks(SearchFrameworksRequest: $request) {
      __typename
      totalResults
    }
  }
`);

export function useFrameworksCount(
  searchFrameworksRequest: SearchFrameworksRequest,
  options?: UseQueryOptions<FrameworksCountQuery, unknown, FrameworksCountQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["frameworksCount", searchFrameworksRequest],
    frameworksCountQuery,
    [{ request: searchFrameworksRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: data?.frameworks,
    ...rest,
  };
}
