import React from "react";

export const hubspot = (
  <svg
    height="14"
    viewBox="6.20856283 .64498824 244.26943717 251.24701176"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Hubspot.com logo</title>
    <desc>
      An inbound marketing and sales CRM platform designed for small and medium-sized businesses
    </desc>
    <path
      d="m191.385 85.694v-29.506a22.722 22.722 0 0 0 13.101-20.48v-.677c0-12.549-10.173-22.722-22.721-22.722h-.678c-12.549 0-22.722 10.173-22.722 22.722v.677a22.722 22.722 0 0 0 13.101 20.48v29.506a64.342 64.342 0 0 0 -30.594 13.47l-80.922-63.03c.577-2.083.878-4.225.912-6.375a25.6 25.6 0 1 0 -25.633 25.55 25.323 25.323 0 0 0 12.607-3.43l79.685 62.007c-14.65 22.131-14.258 50.974.987 72.7l-24.236 24.243c-1.96-.626-4-.959-6.057-.987-11.607.01-21.01 9.423-21.007 21.03.003 11.606 9.412 21.014 21.018 21.017 11.607.003 21.02-9.4 21.03-21.007a20.747 20.747 0 0 0 -.988-6.056l23.976-23.985c21.423 16.492 50.846 17.913 73.759 3.562 22.912-14.352 34.475-41.446 28.985-67.918-5.49-26.473-26.873-46.734-53.603-50.792m-9.938 97.044a33.17 33.17 0 1 1 0-66.316c17.85.625 32 15.272 32.01 33.134.008 17.86-14.127 32.522-31.977 33.165"
      fill="currentColor"
    />
  </svg>
);

export const salesforce = (
  <svg
    version="1.1"
    height="14"
    width="14"
    viewBox="0 0 273 191"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Salesforce.com logo</title>
    <desc>
      An enterprise-focused CRM platform offering advanced sales, customer service, and marketing
      solutions
    </desc>
    <defs>
      <path id="a" d="m0.06 0.5h272v190h-272z" />
    </defs>
    <g fillRule="evenodd">
      <path
        d="m113 21.3c8.78-9.14 21-14.8 34.5-14.8 18 0 33.6 10 42 24.9a58 58 0 0 1 23.7-5.05c32.4 0 58.7 26.5 58.7 59.2s-26.3 59.2-58.7 59.2c-3.96 0-7.82-0.398-11.6-1.15-7.35 13.1-21.4 22-37.4 22a42.7 42.7 0 0 1-18.8-4.32c-7.45 17.5-24.8 29.8-45 29.8-21.1 0-39-13.3-45.9-32a45.1 45.1 0 0 1-9.34 0.972c-25.1 0-45.4-20.6-45.4-45.9 0-17 9.14-31.8 22.7-39.8a52.6 52.6 0 0 1-4.35-21c0-29.2 23.7-52.8 52.9-52.8 17.1 0 32.4 8.15 42 20.8"
        fill="currentColor"
        mask="url(#b)"
      />
    </g>
  </svg>
);
