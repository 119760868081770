import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, ConfigProvider, Modal } from "antd5";

import { MidPaddingModal } from "lib/themes/MidPaddingModal";
import { EventNames, useTracking } from "lib/tracking";

import css from "./SaveSignalsModal.module.scss";

export const HIDE_SAVE_KEYWORD_MODAL = "HIDE_SAVE_KEYWORD_MODAL";
export const HIDE_SAVE_CPV_CODE_MODAL = "HIDE_SAVE_CPV_CODE_MODAL";

export type SaveSignalsModalProps = {
  signalType: "keyword" | "CPV code";
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

function SaveSignalsModal({ signalType, isOpen, onConfirm, onClose }: SaveSignalsModalProps) {
  const { logEvent } = useTracking();

  const hideModalKey =
    signalType === "keyword" ? HIDE_SAVE_KEYWORD_MODAL : HIDE_SAVE_CPV_CODE_MODAL;
  const closeModal = (actionType: "Confirm" | "Cancel") => {
    logEvent(EventNames.saveSignalsModalActioned, {
      "Context source": `Save ${signalType} confirm modal`,
      "Action type": actionType,
      "Don't show me again checked?": localStorage.getItem(hideModalKey) === "true" ? "Yes" : "No",
    });
    onClose();
  };

  return (
    <ConfigProvider theme={MidPaddingModal}>
      <Modal
        title={
          <div className={css.header}>
            <InfoCircleOutlined className={css.icon} />
            <h2 className={css.title}>
              Save {signalType === "keyword" ? "free-text keywords" : "unsaved CPV codes"} as
              signals
            </h2>
          </div>
        }
        open={isOpen}
        closable={false}
        footer={
          <div className={css.footer} aria-label="Modal footer">
            <Checkbox onChange={(e) => localStorage.setItem(hideModalKey, `${e.target.checked}`)}>
              Don't show me this again
            </Checkbox>
            <span className={css.buttons}>
              <Button onClick={() => closeModal("Cancel")}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  onConfirm();

                  closeModal("Confirm");
                }}
              >
                Continue
              </Button>
            </span>
          </div>
        }
      >
        <div className={css.content}>
          Saving these {signalType}s as signals will also update your signal settings and affect all
          saved views where all {signalType} signals are applied. Do you still wish to continue?
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default SaveSignalsModal;
