import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Drawer, Segmented } from "antd5";

import { TrackingProvider } from "lib/tracking";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextDefault } from "../../../lib/themes/colors";
import { Flex } from "../../../styles/utility-components";
import { SaveToLists } from "./lists/SaveToLists";
import ObjectOverview from "./opportunity/ObjectOverview";
import { SaveToOpportunity } from "./opportunity/SaveToOpportunity";
import { EntityType, SharedSaveProps } from "./types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
} & SharedSaveProps;

const hasList: Record<EntityType, boolean> = {
  buyer: true,
  supplier: true,
  notice: true,
  framework: false,
  document: false,
  contact: false,
};

export function SaveToDrawer({
  isOpen,
  onClose,
  entityType,
  entityId,
  entityListId,
  defaultOpportunityValues,
}: Props) {
  const [selectedTab, setSelectedTab] = useState<"opportunity" | "list">(
    entityType === "supplier" ? "list" : "opportunity",
  );

  const showGraphic = hasList[entityType];

  return (
    <Drawer
      placement="right"
      closable={true}
      open={isOpen}
      width={showGraphic ? 920 : 486}
      onClose={onClose}
      styles={{ header: { display: "none" }, body: { padding: "16px 24px" } }}
    >
      <TrackingProvider data={{ "Data type": entityType.toUpperCase() }}>
        <Flex gap={32} height="100%">
          {showGraphic && <ObjectOverview objectType={selectedTab} entityType={entityType} />}
          <Flex column height="100%" gap={8} width="440px">
            <Flex justifyContent="flex-end">
              <Button
                icon={<UIcon icon="xClose" color={sysTextDefault} size={16} />}
                type="text"
                onClick={onClose}
              />
            </Flex>
            {hasList[entityType] ? (
              <Flex column height={"calc(100% - 32px)"} gap={24}>
                <Segmented
                  options={[
                    { value: "opportunity", label: "Opportunity" },
                    { value: "list", label: "List" },
                  ]}
                  onChange={(value: "opportunity" | "list") => setSelectedTab(value)}
                  value={selectedTab}
                  block
                />
                <ContentContainer hasList={true}>
                  {selectedTab === "opportunity" ? (
                    <SaveToOpportunity
                      entityType={entityType}
                      entityId={entityId}
                      defaultOpportunityValues={defaultOpportunityValues}
                      closeDrawer={onClose}
                    />
                  ) : (
                    <SaveToLists entityType={entityType} entityId={entityListId ?? entityId} />
                  )}
                </ContentContainer>
              </Flex>
            ) : (
              <ContentContainer hasList={false}>
                <SaveToOpportunity
                  entityType={entityType}
                  entityId={entityId}
                  defaultOpportunityValues={defaultOpportunityValues}
                  closeDrawer={onClose}
                />
              </ContentContainer>
            )}
          </Flex>
        </Flex>
      </TrackingProvider>
    </Drawer>
  );
}

const ContentContainer = styled.div<{ hasList: boolean }>((hasList) => ({
  height: hasList ? "calc(100% - 64px)" : "calc(100% - 32px)",
}));
