import { OpportunityWorkflow_Transitions } from "lib/generated/app-service-gql/graphql";
import { OpportunityDetails } from "../../lib/hooks/api/opportunities/useOpportunity";

const keysToKeep = ["id", "name", "description", "value", "closeDate"];

export const filterObject = (obj: Record<string, unknown>, keys: string[]) => {
  return Object.keys(obj)
    .filter((key) => keys.includes(key))
    .reduce<Record<string, unknown>>((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
};

export const filterOpportunityDefaultValues = (opportunity: OpportunityDetails) => {
  const filteredPayload = filterObject(opportunity, keysToKeep);
  return {
    ...filteredPayload,
    assignedToId: opportunity.assignedTo?.guid,
    stageId: opportunity.stage.id,
  };
};

export const getOpportunityStageById = (
  id: string,
  transitions: OpportunityWorkflow_Transitions[],
) => {
  return transitions.find((transition) => transition.stage.id === id);
};
