// NOTE: Try not to use the primitive colours unless necessary, use the semantic colours instead
const primitiveColours = {
  // https://www.figma.com/design/bH5c5Hh9lYRJQs2ytLlv2o/Stotles-DS---Design-Tokens-v1.0?node-id=3-3275&t=SawDKqs3pcKStcTQ-4

  // Basics

  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysTextWhite | sysTextWhite}, {@link lightTheme.colors.sysBackgroundDefault | sysBackgroundDefault}, {@link lightTheme.colors.sysBorderInverse | sysBorderInverse}
   */
  white: "#FFFFFF",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysTextBlack | sysTextBlack}
   */
  black: "#000000",

  // Blues

  /**
   * @deprecated Prefer using semantic colours instead
   */
  blue25: "#F7FAFF",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysPrimarySubtle | sysPrimarySubtle}, {@link lightTheme.colors.sysInfoSubtle | sysInfoSubtle}
   */
  blue50: "#EDF3FF",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysPrimarySubtleAlt | sysPrimarySubtleAlt}, {@link lightTheme.colors.sysInfoSubtleAlt}, {@link lightTheme.colors.sysSignalKeyword | sysSignalKeyword}
   */
  blue100: "#CADBFE",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandBlueComplimentary | sysBrandBlueComplimentary}
   */
  blue200: "#9DBCFB",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  blue300: "#84AAFB",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandBlueDefault | sysBrandBlueDefault}
   */
  blue400: "#4F86FA",
  /**
   * @deprecated Prefer using semantic colours instead.
   *
   * Used by {@link lightTheme.colors.sysPrimaryDefault | sysPrimaryDefault}, {@link lightTheme.colors.sysInfoDefault | sysInfoDefault}
   */
  blue500: "#2569F9",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysPrimaryHover | sysPrimaryHover}, {@link lightTheme.colors.sysInfoHover | sysInfoHover}
   */
  blue600: "#074FE9",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysPrimaryPressed | sysPrimaryPressed}, {@link lightTheme.colors.sysInfoPressed | sysInfoPressed}
   */
  blue700: "#0644C6",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  blue800: "#04369F",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysFocusBr | sysFocusBr}
   */
  blue900: "#032772",

  // Greys

  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBackgroundAlternative | sysBackgroundAlternative}, {@link lightTheme.colors.sysBorderSecondary | sysBorderSecondary}
   */
  grey10: "#F7F7F8",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBackgroundInactive | sysBackgroundInactive}
   */
  grey25: "#EEEFF1",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBorderPrimary | sysBorderPrimary}, {@link lightTheme.colors.sysSignalDefault | sysSignalDefault}
   */
  grey50: "#E6E7EA",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  grey100: "#D5D8DD",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  grey200: "#B3B8C1",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysTextInactive | sysTextInactive}
   */
  grey300: "#9299A6",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysTextPlaceholder | sysTextPlaceholder}
   */
  grey400: "#707A8B",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysTextSecondary | sysTextSecondary}
   */
  grey500: "#4F5A6F",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBackgroundInverse | sysBackgroundInverse}
   */
  grey600: "#2D3B54",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysTextDefault | sysTextDefault}
   */
  grey700: "#263247",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  grey800: "#1F2839",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBackgroundOverlay | sysBackgroundOverlay}
   */
  grey900: "#171F2C",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  grey1000: "#10151E",

  // Reds

  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysDestructiveSubtle | sysDestructiveSubtle}
   */
  red50: "#FFEEF0",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSignalCompetitor | sysSignalCompetitor}, {@link lightTheme.colors.sysDestructiveSubtleAlt | sysDestructiveSubtleAlt}
   */
  red100: "#FECDD1",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  red200: "#FDABB3",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  red300: "#FB8994",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysStatusNegative | sysStatusNegative}, {@link lightTheme.colors.sysBrandRedDefault | sysBrandRedDefault}
   */
  red400: "#FA5766",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysDestructiveDefault | sysDestructiveDefault}
   */
  red500: "#E9071C",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysDestructiveHover | sysDestructiveHover}
   */
  red600: "#D10519",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysDestructivePressed | sysDestructivePressed}, {@link lightTheme.colors.sysBrandRedComplimentary | sysBrandRedComplimentary}
   */
  red700: "#BF1642",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  red800: "#A80514",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  red900: "#81040F",

  // Greens

  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSuccessSubtle | sysSuccessSubtle}
   */
  green50: "#E5F5EB",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSuccessSubtleAlt | sysSuccessSubtleAlt}
   */
  green100: "#D5EEDD",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  green200: "#B3E1C2",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  green300: "#91D3A7",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  green400: "#6FC68B",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysStatusPositive | sysStatusPositive}
   */
  green500: "#4DB870",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSuccessDefault | sysSuccessDefault}
   */
  green600: "#2BAB55",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSuccessHover | sysSuccessHover}
   */
  green700: "#249047",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSuccessPressed | sysSuccessPressed}
   */
  green800: "#1D743A",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  green900: "#0F3E1F",

  // Yellows

  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysWarningSubtle | sysWarningSubtle}
   */
  yellow50: "#FFFBF0",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysWarningSubtleAlt | sysWarningSubtleAlt}
   */
  yellow100: "#FFF3D3",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSignalPartner | sysSignalPartner}
   */
  yellow200: "#FFECB6",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  yellow300: "#FFE498",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandYellowDefault | sysBrandYellowDefault}
   */
  yellow400: "#FFD86C",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysStatusInProgress | sysStatusInProgress}, {@link lightTheme.colors.sysBrandYellowComplimentary | sysBrandYellowComplimentary}
   */
  yellow500: "#FFC932",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysWarningDefault | sysWarningDefault}
   */
  yellow600: "#FAB800",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysWarningHover | sysWarningHover}
   */
  yellow700: "#D69E00",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysWarningPressed | sysWarningPressed}
   */
  yellow800: "#9E7400",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  yellow900: "#423100",

  // Navies

  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy50: "#E2E4F3",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy100: "#BDC2E5",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandNavyComplimentary | sysBrandNavyComplimentary}
   */
  navy200: "#8C94D1",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy300: "#707BC7",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy400: "#5B66BE",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy500: "#4753B3",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy600: "#464E8B",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandNavyDefault | sysBrandNavyDefault}
   */
  navy700: "#313A7E",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy800: "#272E63",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  navy900: "#1A1F42",

  // Purples

  /**
   * @deprecated Prefer using semantic colours instead
   */
  purple50: "#F2EFF9",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysSignalBuyer | sysSignalBuyer}
   */
  purple100: "#D7CFED",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  purple200: "#C9BEE6",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  purple300: "#A18ED4",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandPurpleDefault | sysBrandPurpleDefault}
   */
  purple400: "#785DC1",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  purple500: "#6345B5",
  /**
   * @deprecated Prefer using semantic colours instead
   *
   * Used by {@link lightTheme.colors.sysBrandPurpleComplimentary | sysBrandPurpleComplimentary}
   */
  purple600: "#4732A8",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  purple700: "#473181",
  /**
   * @deprecated Prefer using semantic colours instead
   */
  purple800: "#392767",
} as const;

const brandColors = {
  hubspotOrange: "#ff7a59",
  salesforceBlue: "#00A1E0",
};

export const lightTheme = {
  colors: {
    // NOTE: Try not to use the primitive colours unless necessary, use the semantic colours instead
    ...primitiveColours,
    ...brandColors,

    // Semantics
    // https://www.figma.com/design/bH5c5Hh9lYRJQs2ytLlv2o/Stotles-DS---Design-Tokens-v1.0?node-id=5-5652&t=YSoM6GgzqFuSuiOM-4
    sysPrimaryDefault: primitiveColours.blue500,
    sysPrimaryHover: primitiveColours.blue600,
    sysPrimaryPressed: primitiveColours.blue700,
    sysPrimarySubtle: primitiveColours.blue50,
    sysPrimarySubtleAlt: primitiveColours.blue100,

    sysTextDefault: primitiveColours.grey700,
    sysTextSecondary: primitiveColours.grey500,
    sysTextInactive: primitiveColours.grey300,
    sysTextPlaceholder: primitiveColours.grey400,
    sysTextWhite: primitiveColours.white,
    sysTextBlack: primitiveColours.black,

    sysBackgroundDefault: primitiveColours.white,
    sysBackgroundAlternative: primitiveColours.grey10,
    sysBackgroundInactive: primitiveColours.grey25,
    sysBackgroundInverse: primitiveColours.grey600,
    sysBackgroundOverlay: primitiveColours.grey900,

    sysBorderPrimary: primitiveColours.grey50,
    sysBorderSecondary: primitiveColours.grey10,
    sysBorderInverse: primitiveColours.white,

    sysFocusBr: primitiveColours.blue900,

    sysSignalDefault: primitiveColours.grey50,
    sysSignalKeyword: primitiveColours.blue100,
    sysSignalCompetitor: primitiveColours.red100,
    sysSignalPartner: primitiveColours.yellow200,
    sysSignalBuyer: primitiveColours.purple100,

    sysSuccessDefault: primitiveColours.green600,
    sysSuccessHover: primitiveColours.green700,
    sysSuccessPressed: primitiveColours.green800,
    sysSuccessSubtle: primitiveColours.green50,
    sysSuccessSubtleAlt: primitiveColours.green100,

    sysDestructiveDefault: primitiveColours.red500,
    sysDestructiveHover: primitiveColours.red600,
    sysDestructivePressed: primitiveColours.red800,
    sysDestructiveSubtle: primitiveColours.red50,
    sysDestructiveSubtleAlt: primitiveColours.red100,

    sysWarningDefault: primitiveColours.yellow600,
    sysWarningHover: primitiveColours.yellow700,
    sysWarningPressed: primitiveColours.yellow800,
    sysWarningSubtle: primitiveColours.yellow50,
    sysWarningSubtleAlt: primitiveColours.yellow100,

    sysInfoDefault: primitiveColours.blue500,
    sysInfoHover: primitiveColours.blue600,
    sysInfoPressed: primitiveColours.blue700,
    sysInfoSubtle: primitiveColours.blue50,
    sysInfoSubtleAlt: primitiveColours.blue100,

    sysStatusPositive: primitiveColours.green500,
    sysStatusNegative: primitiveColours.red400,
    sysStatusInProgress: primitiveColours.yellow500,

    sysBrandBlueDefault: primitiveColours.blue400,
    sysBrandBlueComplimentary: primitiveColours.blue200,
    sysBrandYellowDefault: primitiveColours.yellow400,
    sysBrandYellowComplimentary: primitiveColours.yellow500,
    sysBrandRedDefault: primitiveColours.red400,
    sysBrandRedComplimentary: primitiveColours.red700,
    sysBrandPurpleDefault: primitiveColours.purple400,
    sysBrandPurpleComplimentary: primitiveColours.purple600,
    sysBrandNavyDefault: primitiveColours.navy700,
    sysBrandNavyComplimentary: primitiveColours.navy200,
  },
} as const;
