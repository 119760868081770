import {
  QueryKey,
  UseMutationOptions,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  BidsQuery,
  BidsSearchRequest,
  BidsSearchResponse,
  CreateBidMutation,
  CreateBidMutationVariables,
  DeleteBidMutation,
  DeleteBidMutationVariables,
  FindBidByIdQuery,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation, useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const BIDS_QUERY = graphql(`
  query bids($request: BidsSearchRequest!) {
    bids(BidsSearchRequest: $request) {
      results {
        id
        createdAt
        updatedAt
        procurementStageId
      }
      totalResults
    }
  }
`);

const findBidByIdQuery = graphql(`
  query FindBidById($id: String!) {
    findBidById(id: $id) {
      id
      createdAt
      updatedAt
      procurementStageId
    }
  }
`);

const createBid = graphql(`
  mutation createBid($bid: CreateBidRequest!) {
    createBid(bid: $bid) {
      id
    }
  }
`);

const deleteBid = graphql(`
  mutation deleteBid($input: BidDeleteRequest!) {
    deleteBid(BidDeleteRequest: $input) {
      id
    }
  }
`);

export type BidsDto = BidsSearchResponse["results"][0];

export function useBids(
  searchBidsRequest: BidsSearchRequest,
  options?: UseQueryOptions<BidsQuery, unknown, BidsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["bids", searchBidsRequest],
    BIDS_QUERY,
    [{ request: searchBidsRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.bids, ...rest };
}

export function useFindBidById(
  bidId: string,
  options?: UseQueryOptions<FindBidByIdQuery, unknown, FindBidByIdQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["bid", bidId], findBidByIdQuery, [{ id: bidId }], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return { data: data?.findBidById, ...rest };
}

export function useCreateBid(
  options?: UseMutationOptions<CreateBidMutation, unknown, CreateBidMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useGraphQlMutation(createBid, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      void queryClient.invalidateQueries(["bids"]);
    },
  });
  return { data: data?.createBid, ...rest };
}

export function useDeleteBid(
  options?: UseMutationOptions<DeleteBidMutation, unknown, DeleteBidMutationVariables, unknown>,
) {
  const queryClient = useQueryClient();

  const { data, ...rest } = useGraphQlMutation(deleteBid, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      void queryClient.invalidateQueries(["bids"]);
    },
  });
  return { data: data?.deleteBid, ...rest };
}
