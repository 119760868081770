import { BriefingType } from "components/actions/GetAccountBriefingButton";
import { BuyingStats } from "components/organisation_clean/types";
import { DocumentSearchResponse, File } from "./documents/types";
import {
  BuyerSummary,
  DocumentMatch,
  EmailSubscription,
  IntegrationSubscription,
  LeadSignal,
  LeadSubscriptionFrequency,
  OscarOrgDetails,
  RecordDetails,
  RecordListSummary,
  RecordPreview,
  SavedView,
  Status,
  SupplierSummary,
  UserListSummaryWithCount,
} from "./types/models";
import API from "./Api";

type EmptyObject = Record<string, never>;

type AddToListRequest = {
  record_list_entry: {
    guid: string;
  };
};

type PagingInfo = {
  total_results: number;
  offset: number;
  limit: number;
  next_offset: number | null;
};

type SuppliersForBuyersQuery = {
  signal_sort?: "ASC" | "DESC";
  offset?: number;
  limit?: number;
  sort?: string;
  sort_order?: "ASC" | "DESC";
  text?: string;
  company_guid_override?: string;
  team_id?: string;
};

type GetSuppliersForBuyerResponse = {
  suppliers: SupplierSummary[];
  additional_supplier_data: {
    [supplier_id: number]: { award_count: number; latest_activity: string; signals: LeadSignal[] };
  };
  paging_info: PagingInfo;
};

type ManageUserListRequest = {
  name: string;
  record_guids?: string[];
};

export type SignalFilters = {
  keywords: string[];
  partners: string[];
  competitors: string[];
};

// TODO: we shouldn't actually need this, because the converter should handle it - but we already
// have some places in code that do this
type BoolAsParam = "Y" | "N";

export type SearchRecordsParams = {
  cpv_dimensions?: string[];
  buyer_id?: number[];
  buyer_guid?: string[];
  record_type?: "CONTRACT" | "TENDER" | "PIN";
  offset?: number;
  limit?: number;
  sort?: string;
  sort_order?: "ASC" | "DESC";
  publish_date_to?: string;
  publish_date_from?: string;
  publish_date_relative_to?: string;
  publish_date_relative_from?: string;
  country?: string[];
  language?: string[];
  value_from?: number;
  value_to?: number;
  value_nulls?: boolean;
  close_date_from?: string;
  close_date_to?: string;
  close_date_relative_from?: string;
  close_date_relative_to?: string;
  close_nulls?: BoolAsParam;
  stage?: string[];
  buyer_category_id?: string[];
  buyer_category_nulls?: boolean;
  award_date_from?: string;
  award_date_to?: string;
  award_date_relative_from?: string;
  award_date_relative_to?: string;
  expiry_date_from?: string;
  expiry_date_to?: string;
  expiry_date_relative_from?: string;
  expiry_date_relative_to?: string;
  expiry_nulls?: BoolAsParam;
  text?: string;
  buyer_country?: string[];
  supplier_sme?: string;
  id?: string[];
  supplier_id?: number[];
  supplier_guid?: string[];
  supplier_country?: string[];
  qued?: string;
  signal_sort?: "ASC" | "DESC";
  // We currently only allow to search by title AND description or title, description AND signal.
  // No other combination
  text_search_fields?: ["signal", "title", "description"] | ["title", "description"];
  // TODO: Once we have relevance scores we'll get rid of "exclude signal category"
  exclude_signal_category?: "Buyers" | "Keywords" | "CPV codes" | "Partners" | "Competitors";
  signals?: Partial<SignalFilters>;
};

export type SearchResponse<R> = {
  results: R[];
  paging_info: PagingInfo;
};

type SearchRecordsResponse = SearchResponse<RecordDetails>;

export type GetContactsForBuyerResponse = {
  contacts: {
    name: string;
    emails: {
      value: string;
      last_referenced: string;
    }[];
    phone_numbers: {
      value: string;
      last_referenced: string;
    }[];
    last_referenced: string;
  }[];
};

type GetDocumentsResponse = {
  matched_documents: DocumentMatch[];
  signals: Record<string, Signal>;
};

type GetDocumentHighlightResponse = {
  id: string;
  name: string;
  description: string;
  matched_words: string[];
};

export type SearchBuyersParams = {
  offset?: number;
  limit?: number;
  sort?: string;
  sort_order?: "ASC" | "DESC";
  country?: string[];
  text?: string;
  id?: number[];
  guids?: string[];
  type?: string[];
  full_address?: string;
  category_id?: string[];
  category_nulls?: boolean;
  include_archived?: boolean;
};

export type SearchBuyersResponse = SearchResponse<BuyerSummary>;

export type SearchSuppliersParams = {
  offset?: number;
  limit?: number;
  sort?: string;
  sort_order?: "ASC" | "DESC";
  country?: string[];
  text?: string;
  is_sme?: string;
  record_id?: string[];
  buyer_id?: number[];
  id?: number[];
  guid?: string[];
  stats?: boolean;
};

export type SearchSuppliersResponse = SearchResponse<SupplierSummary>;

type SearchOscarOrgsParams = {
  offset?: number;
  limit?: number;
  sort?: string;
  sort_order?: "ASC" | "DESC";
  text?: string;
  id?: string[];
};

type SearchOscarOrgsResponse = SearchResponse<OscarOrgDetails>;

export type SubmitShareViaEmailFormParams = {
  email: string;
  cc: boolean;
  guids: string[];
  message?: string;
};

export type GetJobResponse = {
  finished: boolean;
  state: "running" | "completed" | "failed";
};

export type PreviewRecordQueryRequest = {
  query: SelfServeQuery;
  from_date?: string;
  to_date?: string;
};

type TextMatch = {
  field: string;
  start: number;
  end: number;
  keyword?: string;
};

export type RecordMatchInfo = {
  keyword_matches: TextMatch[];
  // TODO: Add other types of matches here
};

export enum SignalCategory {
  KEYWORD = "Keywords",
  PARTNER = "Partners",
  COMPETITOR = "Competitors",
  CPV_CODE = "CPV codes",
}
type SignalId = string & { __type_tag: "SignalId" };

export type Signal = {
  id: SignalId;
  category: string;
  name: string;
  signal_criteria?: SignalCriteria;
};

type SignalCriteria = {
  keyword?: string;
  organisation_id?: string;
  organisation_name?: string;
  cpv_code?: string;
};

export type PreviewRecordMatch = {
  signal_ids: string[];
  preview_match_info: {
    keywords: string[];
    partner_matches?: string[];
    competitor_matches?: string[];
    buyer_matches?: string[];
  };
};

// the key should be a record guid
export type PreviewGuidMatchMap = Record<string, PreviewRecordMatch>;

export type PreviewRecordQueryResponse<T = RecordPreview> = {
  preview_results: T[];
  guid_match_map: PreviewGuidMatchMap;
  signals: Signal[];
};

export type SelfServeQuery = {
  countries: string[];
  languages: string[] | undefined;
  keywords: {
    keywords: string[];
    from_date: Date;
    status: Status[];
    exclude_keywords?: string[];
    cpv_codes?: string[];
    cpv_codes_include_null?: boolean;
  };
  partners?: {
    supplier_ids: number[];
    org_ids: string[];
    supplier_names: string[];
    from_date: Date;
  };
  competitors?: {
    supplier_ids: number[];
    org_ids: string[];
    supplier_names: string[];
    from_date: Date;
  };
  buyers?: {
    buyer_ids: number[];
    from_date: Date;
    sectors?: string[];
    sectors_include_null?: boolean;
    keywords: string[];
    cpv_codes: string[];
    cpv_codes_include_null?: boolean;
  };
  cpv_codes?: {
    codes: string[];
  };
};

type SaveSelfServeQueryRequest = {
  query: SelfServeQuery;
};

type SaveSelfServeQueryResponse = {
  job_id: string;
};

type CpvCodeTreeResponse = {
  tree: CpvCodeTreeSection[];
};

export type CpvCodeTreeSection = {
  name: string;
  code: string;
  children: CpvCodeTreeSection[] | null;
  sector_code: string;
  sector_name: string;
};

export type PersonalNotificationSettings = {
  notifications_enabled: boolean;
  send_app_activity_notifications: boolean;
};

type DisplaySettings = { show_buyer_categories?: boolean };

// This corresponds to the Ruby QueryRunner::RecordMatch type
// signal_ids has been removed for compatibility with complex queries
//
// TODO: Replace with types based on Search APIs return type
export type RecordMatch = {
  text_matches: TextMatch[];
};

type AnnotateRecordsRequest = {
  record_guids: string[];
  criteria?: SelfServeQuery;
};

type AnnotateRecordsResponse = {
  matched_records: Record<string, RecordMatch>;
};

type UserInvitationParams = {
  email_addresses: string[];
};

export type LeadSubscriptions = {
  personal_subscriptions: {
    email: EmailSubscription[];
  };
  company_subscriptions: {
    slack: IntegrationSubscription[];
    teams: IntegrationSubscription[];
  };
};

export type LeadSupscriptionUpdate = {
  frequency: LeadSubscriptionFrequency;
  view_ids: string[];
  list_ids: string[];
  webhook_url?: string;
  name?: string;
  type?: "EMAIL" | "SLACK" | "TEAMS";
};

export type BuyerCpvStats = {
  total_value: number;
  total_awarded: number;
  avg_contract_value: number;
  percent_to_smes: number;
};

export type BuyerCpvStatsPerCode = {
  code: string;
  name: string;
} & BuyerCpvStats;

export type BuyerCpvStatsResponse = {
  overall_stats: BuyerCpvStats;
  per_cpv_stats: BuyerCpvStatsPerCode[];
};

export type OwnerType = "BUYER" | "RECORD" | "SUPPLIER";

type UploadFileListRequest = {
  uploaded_by: string;
  owner_guid: string;
  owner_type: OwnerType;
  document_type?: string;
  description?: string;
  source_url?: string;
  published_at?: string;
  file_link: string;
};

export type BuyerCategory = {
  id: string;
  name: string;
  parent_category_id: string | null;
};

type BuyerCategoriesResponse = {
  buyer_categories: BuyerCategory[];
};

type BuyersStatsResponse = {
  stats: Record<string, BuyingStats>;
};

class StotlesAPI extends API {
  constructor(endpoint: string = window.location.origin) {
    super(endpoint);
  }

  addRecordToList(listId: number, recordGUID: string): Promise<Record<string, never>> {
    return this.fetchJSON<AddToListRequest, EmptyObject>(
      "POST",
      `api/record_lists/${listId}/records`,
      {
        body: { record_list_entry: { guid: recordGUID } },
      },
    );
  }

  removeRecordFromList(listId: number, recordGUID: string): Promise<null> {
    return this.fetchJSON<EmptyObject, null>(
      "DELETE",
      `api/record_lists/${listId}/records/${recordGUID}`,
    );
  }

  getSuppliersForBuyer(
    buyerID: number,
    query: SuppliersForBuyersQuery,
  ): Promise<GetSuppliersForBuyerResponse> {
    return this.fetchJSON<void, GetSuppliersForBuyerResponse>(
      "GET",
      `api/buyers/${buyerID}/suppliers_v2`,
      { query: this.toURLQueryParams(query) },
    );
  }

  getContactsForBuyer(
    buyerID: number,
    filters?: { cpvDimensions?: string[] },
  ): Promise<GetContactsForBuyerResponse> {
    const query: Record<string, any> = {};
    if (filters) {
      if (filters.cpvDimensions) {
        query["cpv_dimensions"] = filters.cpvDimensions;
      }
    }
    return this.fetchJSON("GET", `api/buyers/${buyerID}/contacts`, {
      query,
    });
  }

  getDocuments(
    filters: {
      ownerGuid?: string;
      fetch_all?: boolean;
    },
    pageSize?: number,
  ): Promise<GetDocumentsResponse> {
    return this.fetchJSON("GET", `api/documents/search/`, {
      query: this.toURLQueryParams({
        owner_guid: filters.ownerGuid,
        fetch_all: filters.fetch_all,
        page_size: pageSize,
      }),
    });
  }

  getDocumentHighlights(documentId: string): Promise<GetDocumentHighlightResponse> {
    return this.fetchJSON("GET", `api/documents/highlight/`, {
      query: this.toURLQueryParams({
        id: documentId,
      }),
    });
  }

  getRecordListsForRecord(guid: string): Promise<{ record_lists: RecordListSummary[] }> {
    return this.fetchJSON<EmptyObject, { record_lists: RecordListSummary[] }>(
      "GET",
      `api/records/${guid}/record_lists`,
    );
  }

  getUserLists(): Promise<{ user_lists: UserListSummaryWithCount[] }> {
    return this.fetchJSON<EmptyObject, { user_lists: UserListSummaryWithCount[] }>(
      "GET",
      `api/user_lists`,
    );
  }

  createUserList(name: string, recordGuids?: string[]): Promise<UserListSummaryWithCount> {
    return this.fetchJSON<ManageUserListRequest, UserListSummaryWithCount>(
      "POST",
      `api/user_lists`,
      {
        body: { name: name, record_guids: recordGuids },
      },
    );
  }

  updateUserList(userListId: number, name: string): Promise<EmptyObject> {
    return this.fetchJSON<ManageUserListRequest, EmptyObject>(
      "PATCH",
      `api/user_lists/${userListId}/`,
      {
        body: { name: name },
      },
    );
  }

  duplicateUserList(userListId: number, name: string): Promise<UserListSummaryWithCount> {
    return this.fetchJSON<ManageUserListRequest, UserListSummaryWithCount>(
      "POST",
      `api/user_lists/${userListId}/duplicate`,
      {
        body: { name: name },
      },
    );
  }

  deleteUserList(userListId: number): Promise<EmptyObject> {
    return this.fetchJSON<EmptyObject, EmptyObject>("DELETE", `api/user_lists/${userListId}/`);
  }

  exportSearchRecords(params: SearchRecordsParams, format?: "csv" | "xlsx"): Promise<Blob> {
    return this.fetchData<SearchRecordsParams>("POST", `api/records${format ? "." + format : ""}`, {
      body: this.sanitiseBody(params),
    });
  }

  searchRecords(params: SearchRecordsParams): Promise<SearchResponse<RecordDetails>> {
    return this.fetchJSON<SearchRecordsParams, SearchRecordsResponse>("POST", `api/records`, {
      body: this.sanitiseBody(params),
    });
  }

  searchBuyers(params: SearchBuyersParams): Promise<SearchResponse<BuyerSummary>> {
    return this.fetchJSON<EmptyObject, SearchBuyersResponse>("GET", `api/buyers`, {
      query: this.toURLQueryParams(params),
    });
  }

  exportSearchBuyers(params: SearchBuyersParams, format?: "csv" | "xlsx"): Promise<Blob> {
    return this.fetchData<EmptyObject>("GET", `api/buyers${format ? "." + format : ""}`, {
      query: this.toURLQueryParams(params),
    });
  }

  exportBuyerList(listId: string, format: "csv" | "xlsx"): Promise<Blob> {
    return this.fetchData<EmptyObject>("GET", `admin/api/buyer_lists/${listId}/export`, {
      query: this.toURLQueryParams({ format: format }),
    });
  }

  searchSuppliers(params: SearchSuppliersParams): Promise<SearchResponse<SupplierSummary>> {
    return this.fetchJSON<EmptyObject, SearchSuppliersResponse>("GET", `api/suppliers`, {
      query: this.toURLQueryParams(params),
    });
  }

  searchOscarOrgs(params: SearchOscarOrgsParams): Promise<SearchResponse<OscarOrgDetails>> {
    return this.fetchJSON<EmptyObject, SearchOscarOrgsResponse>("GET", `api/oscar_orgs`, {
      query: this.toURLQueryParams(params),
    });
  }

  searchBuyersInList(
    params: SearchBuyersParams,
    companyId: number,
    listId: number,
  ): Promise<SearchResponse<BuyerSummary>> {
    return this.fetchJSON<EmptyObject, SearchBuyersResponse>(
      "GET",
      `api/companies/${companyId}/record_lists/${listId}/buyers`,
      { query: this.toURLQueryParams(params) },
    );
  }

  submitShareViaEmailForm(params: SubmitShareViaEmailFormParams): Promise<EmptyObject> {
    return this.fetchJSON<SubmitShareViaEmailFormParams, EmptyObject>("POST", "api/records/share", {
      body: params,
    });
  }

  sendPasswordResetRequest(): Promise<EmptyObject> {
    return this.fetchJSON<EmptyObject, EmptyObject>("POST", "api/users/reset_password");
  }

  getPersonalNotificationSettings(): Promise<PersonalNotificationSettings> {
    return this.fetchJSON<EmptyObject, PersonalNotificationSettings>(
      "GET",
      "api/notification_settings",
    );
  }

  updateNotificationSettings(
    settings: PersonalNotificationSettings,
  ): Promise<PersonalNotificationSettings> {
    return this.fetchJSON<PersonalNotificationSettings, PersonalNotificationSettings>(
      "POST",
      "api/notification_settings",
      { body: settings },
    );
  }

  getDisplaySettings(): Promise<DisplaySettings> {
    return this.fetchJSON<EmptyObject, DisplaySettings>("GET", "api/display_settings");
  }

  updateDisplaySettings(settings: DisplaySettings): Promise<{ status: number }> {
    return this.fetchJSON<DisplaySettings, { status: number }>("POST", "api/display_settings", {
      body: settings,
    });
  }

  sendVerificationEmailRequest(): Promise<{ message: string; status: number }> {
    return this.fetchJSON<EmptyObject, { message: string; status: number }>(
      "POST",
      "api/users/send_verification_email",
    );
  }

  searchFolder(filters: {
    buyerGuids: string[] | undefined;
    text: string | undefined;
    userGuid?: string[] | undefined;
  }): Promise<DocumentSearchResponse> {
    return this.fetchJSON<void, DocumentSearchResponse>("GET", `api/documents/searchFolder`, {
      query: this.toURLQueryParams({
        guids: filters.buyerGuids?.map((e) => e.toString()).join(","),
        query: filters.text,
        userGuid: filters.userGuid,
      }),
    });
  }

  fetchDocument(documentId: string): Promise<File> {
    return this.fetchJSON<void, File>("GET", `api/documents/getFile`, {
      query: this.toURLQueryParams({
        id: documentId,
      }),
    });
  }

  updateDocument(file: File): Promise<void> {
    return this.fetchJSON<File, void>("POST", `api/documents/updateFile`, {
      body: {
        ...file,
      },
    });
  }

  deleteDocument(documentId: string): Promise<void> {
    return this.fetchJSON<EmptyObject, void>("DELETE", `api/documents/${documentId}`);
  }

  uploadLinkedFile(
    uploadedBy: string,
    ownerGuid: string,
    ownerType: OwnerType,
    fileLink: string,
    documentType?: string,
    description?: string,
    sourceUrl?: string,
    publishedDate?: string,
  ): Promise<void> {
    return this.fetchJSON<UploadFileListRequest, void>("POST", `api/documents/uploadLink`, {
      body: {
        uploaded_by: uploadedBy,
        owner_guid: ownerGuid,
        owner_type: ownerType,
        file_link: fileLink,
        ...(description && { description }),
        ...(documentType && { document_type: documentType }),
        ...(publishedDate && { published_at: publishedDate }),
        ...(sourceUrl && { source_url: sourceUrl }),
      },
    });
  }

  saveSelfServeQuery(
    companyId: number,
    request: SaveSelfServeQueryRequest,
  ): Promise<SaveSelfServeQueryResponse> {
    return this.fetchJSON<SaveSelfServeQueryRequest, SaveSelfServeQueryResponse>(
      "PUT",
      `api/companies/${companyId}/record_queries/self_serve`,
      {
        body: request,
      },
    );
  }

  completeOnboarding(companyId: number): Promise<EmptyObject> {
    return this.fetchJSON<EmptyObject, EmptyObject>(
      "POST",
      `api/companies/${companyId}/complete_onboarding`,
    );
  }

  getCompanyLogo(companyGuid: string): Promise<{ logo_url: string }> {
    return this.fetchJSON<EmptyObject, { logo_url: string }>(
      "GET",
      `api/companies/${companyGuid}/logo`,
    );
  }

  previewRecordQuery(
    request: PreviewRecordQueryRequest,
  ): Promise<PreviewRecordQueryResponse<RecordPreview>> {
    return this.fetchJSON<PreviewRecordQueryRequest, PreviewRecordQueryResponse<RecordPreview>>(
      "POST",
      `api/record_queries/preview`,
      {
        body: request,
      },
    );
  }

  feedPreview(
    companyId: number,
    request: PreviewRecordQueryRequest,
  ): Promise<PreviewRecordQueryResponse<RecordPreview>> {
    return this.fetchJSON<PreviewRecordQueryRequest, PreviewRecordQueryResponse<RecordPreview>>(
      "POST",
      `api/companies/${companyId}/feed_preview`,
      {
        body: request,
      },
    );
  }

  getCpvCodesBySector(sectorCodes: string[]): Promise<CpvCodeTreeResponse> {
    return this.fetchJSON<EmptyObject, CpvCodeTreeResponse>(
      "GET",
      `api/cpv_codes?sectors=${sectorCodes.join(",")}`,
    );
  }

  toURLQueryParams<Input extends Record<string, any>>(input: Input): Record<string, string> {
    const queryParams: Record<string, string> = {};

    for (const key of Object.keys(input) as Iterable<keyof SearchBuyersParams>) {
      const value = input[key];
      if (value === undefined) {
        continue;
      }
      if (value === null) {
        queryParams[key] = "";
      } else if (Array.isArray(value)) {
        queryParams[key] = value.map((e) => e.toString()).join(",");
      } else if (typeof value === "boolean") {
        queryParams[key] = value ? "Y" : "N";
      } else if (typeof value !== "string") {
        queryParams[key] = value.toString();
      } else {
        queryParams[key] = value;
      }
    }

    return queryParams;
  }

  /**
   * Standardise and sanitise a body for a post request to make sure it is uniform
   */
  private sanitiseBody(input: Record<string, any>) {
    const body: Record<string, any> = {};

    for (const [key, value] of Object.entries(input)) {
      // consistency with toURLQueryParams
      if (value === undefined) {
        continue;
      } else if (Array.isArray(value)) {
        // for the time being we will also keep this consistent with GET requests, for the benefit
        // of existing params. Back end changes will be required to adjust how Rails permits
        // existing parameters
        body[key] = value.map((e) => e.toString()).join(",");
      } else {
        body[key] = value;
      }
    }

    return body;
  }

  getJob(jobId: string): Promise<GetJobResponse> {
    return this.fetchJSON<EmptyObject, GetJobResponse>("GET", `api/jobs/${jobId}`);
  }

  annotateRecords(
    companyId: number,
    guids: string[],
    criteria?: SelfServeQuery,
  ): Promise<AnnotateRecordsResponse> {
    return this.fetchJSON<AnnotateRecordsRequest, AnnotateRecordsResponse>(
      "POST",
      `api/companies/${companyId}/record_queries/annotate`,
      {
        body: {
          record_guids: guids,
          criteria: criteria,
        },
      },
    );
  }

  getAvailableSavedViews(): Promise<{ saved_views: SavedView[] }> {
    return this.fetchJSON<EmptyObject, { saved_views: SavedView[] }>("GET", "api/saved_views/");
  }

  deleteSavedView(savedViewId: string): Promise<EmptyObject> {
    return this.fetchJSON<EmptyObject, EmptyObject>("DELETE", `api/saved_views/${savedViewId}`);
  }

  sendInvites(...emailAdresses: string[]): Promise<void> {
    return this.fetchJSON<UserInvitationParams, never>("POST", "api/user_invitations", {
      body: { email_addresses: emailAdresses },
    });
  }

  createAccountBriefingRequest(
    buyerId: number,
    briefing_type: BriefingType,
    url: string,
  ): Promise<unknown> {
    return this.fetchJSON("POST", `api/buyers/${buyerId}/briefing`, {
      body: {
        url,
        briefing_type,
      },
    });
  }
  getLeadSubscriptions(): Promise<LeadSubscriptions> {
    return this.fetchJSON("GET", "api/lead_subscriptions");
  }

  updateLeadSubscription(
    subscriptionUpdate: LeadSupscriptionUpdate & {
      disabled?: boolean;
    },
    id: string,
  ): Promise<void> {
    return this.fetchJSON("PUT", `api/lead_subscriptions/${id}`, {
      body: subscriptionUpdate,
    });
  }

  createLeadSubscription(
    subscription: LeadSupscriptionUpdate & {
      company_guid?: string;
      team_id?: string | null;
    },
  ): Promise<void> {
    return this.fetchJSON("POST", "api/lead_subscriptions/", {
      body: subscription,
    });
  }

  deleteLeadSubscription(subscriptionId: string): Promise<void> {
    return this.fetchJSON("DELETE", `api/lead_subscriptions/${subscriptionId}`);
  }

  getBuyerCpvStats(
    buyerId: number,
    upcomingExpiries = false,
    period = "P3Y",
  ): Promise<BuyerCpvStatsResponse> {
    const params = new URLSearchParams({ expiries: String(upcomingExpiries), period });
    return this.fetchJSON<EmptyObject, BuyerCpvStatsResponse>(
      "GET",
      `api/buyers/${buyerId}/cpv_stats?${params}`,
    );
  }

  getBuyerCategories(): Promise<BuyerCategoriesResponse> {
    return this.fetchJSON<EmptyObject, BuyerCategoriesResponse>(
      "GET",
      `api/buyers/buyer_categories`,
    );
  }

  reportBuyerRelationship(buyer_id: number): Promise<void> {
    return this.fetchJSON("POST", `api/buyers/${buyer_id}/report_buyer_relationship`, {
      body: {},
    });
  }

  getBuyerStats(buyer_guids: string[]): Promise<BuyersStatsResponse> {
    return this.fetchJSON<{ buyer_guids: string[] }, BuyersStatsResponse>(
      "POST",
      `api/buyers/stats`,
      {
        body: { buyer_guids },
      },
    );
  }

  requestSubscriptionDowngrade(newPlan: string): Promise<void> {
    return this.fetchJSON("POST", `api/subscription/downgrade`, {
      body: { new_plan: newPlan },
    });
  }
}

export default StotlesAPI;
