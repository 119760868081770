import * as rhl from "react-hot-loader";
import * as Sentry from "@sentry/react";
import { Buffer } from "buffer";
import { enableMapSet } from "immer";

import "core-js/stable";
import "regenerator-runtime/runtime";
import "whatwg-fetch";

import { registerSentryAPIErrorHandler } from "lib/Api";
import * as tracking from "lib/tracking";

enableMapSet();

/**
 * Initialise Sentry as early as possible.
 */
try {
  if (window.sentryDSN) {
    console.log("Sentry enabled.");
    Sentry.init({
      dsn: window.sentryDSN,
      environment: window.environment,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.extraErrorDataIntegration(),
      ],
      // Performance Monitoring keeping sampling rate low for now
      tracesSampler: (samplingContext) => {
        const { parentSampled } = samplingContext;

        // If the parent transaction is sampled, we should sample the full trace
        if (parentSampled !== undefined) {
          return parentSampled;
        }
        // we get an allowance of 250000 transactions a month, one trace is ~10 transactions
        // this rate might be too high still, but we can adjust it later
        return 0.3;
      },
      tracePropagationTargets: ["localhost", /^https:\/\/app\.stotles\.com/, /^\//],
      // Session Replay
      replaysSessionSampleRate: 0.001,
      replaysOnErrorSampleRate: 0.2, // we only get 500 a month so we need to be careful

      // Set release version
      ...(window.releaseVersion ? { release: window.releaseVersion } : undefined),
    });
    if (window.currentUser) {
      const { company } = window.currentUser;
      Sentry.setUser({
        email: window.currentUser.email,
        company_id: company.id,
        company_name: company.name,
      });
    }
    registerSentryAPIErrorHandler();
  } else {
    console.log("Sentry not enabled.");
  }
} catch (_) {
  // Ignore errors
}

// Silence some react-hot-loader logs that are not important in our setup
rhl.setConfig({ trackTailUpdates: false });

// Required by the CSV modules used for frontend export (outreach builder for example)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(globalThis as any).Buffer = Buffer;

// Support component names relative to this directory:
const componentRequireContext = require.context("pages/app", true, /^(\.\/)?[^.]+$/);
const ReactRailsUJS = require("react_ujs");
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);

tracking.init();
