import React from "react";
import { App, Modal } from "antd5";

import { CreateOpportunityForm, CreateOpportunityFormValues } from "./CreateOpportunityForm";

type Props = {
  defaultValues?: Partial<CreateOpportunityFormValues>;
  onClose: () => void;
  isOpen: boolean;
};

export function CreateOpportunityModal({ isOpen, defaultValues, onClose }: Props) {
  const { message } = App.useApp();

  return (
    <Modal title="Create Opportunity" open={isOpen} footer={null} onCancel={onClose}>
      <CreateOpportunityForm
        onCancel={onClose}
        onSuccess={() => {
          onClose();
          message.success("Opportunity created successfully");
        }}
        defaultValues={defaultValues}
      />
    </Modal>
  );
}
