import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Flex } from "styles/utility-components";

import {
  attachment01,
  barChart02,
  fileSearch02,
  filterFunnel02,
  trendUp01,
  userCircle,
  usersPlus,
} from "lib/icons/untitled_ui/SVGs";
import UIcon from "lib/icons/untitled_ui/UIcon";
import { sysTextDefault } from "lib/themes/colors";
import { EntityType } from "../types";

import BuyerListExample from "../../../../../assets/images/opportunities/buyer_list_example.svg";
import NoticeListExample from "../../../../../assets/images/opportunities/notice_list_example.svg";
import OpportunityExample from "../../../../../assets/images/opportunities/opportunity_example.svg";
import SupplierListExample from "../../../../../assets/images/opportunities/supplier_list_example.svg";

const object = { opportunity: "opportunity", list: "list" };
const entity = { notice: "notice", buyer: "buyer", supplier: "supplier" };

type ObjectType = keyof typeof object;

type ContentType = {
  header: string;
  description?: ReactNode;
  bullets?: { icon: ReactNode; text: string }[];
};

export default function ObjectOverview({
  objectType,
  entityType,
}: {
  objectType: ObjectType;
  entityType: EntityType;
}) {
  const image = getImage(objectType, entityType);

  return (
    <Container>
      <img src={image} alt={`${objectType} example`} />
      <Content objectType={objectType} entityType={entityType} />
    </Container>
  );
}

function Content({ objectType, entityType }: { objectType: ObjectType; entityType: EntityType }) {
  const { header, description, bullets } = getContent(objectType, entityType);
  return (
    <Flex column gap={24}>
      <Header>{header}</Header>
      {description && <Flex>{description}</Flex>}
      {bullets?.map(({ icon, text }, index) => (
        <Flex key={index} gap={10}>
          <IconWrapper>
            <UIcon svg={icon} size={20} color={sysTextDefault} />
          </IconWrapper>
          <Text>{text}</Text>
        </Flex>
      ))}
    </Flex>
  );
}

const getImage = (objectType: ObjectType, entityType: EntityType) => {
  if (objectType === object.opportunity) {
    return OpportunityExample;
  }

  switch (entityType) {
    case entity.notice:
      return NoticeListExample;
    case entity.buyer:
      return BuyerListExample;
    case entity.supplier:
      return SupplierListExample;
    default:
      throw new Error(`Unsupported entity type: ${entityType}`);
  }
};

const getContent = (objectType: ObjectType, entityType: EntityType): ContentType => {
  if (objectType === object.opportunity) {
    return {
      header: "Pipeline opportunities tailored to you",
      bullets: [
        { icon: trendUp01, text: "Review progress public sector opportunities in Stotles" },
        { icon: attachment01, text: "Attach relevant insights to form strategic outreach" },
        { icon: userCircle, text: "Assign to your team members to start actioning" },
        { icon: usersPlus, text: "Find contacts to start engaging with" },
      ],
    };
  }

  switch (entityType) {
    case entity.notice:
      return {
        header: "Organise your notices with lists",
        description: (
          <Text>
            Lists are a way to keep track of notices you want to categorise but aren't potential
            sales opportunities.
            <BoldText>
              {" "}
              We still normally suggest using 'Opportunity' to track any notices of interest.
            </BoldText>
          </Text>
        ),
      };
    case entity.buyer:
      return {
        header: "Find insights faster with buyer lists",
        bullets: [
          {
            icon: filterFunnel02,
            text: "Filter by lists to instantly view notices awarded or mentioning your competitors or partners",
          },
          {
            icon: fileSearch02,
            text: "Quickly identify opportunities and insights that involve buyers in your lists",
          },
        ],
      };
    case entity.supplier:
      return {
        header: "Find insights faster with supplier lists",
        bullets: [
          {
            icon: filterFunnel02,
            text: "Filter by lists to instantly view notices awarded or mentioning your competitors or partners",
          },
          {
            icon: barChart02,
            text: "Contribute to your signal score, spotlighting notices that matter most.",
          },
          {
            icon: fileSearch02,
            text: "Quickly identify opportunities and insights that involve suppliers in your lists",
          },
        ],
      };
    default:
      throw new Error(`Unsupported entity type: ${entityType}`);
  }
};

export const Container = styled(Flex)(({ theme }) => ({
  flexDirection: "column",
  justifyContent: "space-around",
  flexGrow: 1,
  borderRadius: "8px",
  background: `linear-gradient(180deg, ${theme.colors.sysPrimarySubtle} -2.36%, ${theme.colors.sysTextWhite} 103.51%)`,
  padding: "56px 32px",
  width: "392px",
}));

const Header = styled.p({ fontSize: 28, fontWeight: 600, marginBottom: 0 });

const Text = styled.p({ fontSize: 16, fontWeight: 500, marginBottom: 0 });

const BoldText = styled.span({ fontWeight: 700 });

const IconWrapper = styled(Flex)({ alignItems: "center", flexShrink: 0 });
