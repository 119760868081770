import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  NoticesOverallQuery,
  NoticesSearchRequest,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const noticesOverallQuery = graphql(`
  query noticesOverall($request: NoticesSearchRequest!) {
    notices(NoticesSearchRequest: $request) {
      __typename
      totalResults
      values {
        combined {
          gbp
        }
      }
    }
  }
`);

export function useNoticesOverall(
  searchNoticesRequest: NoticesSearchRequest,
  options?: UseQueryOptions<NoticesOverallQuery, unknown, NoticesOverallQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["noticesOverall", searchNoticesRequest],
    noticesOverallQuery,
    [{ request: searchNoticesRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: data?.notices,
    ...rest,
  };
}
