import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import { Button, Input, Popover } from "antd5";

import PaywallPopover from "components/paywall/PaywallPopover";
import { SaveSupplierMenu } from "components/suppliers/SaveSupplierPopover";
import { useDebouncedValue } from "lib/debounce";
import { useSearchOrganisations } from "lib/hooks/api/organisations/useSearchOrganisations";
import Signal from "lib/icons/Signal";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { EventNames, logEvent } from "lib/tracking";
import { OrgSortBy, OrgSortOrder, SearchOrgPrimaryRole } from "lib/types/graphQLEnums";
import { ALL_COMPETITORS_TOKEN, ALL_PARTNERS_TOKEN } from "lib/types/models";
import { deduplicateByKey, isDefined, simpleArrayDedupe } from "lib/utils";
import { useSignalSettingsGQL } from "../../lib/hooks/api/teams/useSignalSettingsGQL";
import SelectedSupplierTags from "./dropdown_list_selects/new_supplier_selects/SelectedSupplierTags";
import SuppliersDropdown from "./dropdown_list_selects/new_supplier_selects/SuppliersDropdown";
import { SupplierOption } from "./dropdown_list_selects/new_supplier_selects/types";
import { SelectProps } from "./Inputs";

import css from "./NewSupplierSelect.module.scss";

type SimpleOrg = {
  id: string;
  name: string;
};

function convertOrgToSupplierOption(orgs?: SimpleOrg[] | null): SupplierOption[] {
  if (!orgs) {
    return [];
  }

  return orgs.map((o) => ({
    label: o.name,
    value: o.id,
  }));
}

/**
 * If all competitor guids or all partner guids are selected - add the
 * ALL_PARTNERS and/or ALL_COMPETITORS tokens. Reason being; if a user saves a
 * supplier as a competitor or a partner after creating a saved view - we want
 * to be able to have a token which would include this new guid in any
 * pre-existing views with ALL competitors or ALL partners
 */
function checkIfAllSignalsSelected(
  selectedSupplierIds: string[],
  competitors: SupplierOption[],
  partners: SupplierOption[],
): string[] {
  if (selectedSupplierIds.length === 0) {
    return [];
  }

  const allCompetitorsGuids = competitors.map((s) => s.value);
  const allPartnersGuids = partners.map((s) => s.value);

  const containsAllCompetitors =
    allCompetitorsGuids.length > 0 &&
    allCompetitorsGuids.every((s) => selectedSupplierIds.includes(s));

  const containsAllPartners =
    allPartnersGuids.length > 0 && allPartnersGuids.every((s) => selectedSupplierIds.includes(s));

  if (containsAllCompetitors) {
    selectedSupplierIds.push(ALL_COMPETITORS_TOKEN);
  }

  if (containsAllPartners) {
    selectedSupplierIds.push(ALL_PARTNERS_TOKEN);
  }

  return selectedSupplierIds;
}

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

export function NewSupplierSelect<T extends FieldValues>(props: FieldProps<T>) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);

  const [isOpen, setIsOpen] = useState(false);

  const { field } = useController(props);

  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Supplier filter",
  });

  const [selectedSuppliers, setSelectedSuppliers] = useState<SupplierOption[]>([]);

  const { data: signals, isLoading: isLoadingSignals } = useSignalSettingsGQL({
    includeAliases: true,
    includeNotices: true,
  });

  const competitors = useMemo(
    (): SupplierOption[] => convertOrgToSupplierOption(signals?.competitors),
    [signals?.competitors],
  );

  const partners = useMemo(
    (): SupplierOption[] => convertOrgToSupplierOption(signals?.partners),
    [signals?.partners],
  );

  /**
   * UseMemo of selectedSupplierIds is primarily used to handle the case where
   * the user selects the ALL_COMPETITORS or ALL_PARTNERS token
   * or it is already present in the filters (from a saved view for example)
   */
  const selectedSupplierIds = useMemo((): string[] => {
    if (!field.value) {
      return [];
    }

    let filterValue: string[] = field.value.filter(isDefined);

    // If ALL_COMPETITORS token exists in filters, then replace it with all competitor IDs
    if (filterValue.includes(ALL_COMPETITORS_TOKEN)) {
      filterValue = filterValue.concat(competitors.map((s) => s.value));
    }

    // If ALL_PARTNERS token exists in filters, then replace it with all partner IDs
    if (filterValue.includes(ALL_PARTNERS_TOKEN)) {
      filterValue = filterValue.concat(partners.map((s) => s.value));
    }

    // Ensure that all partners and all competitors tokens are removed from the filter value
    return simpleArrayDedupe(filterValue).filter(
      (val) => ![ALL_COMPETITORS_TOKEN, ALL_PARTNERS_TOKEN].includes(val),
    );
  }, [competitors, field.value, partners]);

  // On initial load, fetch the labels for the selected suppliers
  // This doesn't include textSearch
  const { data: initialOrganisations } = useSearchOrganisations(
    {
      textSearch: "",
      sortBy: OrgSortBy.Relevance,
      sortOrder: OrgSortOrder.Desc,
      primaryRole: SearchOrgPrimaryRole.Supplier,
      ids: selectedSupplierIds,
      limit: Math.max(selectedSupplierIds.length, 1),
      page: 1,
    },
    false,
    false,
    {
      enabled: selectedSupplierIds.length > 0 && selectedSuppliers.length === 0,
    },
  );

  // Loads initially selected suppliers
  useEffect(() => {
    if (initialOrganisations) {
      const initialOrgs = initialOrganisations.searchOrganisations.orgs;
      setSelectedSuppliers(initialOrgs.map((o) => ({ label: o.name, value: o.id })));
    }
  }, [initialOrganisations]);

  const handleSuppliersSelection = useCallback(
    (newSuppliers: SupplierOption[]) => {
      const newSuppliersDeuped = Array.from(deduplicateByKey(newSuppliers, (s) => s.value));

      setSelectedSuppliers(newSuppliersDeuped);

      const newSupplierIds = newSuppliersDeuped.map((s) => s.value);

      // Puts in all_competitors and all_partners tokens if all competitors or
      // all partners are selected
      const newSelectionValues = checkIfAllSignalsSelected(newSupplierIds, competitors, partners);
      field.onChange(newSelectionValues);
    },
    [competitors, field, partners],
  );

  const showTags = selectedSupplierIds.length > 0 && !isOpen;

  return (
    <PaywallPopover featureType="SUPPLIERS">
      <div
        aria-label="supplierSelectContainer"
        onBlur={(e) => {
          if (e.currentTarget.contains(e.relatedTarget as Node)) {
            return;
          }
          setIsOpen(false);
        }}
      >
        <div tabIndex={0} className={css.container}>
          <Input
            aria-label="supplierSearchInput"
            className={hasSuppliers ? css.inputField : css.disabledInputField}
            onClick={() => {
              if (hasSuppliers) {
                setIsOpen(true);
              }
            }}
            onChange={(e) => {
              const newValue = e.currentTarget.value.trim();

              // if clearing the search text, close the dropdown
              if (newValue.length === 0 && searchText.length > 0) {
                setIsOpen(false);
              }

              // if we're typing, open the dropdown
              if (newValue.length > 0 && !isOpen) {
                setIsOpen(true);
              }

              setSearchText(e.currentTarget.value.trim());
            }}
            placeholder={isOpen ? undefined : "Search competitors, partners and other suppliers"}
          />
          <SuppliersDropdown
            handleSuppliersSelection={handleSuppliersSelection}
            textSearch={debouncedText}
            isOpen={isOpen}
            selectedSuppliers={selectedSuppliers}
            competitors={signals?.competitors ?? []}
            partners={signals?.partners ?? []}
            isLoading={isLoadingSignals}
          />
          {showTags && (
            <>
              <SelectedSupplierTags
                selectedSuppliers={selectedSuppliers}
                handleSuppliersSelection={handleSuppliersSelection}
              />
              <SaveSupplierSignals
                supplierGuids={selectedSupplierIds}
                partnerGuids={partners.map((p) => p.value)}
                competitorGuids={competitors.map((c) => c.value)}
                isLoadingSignals={isLoadingSignals}
              />
            </>
          )}
        </div>
      </div>
    </PaywallPopover>
  );
}

type Props = {
  supplierGuids: string[];
  partnerGuids: string[];
  competitorGuids: string[];
  isLoadingSignals: boolean;
};

function SaveSupplierSignals({
  supplierGuids,
  partnerGuids,
  competitorGuids,
  isLoadingSignals,
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false);

  // Using this to make the popover width the same as the button
  const [width, setWidth] = useState(0);
  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.offsetWidth);
    }
  }, []);

  return (
    <Popover
      // Click to open popover, then when open it closes automatically after 0.3 secs
      trigger={isOpen ? ["hover"] : ["click"]}
      open={isOpen}
      mouseLeaveDelay={0.3}
      placement="bottom"
      arrow={false}
      onOpenChange={(open) => setIsOpen(open)}
      overlayInnerStyle={{ padding: 0, width }}
      content={
        <SaveSupplierMenu
          supplierGuids={supplierGuids}
          partnerGuids={partnerGuids}
          competitorGuids={competitorGuids}
          contextSource="Supplier filter"
          onSaveSupplierOp={() => {
            logEvent(EventNames.recordQueryUpdated, {
              "Context source": "Supplier filter",
              "Action type": "Added",
              "Org ids": supplierGuids,
              "Signal type": "Supplier",
            });
          }}
        />
      }
    >
      <div ref={targetRef} style={{ width: "100%" }}>
        <Button
          className={css.saveSignalBtn}
          loading={isLoadingSignals}
          // Close the popover when the button is clicked
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            }
          }}
        >
          <Signal className={css.signalIcon} size={16} label="signalIcon" />
          Save as signals
          {isOpen ? <CaretUpFilled /> : <CaretDownFilled />}
        </Button>
      </div>
    </Popover>
  );
}
