import React from "react";
import validateCurrencyCode from "validate-currency-code";

/**
 * Smart currency formatter, which changes the format based on the amount.
 * @param value Null = empty amount (e.g. £ --)
 * @param currency An ISO 3166 currency code (e.g. GBP)
 * @param locale If numbers should be formatted using a specific locale
 * @returns A formatted currency string
 */
export function currencyFormat(
  value: number | null, // Not including undefined since components should handle the loading state themselves
  currency: string,
  locale: Intl.LocalesArgument = "en-GB", // Should be changed to undefined eventually
): string {
  try {
    if (currency && !validateCurrencyCode(currency)) {
      console.warn(`Invalid currency code found when formatting: ${currency}`);
      currency = "GBP";
    }

    const formatter = new Intl.NumberFormat(locale as string, {
      // Remove "as string" when upgrading to ES2023 / TypeScript 5.5+
      ...(value && value >= 10000 ? highCurrencyFormatter : lowCurrencyFormatterOpts),
      currency,
    });

    // If no value, show £ --
    if (value === null) {
      return emptyValue(formatter.format(0));
    }
    return formatter.format(value);
  } catch (e) {
    console.error("Failed to format currency", e);
    return emptyValue(value?.toString() ?? "");
  }
}

/**
 * Currency formatter, which always shows the integer part of the number and depending on the amount the decimal part as well.
 * @param value Null = empty amount (e.g. £ --)
 * @param currency An ISO 3166 currency code (e.g. GBP)
 * @param locale If numbers should be formatted using a specific locale
 * @returns A formatted currency string
 */
export function currencyFormatLong(
  value: number | null, // Not including undefined since components should handle the loading state themselves
  currency: string,
  locale: Intl.LocalesArgument = "en-GB", // Should be changed to undefined eventually
): string {
  try {
    if (currency && !validateCurrencyCode(currency)) {
      console.warn(`Invalid currency code found when formatting: ${currency}`);
      currency = "GBP";
    }

    const formatter = new Intl.NumberFormat(locale as string, {
      // Remove "as string" when upgrading to ES2023 / TypeScript 5.5+
      ...lowCurrencyFormatterOpts,
      ...(value && value >= 10000 ? { minimumFractionDigits: 0, maximumFractionDigits: 0 } : {}),
      currency,
    });

    // If no value, show £ --
    if (value === null) {
      return emptyValue(formatter.format(0));
    }
    return formatter.format(value);
  } catch (e) {
    console.error("Failed to format currency", e);
    return emptyValue(value?.toString() ?? "");
  }
}

/**
 * Shows most of the number, with some minor rounding
 * @param value Number to format
 * @param locale Leave undefined to use the browser's locale
 * @returns String formatted number
 */
export function numberFormatLong(value: number | null, locale?: Intl.LocalesArgument): string {
  try {
    const formatter = new Intl.NumberFormat(locale as string, {
      // Remove "as string" when upgrading to ES2023 / TypeScript 5.5+
      style: "decimal",
      notation: "standard",
      maximumFractionDigits: 3, // Default value
    });
    if (value === null) {
      return emptyValue(formatter.format(0));
    }
    return formatter.format(value);
  } catch (e) {
    console.error("Failed to format number", e);
    return emptyValue(value?.toString() ?? "");
  }
}

/**
 *
 * @param value Number to format
 * @param locale Leave undefined to use the browser's locale
 * @returns String formatted number
 */
export function numberFormatShort(value: number | null, locale?: Intl.LocalesArgument): string {
  try {
    const formatter = new Intl.NumberFormat(locale as string, {
      // Remove "as string" when upgrading to ES2023 / TypeScript 5.5+
      style: "decimal",
      notation: "compact",
      maximumFractionDigits: value && value > 1000 ? 1 : 0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - trailingZeroDisplay is not in the types. Remove when upgrade to ES2023 / TypeScript 5.5+
      trailingZeroDisplay: "stripIfInteger",
    });
    if (value === null) {
      return emptyValue(formatter.format(0));
    }
    return formatter.format(value);
  } catch (e) {
    console.error("Failed to format number", e);
    return emptyValue(value?.toString() ?? "");
  }
}

// Turn any form of 0 into an -- string
const emptyValue = (input: string) =>
  // These aren't normal spaces below! They are non-breaking spaces
  // since Intl.NumberFormat will output them.
  input
    .replace("0.00", " -- ")
    .replace("0,00", " -- ")
    .replaceAll("0", " -- ")
    .replaceAll(" ", " ") // The one normal space on the left (since we convert to non-breaking)
    .replaceAll("  ", " ")
    .trim();

// For 9999.99 and below
const lowCurrencyFormatterOpts = {
  style: "currency",
  notation: "standard",
  minimumFractionDigits: 2,
} as Intl.NumberFormatOptions;

// For 10000.00 and above
const highCurrencyFormatter = {
  style: "currency",
  notation: "compact",
  maximumFractionDigits: 2,
  minimumSignificantDigits: 4,
  maximumSignificantDigits: 4,
} as Intl.NumberFormatOptions;

/**
 * @deprecated Prefer using format functions directly
 */
export function formatNumber({
  value,
  locale = "en-GB",
  shortFormat,
  currency,
}: {
  value: number | null | undefined;
  locale: string;
  currency?: string;
  shortFormat?: boolean;
}) {
  if (currency) {
    return currencyFormat(value ?? null, currency, locale);
  }
  return shortFormat ? numberFormatShort(value ?? 0, locale) : numberFormatLong(value ?? 0, locale);
}

export const Currency = ({
  value,
  currency = "GBP",
  locale = "en-GB",
  className,
  longFormat,
}: {
  value?: number | null;
  currency?: string;
  locale?: string;
  className?: string;
  longFormat?: boolean;
}) => {
  return (
    <span className={className}>
      {longFormat
        ? currencyFormatLong(value !== undefined ? value : 0, currency, locale)
        : currencyFormat(value !== undefined ? value : 0, currency, locale)}
    </span>
  );
};

export const Number = ({
  value,
  locale = "en-GB",
  shortFormat = false,
  className,
}: {
  value?: number | null;
  locale?: string;
  shortFormat?: boolean;
  className?: string;
}) => {
  return (
    <span className={className}>
      {shortFormat
        ? numberFormatShort(value !== undefined ? value : 0, locale)
        : numberFormatLong(value ?? 0, locale)}
    </span>
  );
};
