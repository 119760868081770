import { CalendarEvent, google, ics, office365 } from "calendar-link";
import { DateTime } from "luxon";

type ReminderInterval = {
  value: number;
  unit: "days" | "weeks" | "months" | "years";
};

type CalendarType = "google" | "office365" | "ics";

export function calculateReminderDate({
  anchorDate,
  startHour,
  reminderInterval,
}: {
  anchorDate: Date;
  reminderInterval: ReminderInterval;
  startHour: number;
}): string {
  const anchor = DateTime.fromJSDate(anchorDate).startOf("day").set({ hour: startHour });
  const reminderDate = anchor.minus({ [reminderInterval.unit]: reminderInterval.value }).toISO();
  if (!reminderDate) {
    throw new Error("Reminder date calculation failed");
  }
  return reminderDate;
}

export function isDateInFuture({
  anchorDate,
  startHour,
  reminderInterval,
}: {
  anchorDate: Date;
  reminderInterval: ReminderInterval;
  startHour: number;
}): boolean {
  const givenDate = DateTime.fromJSDate(anchorDate)
    .startOf("day")
    .set({ hour: startHour })
    .minus({ [reminderInterval.unit]: Number(reminderInterval.value) });
  return givenDate > DateTime.now();
}

export function setHoursOnDate(date: Date, hour: number): string {
  const dateString = DateTime.fromJSDate(date).set({ hour }).toISO();
  if (!dateString) {
    throw new Error("Date string creation failed");
  }
  return dateString;
}

export function createCalenderLink(event: CalendarEvent, type: CalendarType) {
  switch (type) {
    case "google":
      return google(event);
    case "office365":
      return office365(event);
    case "ics":
      return ics(event);
    default:
      throw new Error("Invalid calendar type");
  }
}

export function generateEventDescription(
  dataType: "notice" | "framework" | "opportunity",
  dataId: string,
  dataTitle: string,
  calendarType: CalendarType,
) {
  const type =
    dataType === "notice" ? "records" : dataType === "opportunity" ? "opportunities" : "frameworks";
  const dataLink = `${window.location.origin}/${type}/${dataId}`;
  let dataTag = `<a href="${dataLink}">${dataTitle}</a>`;
  const contactsLink = `${window.location.origin}/${type}/${dataId}/related_contacts`;
  let contactsTag =
    dataType === "notice" ? `<a href="${contactsLink}">Find contacts here</a>` : undefined;

  if (calendarType === "ics") {
    dataTag = `${dataTitle} - ${dataLink}`;
    contactsTag = contactsTag ? `Find contacts here - ${contactsLink}` : undefined;
  }
  return `Time to engage with ${dataTag} in Stotles.

${contactsTag ? contactsTag : ""}
  `;
}
