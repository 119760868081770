import { QueryKey, UseMutationOptions, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  BidDocumentsQuery,
  BidDocumentsRequest,
  BidDocumentsResponse,
  GetBidDocumentUrlMutation,
  GetBidDocumentUrlMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation, useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const BID_DOCUMENTS_QUERY = graphql(`
  query bidDocuments($request: BidDocumentsRequest!) {
    bidDocuments(BidDocumentsRequest: $request) {
      items {
        id
        fileName
      }
    }
  }
`);

const getBidDocumentUrl = graphql(`
  mutation GetBidDocumentUrl($id: String!) {
    getBidDocumentUrl(id: $id) {
      documentUrl
    }
  }
`);

export type BidDocumentsDto = BidDocumentsResponse["items"][0];

export function useBidDocuments(
  bidDocumentsRequest: BidDocumentsRequest,
  options?: UseQueryOptions<BidDocumentsQuery, unknown, BidDocumentsQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["bidDocuments", bidDocumentsRequest],
    BID_DOCUMENTS_QUERY,
    [{ request: bidDocumentsRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.bidDocuments, ...rest };
}

export function useGetBidDocumentUrl(
  options?: UseMutationOptions<
    GetBidDocumentUrlMutation,
    unknown,
    GetBidDocumentUrlMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(getBidDocumentUrl, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
  });

  return { data: data?.getBidDocumentUrl, ...rest };
}
