import React, { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ClassNames, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { App } from "antd5";

import { DatePicker, Input } from "components/form_components/Inputs";
import { createUseDebounce } from "lib/debounce";
import CentredSpinner from "../../../lib/core_components/CentredSpinner";
import { OpportunityUpdateInput } from "../../../lib/generated/app-service-gql/graphql";
import { useDeleteOpportunity } from "../../../lib/hooks/api/opportunities/useDeleteOpportunity";
import { OpportunityDetails } from "../../../lib/hooks/api/opportunities/useOpportunity";
import { useUpdateOpportunity } from "../../../lib/hooks/api/opportunities/useUpdateOpportunity";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { Flex, Text } from "../../../styles/utility-components";
import { Currency, FormattedDate } from "../../app_layout/Typography";
import { getFormDirtyValues } from "../../form_components/formUtils";
import { ScheduleReminderButton } from "../../reminders/ScheduleReminderButton";
import OpportunityDiscardModal from "../delete_opportunity_modal/OpportunityDiscardModal";
import OpportunityAssignedSelect from "../opportunity_assigned_select/OpportunityAssignedSelect";
import { OpportunityStageSelect } from "../OpportunityStageSelect";
import { filterOpportunityDefaultValues } from "../utils";

type OpportunityDetailSidebarProps = {
  opportunity: OpportunityDetails;
};

export default function OpportunityDetailSidebar({ opportunity }: OpportunityDetailSidebarProps) {
  const { message } = App.useApp();
  const theme = useTheme();
  const useDebounce = createUseDebounce(500);

  const [editingField, setEditingField] = useState<"value" | "closeDate" | undefined>();
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields },
  } = useForm<OpportunityUpdateInput>({
    defaultValues: opportunity,
  });

  const previousFormValues = useRef<Partial<OpportunityUpdateInput>>(opportunity);

  useEffect(() => {
    if (opportunity) {
      const filteredDefaultValues = filterOpportunityDefaultValues(opportunity);
      reset(filteredDefaultValues);
      previousFormValues.current = filteredDefaultValues;
    }
  }, [opportunity, reset]);

  const { mutate: updateOpportunity, isLoading: updateOpportunityLoading } = useUpdateOpportunity(
    {
      onSuccess: () => {
        message.success("Opportunity updated successfully");
      },
      onError: (error) => {
        console.warn("Failed to update opportunity", error);
        message.error("Failed to update opportunity");
      },
    },
    {
      startStageId: previousFormValues.current?.stageId ?? "",
      buyerNames: opportunity.buyers.map((b) => b.name),
    },
  );

  const { mutate: deleteOpportunity } = useDeleteOpportunity({
    onSuccess: () => {
      message.success("Opportunity deleted successfully");
      window.location.href = "/opportunities";
    },
  });

  const onSubmit: SubmitHandler<OpportunityUpdateInput> = (values: OpportunityUpdateInput) => {
    const updatedValues = getFormDirtyValues(dirtyFields, values);
    const filteredPayload = {
      ...updatedValues,
      id: values.id,
      value: updatedValues.value && Number(updatedValues.value),
    };
    updateOpportunity({ opportunity: filteredPayload });
  };

  const debouncedSubmit = useDebounce((values: OpportunityUpdateInput) => {
    onSubmit(values);
  });

  // Force form to update on change
  useEffect(() => {
    const subscription = watch(() => handleSubmit(debouncedSubmit)());
    return () => subscription.unsubscribe();
  });

  const handleDeleteOpportunity = () => {
    setIsDiscardModalOpen(true);
  };

  if (updateOpportunityLoading) {
    return (
      <Sidebar>
        <CentredSpinner />
      </Sidebar>
    );
  }

  const handleFeedback = () => {
    const url = new URL(window.location.href);
    url.searchParams.set("userflow", "9f97793b-ef2e-4e7b-a5e6-1bc3a0a7d5a0");
    window.open(url.toString(), "_blank");
  };

  const handleDiscardModalCancel = () => {
    setIsDiscardModalOpen(false);
  };

  const handleDiscardModalSubmit = () => {
    deleteOpportunity({ id: opportunity.id });
    setIsDiscardModalOpen(false);
  };

  return (
    <ClassNames>
      {({ css }) => (
        <Sidebar>
          <SidebarContentItem>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex gap={16}>
                <Flex flexDirection="column" gap={16}>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Stage</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Assigned</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Value</SidebarContentItemKey>
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <SidebarContentItemKey>Close</SidebarContentItemKey>
                  </Flex>
                </Flex>
                {/* TODO: improve styling */}
                <Flex flexDirection="column" gap={16}>
                  <Flex alignItems="center" height={32}>
                    <OpportunityStageSelect
                      name="stageId"
                      control={control}
                      label=""
                      variant="borderless"
                      className={css({
                        minWidth: 250,
                      })}
                    />
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    <OpportunityAssignedSelect
                      name="assignedToId"
                      label=""
                      control={control}
                      variant="borderless"
                      className={css({
                        minWidth: 250,
                      })}
                    />
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    {editingField === "value" ? (
                      <Input
                        name={"value"}
                        label={""}
                        control={control}
                        type="number"
                        placeholder="Value of the opportunity"
                      />
                    ) : (
                      <SidebarContentItemValue onClick={() => setEditingField("value")}>
                        <Currency value={opportunity.value} />
                      </SidebarContentItemValue>
                    )}
                  </Flex>
                  <Flex alignItems="center" height={32}>
                    {editingField === "closeDate" ? (
                      <DatePicker name={"closeDate"} label={""} control={control} />
                    ) : (
                      <SidebarContentItemValue onClick={() => setEditingField("closeDate")}>
                        <FormattedDate date={opportunity.closeDate} />
                      </SidebarContentItemValue>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            </form>
          </SidebarContentItem>
          <Divider />
          <SidebarContentItem>
            <Text body color={theme.colors.sysTextSecondary}>
              Actions
            </Text>
            <ScheduleReminderButton
              dataType="opportunity"
              anchorDate={opportunity.closeDate}
              dataId={opportunity.id}
              dataTitle={opportunity.name ?? ""}
              anchorType="close"
              buttonRender={
                <StyledButton>
                  <UIcon icon="clockRewind" size={16} color={theme.colors.sysBrandBlueDefault} />
                  Set a reminder
                </StyledButton>
              }
            />
            <StyledButton onClick={handleDeleteOpportunity}>
              <UIcon icon="trash01" size={16} color={theme.colors.sysBrandRedDefault} />
              Delete opportunity
            </StyledButton>
            <StyledButton onClick={handleFeedback}>
              <UIcon icon="linkExternal02" size={16} color={theme.colors.sysBrandBlueDefault} />
              We&apos;d love your feedback
            </StyledButton>
          </SidebarContentItem>
          <OpportunityDiscardModal
            isOpen={isDiscardModalOpen}
            onCancel={handleDiscardModalCancel}
            onSubmit={handleDiscardModalSubmit}
          />
        </Sidebar>
      )}
    </ClassNames>
  );
}

const Sidebar = styled(Flex)({
  flexDirection: "column",
  height: "100%",
});

const SidebarContentItem = styled(Flex)({
  flexDirection: "column",
  gap: 16,

  padding: "20px 24px",
});

const SidebarContentItemKey = styled(Text)(({ theme }) => ({
  width: 80,

  color: theme.colors.sysTextSecondary,
}));

const Divider = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const SidebarContentItemValue = styled.div(({ theme }) => ({
  cursor: "pointer",
  padding: "8px 12px",

  "&:hover": {
    borderRadius: "4px",
    backgroundColor: theme.colors.sysPrimarySubtle,
  },

  transition: "all 0.2s ease-in-out",
}));

const StyledButton = styled.button(({ theme }) => ({
  all: "unset",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 8,

  height: 16,
  flexGrow: 1,

  padding: 8,

  border: `1px solid ${theme.colors.sysBorderPrimary}`,
  borderRadius: 4,

  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.colors.sysBackgroundAlternative,
  },

  transition: "background-color 0.2s ease-in-out",
}));
