import React, { useState } from "react";
import { Button } from "antd5";
import { Flex } from "styles/utility-components";

import { useVariableValue } from "../../lib/featureFlags";
import { clockRewind } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { EventNames, useTracking } from "../../lib/tracking";
import { ScheduleReminderModal } from "./ScheduleReminderModal";

type Props = {
  dataType: "notice" | "framework" | "opportunity";
  anchorDate?: string | null;
  anchorType?: "expiry" | "close";
  dataId: string;
  dataTitle: string;
  buttonRender?: React.ReactNode;
};

export function ScheduleReminderButton(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logEvent } = useTracking();
  const isEnabled = useVariableValue("calendar-reminders", false);

  if (!isEnabled) {
    return null;
  }

  const handleOpen = () => {
    setIsModalOpen(true);
    logEvent(EventNames.scheduleReminderModalOpened, {
      dataType: props.dataType,
      dataId: props.dataId,
      dataTitle: props.dataTitle,
      anchorDate: props.anchorDate,
      anchorType: props.anchorType,
    });
  };

  return (
    <>
      <Flex onClick={handleOpen}>
        {props.buttonRender ? (
          props.buttonRender
        ) : (
          <Button icon={<UIcon svg={clockRewind} size={16} />} onClick={handleOpen}>
            Reminder
          </Button>
        )}
      </Flex>
      <ScheduleReminderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        {...props}
      />
    </>
  );
}
