import React from "react";
import styled from "@emotion/styled";
import { Flex, Text } from "styles/utility-components";

import { countryCodeToCountry } from "lib/data/optionItems";
import { OpportunityDetails } from "lib/hooks/api/opportunities/useOpportunity";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { sysTextSecondary } from "../../../lib/themes/colors";
import { opportunitySavedCardContainer, opportunitySavedCardIcon } from "./shared/styles";
import OpportunitySavedEmptyState from "./OpportunitySavedEmptyState";

type Props = { suppliers: OpportunityDetails["suppliers"] };

export function OpportunitySuppliers({ suppliers }: Props) {
  const handleAddSupplier = () => {
    window.location.href = "/suppliers/search";
  };

  const handleViewSupplier = (id: string) => () => {
    window.location.href = `/suppliers/${id}`;
  };

  return (
    <Flex column gap={16}>
      {suppliers.map((supplier) => (
        <SupplierCard
          key={supplier.id}
          supplier={supplier}
          onView={handleViewSupplier(supplier.id)}
        />
      ))}
      <OpportunitySavedEmptyState entity="supplier" onClick={handleAddSupplier} />
    </Flex>
  );
}

function SupplierCard({
  supplier,
  onView,
}: {
  supplier: OpportunityDetails["suppliers"][0];
  onView: () => void;
}) {
  const country = countryCodeToCountry(supplier.countryCode ?? "");

  return (
    <Container onClick={onView}>
      <Flex gap={12}>
        <Icon>
          <UIcon icon="trophy01" color={sysTextSecondary} />
        </Icon>
        <Flex column gap={2}>
          <Text>{supplier.name || "Untitled Supplier"}</Text>
          {country && (
            <Text description color={sysTextSecondary}>
              {country}
            </Text>
          )}
        </Flex>
      </Flex>
    </Container>
  );
}

const Container = styled.div(({ theme }) => ({
  height: 72,

  ...opportunitySavedCardContainer(theme),
}));

const Icon = styled(Flex)(({ theme }) => ({
  ...opportunitySavedCardIcon(theme),
}));
