import React, { useCallback, useEffect, useRef } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import { upload01 } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { Text } from "../../styles/utility-components";

import css from "./FileInput.module.scss";

function handleDragover(event: DragEvent) {
  event.preventDefault();
  event.stopPropagation();
}

/**
 * Ronseal. Given an array of File objects, deduplicates them by name
 * @param files
 * @returns
 */
function deduplicateFilesByName(files: File[]): File[] {
  const uniqueMap = new Map<string, File>();

  for (const file of files) {
    if (!uniqueMap.has(file.name)) {
      uniqueMap.set(file.name, file);
    }
  }

  return Array.from(uniqueMap.values());
}

/**
 * Handles the scenarios when a fileEntry is a file or a zip file
 * @param entry
 */
async function handleFileEntry(entry: FileSystemEntry): Promise<File[]> {
  const fileEntry = entry as FileSystemFileEntry;
  return new Promise<File[]>((resolve, reject) => {
    fileEntry.file((file) => {
      if (file.name.endsWith(".zip")) {
        unzipFile(file)
          .then((extractedFiles) => resolve(extractedFiles))
          .catch((error) => reject(error));
      } else {
        resolve([file]);
      }
    }, reject); // Pass `reject` as the error callback for fileEntry.file
  });
}

/**
 * Recursively read a directory (and sub-directories) using the
 * non-standard webkit entries API. Collect all File objects,
 * then call onComplete(files) when all recursive processing is done.
 */
async function readDirectoryRecursively(entry: FileSystemEntry): Promise<File[]> {
  // If entry is a *file*, just read it and return immediately:
  if (entry.isFile && "file" in entry) {
    return await handleFileEntry(entry);
  }

  // If entry is a directory, read it.
  if (entry.isDirectory) {
    const dirEntry = entry as FileSystemDirectoryEntry;
    const reader = dirEntry.createReader();
    const allFiles: File[] = [];

    // Because `reader.readEntries()` is async in a callback sense,
    // we'll "promisify" each batch of readEntries below.
    async function readEntriesBatch(): Promise<void> {
      const entries: FileSystemEntry[] = await new Promise((resolve) => {
        reader.readEntries(resolve); // up to 100 entries
      });

      // If no entries, we’re done with this directory.
      if (!entries || entries.length === 0) {
        return;
      }

      // Process each entry in this batch.
      for (const e of entries) {
        if (e.isFile) {
          const files = await handleFileEntry(e);

          allFiles.push(...files);
        } else if (e.isDirectory) {
          const subFiles = await readDirectoryRecursively(e);

          allFiles.push(...subFiles);
        }
      }

      // read the next batch until there are no more
      await readEntriesBatch();
    }

    // Start reading in a loop
    await readEntriesBatch();
    return allFiles;
  }

  // If the entry is null or undefined, just return an empty array
  return [];
}

/**
 * Function to unzip a given File object using `fflate`.
 * It dynamically imports `fflate` to reduce initial bundle size.
 *
 * @param zipFile - The zip file to be unzipped.
 * @returns A promise that resolves to an array of File objects.
 */
async function unzipFile(zipFile: File): Promise<File[]> {
  // Dynamically import fflate
  const { unzip } = await import("fflate");

  const buffer = await zipFile.arrayBuffer();

  return new Promise<File[]>((resolve, reject) => {
    unzip(new Uint8Array(buffer), (err: Error | null, files: Record<string, Uint8Array>) => {
      if (err) return reject(err);

      const extractedFiles: File[] = [];

      for (const [fileName, fileData] of Object.entries(files)) {
        if (!fileName.endsWith("/")) {
          // Create a File object for each extracted file
          const blob = new Blob([fileData]);
          const cleanFileName = fileName.replace(/^.*[\\/]/, ""); // Extract base name
          extractedFiles.push(new File([blob], cleanFileName));
        }
      }
      resolve(extractedFiles);
    });
  });
}

async function handleZipFiles(selectedFiles: File[]): Promise<File[]> {
  // Identify ZIP files from the input
  const zipFiles = selectedFiles.filter((file) => file.name.endsWith(".zip"));

  for (const zipFile of zipFiles) {
    const extractedFiles = await unzipFile(zipFile);
    // Push only extracted files to the list
    selectedFiles.push(...extractedFiles);
  }

  // Return all files except the original ZIP files
  return selectedFiles.filter((file) => !file.name.endsWith(".zip"));
}

type Props = {
  onChange: (files: File[]) => void;
  onUnsupportedFilesError?: (errors: string[]) => void;
  value: File[];
  isError?: boolean;
  errorMessage?: string;
  acceptedFileTypes?: string;
  multiple?: boolean;
  title?: string;
  listFileNamesInline?: boolean;
};

export function FileInput({
  onChange,
  onUnsupportedFilesError,
  value: files,
  isError,
  errorMessage,
  acceptedFileTypes,
  multiple = true,
  title,
  listFileNamesInline = true,
}: Props) {
  const dropContainer = useRef<HTMLDivElement>(null);
  const fileInput = useRef<HTMLInputElement>(null);

  /**
   * Check if a File object matches our accepted file types.
   */
  const isValidFileType = useCallback(
    (file: File) => {
      if (!acceptedFileTypes) return true; // Allow all if no file type restriction
      const acceptedTypes = acceptedFileTypes.split(",").map((type) => type.trim());
      return acceptedTypes.some(
        (type) => file.type === type || file.name.toLowerCase().endsWith(type),
      );
    },
    [acceptedFileTypes],
  );

  /**
   * Given an array of File objects, filter out unsupported ones,
   * then call onChange with the valid ones.
   */
  const handleFiles = useCallback(
    (selectedFiles: File[]) => {
      const validFiles = selectedFiles.filter(isValidFileType);
      const invalidFiles = selectedFiles.filter((file) => !isValidFileType(file));

      if (invalidFiles.length > 0) {
        onUnsupportedFilesError?.(invalidFiles.map((file) => file.name));
      }

      if (validFiles.length > 0) {
        const updatedFiles = multiple ? [...files, ...validFiles] : [validFiles[0]];

        const deduplicatedFiles = deduplicateFilesByName(updatedFiles);
        onChange(deduplicatedFiles);
      }
    },
    [files, isValidFileType, multiple, onChange, onUnsupportedFilesError],
  );

  /**
   * Handle the drop event for both files and directories.
   * If it's a directory, read its contents; if it's a file, handle it directly.
   */
  useEffect(() => {
    async function handleDrop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();

      const items = event.dataTransfer?.items;

      if (!items) return;

      const allFiles: File[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item.kind === "file") {
          const entry = item.webkitGetAsEntry && item.webkitGetAsEntry();
          if (entry?.isDirectory) {
            // TODO: Should we throw an error here, if multiple prop is not true?
            const dirFiles = await readDirectoryRecursively(entry);
            allFiles.push(...dirFiles);
          } else {
            // It's a regular file => handle directly
            const file = item.getAsFile();
            if (file) {
              if (file.name.endsWith(".zip")) {
                const extractedFiles = await unzipFile(file);
                allFiles.push(...extractedFiles);
              } else {
                allFiles.push(file);
              }
            }
          }
        }
      }

      handleFiles(allFiles);
    }

    const container = dropContainer.current;
    if (container) {
      container.addEventListener("dragover", handleDragover);
      container.addEventListener("drop", handleDrop);
    }

    return () => {
      container?.removeEventListener("dragover", handleDragover);
      container?.removeEventListener("drop", handleDrop);
    };
  }, [handleFiles]);

  return (
    <div
      ref={dropContainer}
      onClick={() => fileInput.current && fileInput.current.click()}
      className={isError ? css.containerError : css.container}
    >
      {/* Hidden input for files */}
      <input
        type="file"
        multiple={multiple}
        onChange={async (e) => {
          if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            const updatedFiles = await handleZipFiles(selectedFiles);
            handleFiles(updatedFiles);
          }
        }}
        accept={acceptedFileTypes}
        style={{ display: "none" }}
        ref={fileInput}
      />

      <UIcon svg={upload01} className={css.tooltipIcon} size={24} />

      {errorMessage && isError && <p className={css.errorText}>{errorMessage}</p>}

      {title ? (
        <Text h3 className={css.instructionText}>
          {title}
        </Text>
      ) : (
        <p className={css.instructionText}>
          Click or drag file(s)/folder(s) to this area to upload
        </p>
      )}

      <p className={css.instructionText}>You can upload multiple files at once</p>
      {acceptedFileTypes && (
        <p className={css.instructionText}>
          Accepted file types: {acceptedFileTypes.replace(/,/g, ", ")}
        </p>
      )}

      {listFileNamesInline &&
        files.map((file, index) => (
          <div key={file.name + index} className={css.file}>
            {file.name}{" "}
            <Tooltip title="Delete">
              <Button
                type="text"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={(evt) => {
                  evt.stopPropagation();
                  const updated = [...files];
                  updated.splice(index, 1);
                  onChange(updated);
                }}
              />
            </Tooltip>
          </div>
        ))}
    </div>
  );
}

export default FileInput;
