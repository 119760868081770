import {
  convertDocumentFiltersToSearchRequest,
  DEFAULT_DOCUMENT_FILTERS,
} from "components/documents/utils";
import {
  convertFrameworkFiltersToRequest,
  DEFAULT_FILTERS as DEFAULT_FRAMEWORK_FILTERS,
} from "components/framework/utils";
import { DEFAULT_NOTICE_FILTERS, TypedFilters } from "components/homepage/util";
import { convertViewToNoticeFilters } from "components/my_feed/useMyFeedPageState";
import { convertNoticeFiltersToSearchRequest } from "components/notices/utils";
import { useVariableValue } from "lib/featureFlags";
import { useDocumentsCount } from "./useDocumentsCount";
import { useFrameworksCount } from "./useFrameworksCount";
import { useNoticesOverall } from "./useNoticesOverall";

/**
 * Fetches the correct data for that opportunities box and returns it
 * Nothing to do with the opportunities object/page
 */
export function useOpportunitiesBox({ type, filters }: TypedFilters) {
  const isLayerCakeEnabled = useVariableValue("layer-cake-filters", false);

  const { data: noticesData } = useNoticesOverall(
    convertNoticeFiltersToSearchRequest(
      convertViewToNoticeFilters(
        // Fallback should never be used, but it's here to avoid TS errors
        type === "NOTICE" ? filters : DEFAULT_NOTICE_FILTERS,
        isLayerCakeEnabled,
      ),
    ),
    { enabled: type === "NOTICE" && filters.id !== DEFAULT_NOTICE_FILTERS.id },
  );
  const { data: frameworksData } = useFrameworksCount(
    convertFrameworkFiltersToRequest(
      type === "FRAMEWORK" ? filters : DEFAULT_FRAMEWORK_FILTERS, // Fallback should never be used, but it's here to avoid TS errors
    ),
    { enabled: type === "FRAMEWORK" },
  );
  const { data: documentsData } = useDocumentsCount(
    convertDocumentFiltersToSearchRequest(
      type === "DOCUMENT" ? filters : DEFAULT_DOCUMENT_FILTERS, // Fallback should never be used, but it's here to avoid TS errors
    ),
    { enabled: type === "DOCUMENT" },
  );

  switch (type) {
    case "NOTICE":
      return noticesData;
    case "FRAMEWORK":
      return frameworksData;
    case "DOCUMENT":
      return documentsData;
    default:
      throw new Error(`Invalid type: ${type}`);
  }
}
