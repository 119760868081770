import {
    useVariable as originalUseVariable,
    useVariableValue as originalUseVariableValue,
    DVCVariable,
    DVCVariableValue
} from '@devcycle/react-client-sdk'


export type UseVariableValue = <
    K extends string & keyof DVCVariableTypes
>(
    key: K,
    defaultValue: DVCVariableTypes[K]
) => DVCVariableTypes[K]

export const useVariableValue = originalUseVariableValue as UseVariableValue

export type UseVariable = <
    K extends string & keyof DVCVariableTypes,
    T extends DVCVariableValue & DVCVariableTypes[K],
>(
    key: K,
    defaultValue: DVCVariableTypes[K]
) => DVCVariable<T>

export const useVariable = originalUseVariable as UseVariable

type DVCJSON = { [key: string]: string | boolean | number }

export type DVCVariableTypes = {
    /**
        created by: Archie Lamb
        created on: 2025-01-16
    */
    'homepage-opportunities-pipeline': boolean
    /**
        created by: Fionn McConville
        created on: 2025-01-13
    */
    'allow-bid-requalify': boolean
    /**
        description: Keep the additional OpenAI function that allows the AI to search the full documents. Ideally going forward we want to disable this as it will hit token limits for OpenAI
        created by: Vishal Bhanderi
        created on: 2025-01-08
    */
    'enable-full-document-search': boolean
    /**
        created by: Fionn McConville
        created on: 2024-12-23
    */
    'new-call-off-table': boolean
    /**
        created by: Piotr Cwieczkowski
        created on: 2024-12-17
    */
    'document-highlighting-v2': boolean
    /**
        created by: Chris Parsons
        created on: 2024-11-18
    */
    'new-supplier-dropdown': boolean
    /**
        created by: Archie Lamb
        created on: 2024-11-11
    */
    'homepage-v2': boolean
    /**
        created by: Tom Whittle
        created on: 2024-11-08
    */
    'award-credits': boolean
    /**
        created by: Hasseeb Hussain
        created on: 2024-11-04
    */
    'opportunties': boolean
    /**
        created by: Justyna Gredecka
        created on: 2024-11-01
    */
    'plan-separation': boolean
    /**
        created by: Fionn McConville
        created on: 2024-10-29
    */
    'new-highlight-logic': boolean
    /**
        created by: Tom Whittle
        created on: 2024-10-22
    */
    'vertical-nav': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-10-18
    */
    'use-signal-score-config': boolean
    /**
        created by: Chris Parsons
        created on: 2024-10-16
    */
    'process-notifications-job': boolean
    /**
        created by: Dominic Bruton
        created on: 2024-09-26
    */
    'calendar-reminders': boolean
    /**
        created by: Vishal Bhanderi
        created on: 2024-09-20
    */
    'document-tooling': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-09-18
    */
    'use-search-engine-for-notifications': boolean
    /**
        created by: Hasseeb Hussain
        created on: 2024-09-16
    */
    'signal-settings-improvements': boolean
    /**
        created by: Tom Whittle
        created on: 2024-08-19
    */
    'filter-collapse-match-styling': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-08-16
    */
    'new-supplier-filter': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-08-16
    */
    'new-notices-search': boolean
    /**
        created by: Dominic Bruton
        created on: 2024-08-09
    */
    'notice-auto-qualification': boolean
    /**
        created by: Tom Whittle
        created on: 2024-08-06
    */
    'filter-collapse-contacts': boolean
    /**
        created by: Tom Whittle
        created on: 2024-08-06
    */
    'filter-collapse-frameworks': boolean
    /**
        created by: Tom Whittle
        created on: 2024-07-24
    */
    'filter-collapse-notices': boolean
    /**
        created by: Tom Whittle
        created on: 2024-07-24
    */
    'filter-collapses': boolean
    /**
        created by: Justyna Gredecka
        created on: 2024-07-23
    */
    'document-details-page': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-07-22
    */
    'cpv-codes-filter': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-07-22
    */
    'keywords-on-the-fly': boolean
    /**
        created by: Gabriella Abraham
        created on: 2024-07-15
    */
    'layer-cake-filters': boolean
    /**
        created by: Dominic Bruton
        created on: 2024-07-12
    */
    'notice-test-var': boolean
    /**
        created by: Vishal Bhanderi
        created on: 2024-07-05
    */
    'global-documents': boolean
    /**
        created by: Justyna Gredecka
        created on: 2024-07-02
    */
    'documents-signal-score': boolean
    /**
        created by: Justyna Gredecka
        created on: 2024-07-02
    */
    'old-documents-buyer-tab': boolean
    /**
        created by: Justyna Gredecka
        created on: 2024-07-02
    */
    'new-documents-buyer-tab': boolean
    /**
        created by: Dominic Bruton
        created on: 2024-06-06
    */
    'notice-free-text-search': boolean
}

/**
    created by: Archie Lamb
    created on: 2025-01-16
*/
export const HOMEPAGE_OPPORTUNITIES_PIPELINE = 'homepage-opportunities-pipeline' as const

/**
    created by: Fionn McConville
    created on: 2025-01-13
*/
export const ALLOW_BID_REQUALIFY = 'allow-bid-requalify' as const

/**
    description: Keep the additional OpenAI function that allows the AI to search the full documents. Ideally going forward we want to disable this as it will hit token limits for OpenAI
    created by: Vishal Bhanderi
    created on: 2025-01-08
*/
export const ENABLE_FULL_DOCUMENT_SEARCH = 'enable-full-document-search' as const

/**
    created by: Fionn McConville
    created on: 2024-12-23
*/
export const NEW_CALL_OFF_TABLE = 'new-call-off-table' as const

/**
    created by: Piotr Cwieczkowski
    created on: 2024-12-17
*/
export const DOCUMENT_HIGHLIGHTING_V_2 = 'document-highlighting-v2' as const

/**
    created by: Chris Parsons
    created on: 2024-11-18
*/
export const NEW_SUPPLIER_DROPDOWN = 'new-supplier-dropdown' as const

/**
    created by: Archie Lamb
    created on: 2024-11-11
*/
export const HOMEPAGE_V_2 = 'homepage-v2' as const

/**
    created by: Tom Whittle
    created on: 2024-11-08
*/
export const AWARD_CREDITS = 'award-credits' as const

/**
    created by: Hasseeb Hussain
    created on: 2024-11-04
*/
export const OPPORTUNTIES = 'opportunties' as const

/**
    created by: Justyna Gredecka
    created on: 2024-11-01
*/
export const PLAN_SEPARATION = 'plan-separation' as const

/**
    created by: Fionn McConville
    created on: 2024-10-29
*/
export const NEW_HIGHLIGHT_LOGIC = 'new-highlight-logic' as const

/**
    created by: Tom Whittle
    created on: 2024-10-22
*/
export const VERTICAL_NAV = 'vertical-nav' as const

/**
    created by: Gabriella Abraham
    created on: 2024-10-18
*/
export const USE_SIGNAL_SCORE_CONFIG = 'use-signal-score-config' as const

/**
    created by: Chris Parsons
    created on: 2024-10-16
*/
export const PROCESS_NOTIFICATIONS_JOB = 'process-notifications-job' as const

/**
    created by: Dominic Bruton
    created on: 2024-09-26
*/
export const CALENDAR_REMINDERS = 'calendar-reminders' as const

/**
    created by: Vishal Bhanderi
    created on: 2024-09-20
*/
export const DOCUMENT_TOOLING = 'document-tooling' as const

/**
    created by: Gabriella Abraham
    created on: 2024-09-18
*/
export const USE_SEARCH_ENGINE_FOR_NOTIFICATIONS = 'use-search-engine-for-notifications' as const

/**
    created by: Hasseeb Hussain
    created on: 2024-09-16
*/
export const SIGNAL_SETTINGS_IMPROVEMENTS = 'signal-settings-improvements' as const

/**
    created by: Tom Whittle
    created on: 2024-08-19
*/
export const FILTER_COLLAPSE_MATCH_STYLING = 'filter-collapse-match-styling' as const

/**
    created by: Gabriella Abraham
    created on: 2024-08-16
*/
export const NEW_SUPPLIER_FILTER = 'new-supplier-filter' as const

/**
    created by: Gabriella Abraham
    created on: 2024-08-16
*/
export const NEW_NOTICES_SEARCH = 'new-notices-search' as const

/**
    created by: Dominic Bruton
    created on: 2024-08-09
*/
export const NOTICE_AUTO_QUALIFICATION = 'notice-auto-qualification' as const

/**
    created by: Tom Whittle
    created on: 2024-08-06
*/
export const FILTER_COLLAPSE_CONTACTS = 'filter-collapse-contacts' as const

/**
    created by: Tom Whittle
    created on: 2024-08-06
*/
export const FILTER_COLLAPSE_FRAMEWORKS = 'filter-collapse-frameworks' as const

/**
    created by: Tom Whittle
    created on: 2024-07-24
*/
export const FILTER_COLLAPSE_NOTICES = 'filter-collapse-notices' as const

/**
    created by: Tom Whittle
    created on: 2024-07-24
*/
export const FILTER_COLLAPSES = 'filter-collapses' as const

/**
    created by: Justyna Gredecka
    created on: 2024-07-23
*/
export const DOCUMENT_DETAILS_PAGE = 'document-details-page' as const

/**
    created by: Gabriella Abraham
    created on: 2024-07-22
*/
export const CPV_CODES_FILTER = 'cpv-codes-filter' as const

/**
    created by: Gabriella Abraham
    created on: 2024-07-22
*/
export const KEYWORDS_ON_THE_FLY = 'keywords-on-the-fly' as const

/**
    created by: Gabriella Abraham
    created on: 2024-07-15
*/
export const LAYER_CAKE_FILTERS = 'layer-cake-filters' as const

/**
    created by: Dominic Bruton
    created on: 2024-07-12
*/
export const NOTICE_TEST_VAR = 'notice-test-var' as const

/**
    created by: Vishal Bhanderi
    created on: 2024-07-05
*/
export const GLOBAL_DOCUMENTS = 'global-documents' as const

/**
    created by: Justyna Gredecka
    created on: 2024-07-02
*/
export const DOCUMENTS_SIGNAL_SCORE = 'documents-signal-score' as const

/**
    created by: Justyna Gredecka
    created on: 2024-07-02
*/
export const OLD_DOCUMENTS_BUYER_TAB = 'old-documents-buyer-tab' as const

/**
    created by: Justyna Gredecka
    created on: 2024-07-02
*/
export const NEW_DOCUMENTS_BUYER_TAB = 'new-documents-buyer-tab' as const

/**
    created by: Dominic Bruton
    created on: 2024-06-06
*/
export const NOTICE_FREE_TEXT_SEARCH = 'notice-free-text-search' as const
