import React, { ReactNode } from "react";
import styled from "@emotion/styled";

import * as brandIcons from "../brands";
import * as icons from "./SVGs";

export type IconKey = keyof typeof icons;
export type BrandIconKey = keyof typeof brandIcons;

type StyleProps = {
  color?: string;
  size?: number;
  fill?: string;
  ariaLabel?: string;
  className?: string;
};
type SVGProps = {
  svg: ReactNode;
} & StyleProps;

type IconProps = {
  icon: IconKey | BrandIconKey;
} & StyleProps;

type Props = SVGProps | IconProps;
export function UIcon(props: Props) {
  let svg: ReactNode = null;

  if ("svg" in props) {
    svg = props.svg;
  } else {
    svg = icons[props.icon as IconKey] || brandIcons[props.icon as BrandIconKey];
  }

  if (!svg) {
    console.error(`Icon ${svg} not found`);
    // Handle the case where the icon doesn't exist
    return null;
  }

  if (React.isValidElement(svg)) {
    return (
      <StyledIcon
        as={svg.type}
        {...svg.props}
        color={props.color ?? svg.props.color}
        size={props.size ?? svg.props.size}
        fill={props.fill ?? svg.props.fill}
        className={props.className}
        aria-label={props.ariaLabel}
      />
    );
  }
  return null;
}

const StyledIcon = styled.svg<StyleProps>(({ size, fill, color, theme }) => ({
  width: size ?? 16,
  height: size ?? 16,
  fill: fill,
  color:
    color === undefined
      ? theme.colors.sysPrimaryDefault
      : Object.keys(theme.colors).includes(color)
        ? theme.colors[color as keyof typeof theme.colors]
        : color,
  flexShrink: 0,
}));

export default UIcon;
