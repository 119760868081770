import React, { useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { BulbOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import { sysPrimaryDefault } from "../../../../lib/themes/colors";
import { TextSearchTipsModal } from "../../../modals/TextSearchTipsModal";
import { MatchCountMap } from "../../../onboarding/SharedOnboarding";
import TagInput from "../../../ui/tag_input/TagInput";
import ClearAllSignalButton from "../clear_all_signal_button/ClearAllSignalButton";
import KeywordInputTooltip from "./keyword_input_tooltip/KeywordInputTooltip";
import SuggestedKeywordsInput from "./suggested_keywords_input/SuggestedKeywordsInput";

import css from "./KeywordInput.module.scss";

const KEYWORD_WARNING_THRESHOLD = 500;

type KeywordInputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, unknown>;
  resultCounts: MatchCountMap | undefined;
};

export default function KeywordInput<T extends FieldValues>({
  name,
  control,
  resultCounts,
}: KeywordInputProps<T>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleGenerateLabel = (tag: string) => {
    const count = resultCounts?.[tag];
    const warning = count && count > KEYWORD_WARNING_THRESHOLD;
    const isValidCount = resultCounts?.[tag] !== undefined && resultCounts?.[tag] !== 0;

    return (
      <div className={css.tagLabel}>
        {tag}
        {isValidCount && (
          <Tooltip title={<KeywordInputTooltip warning={warning} />}>
            <span>({resultCounts?.[tag]})</span>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleVariantSelector = (tag: string) => {
    const count = resultCounts?.[tag];
    if (count && count > KEYWORD_WARNING_THRESHOLD) {
      return "warning";
    }
    return "active";
  };

  return (
    <div className={css.container}>
      <SuggestedKeywordsInput name={name} control={control} />
      <div className={css.inputContainer}>
        <TagInput
          name={name}
          control={control}
          variant="active"
          generateLabel={handleGenerateLabel}
          variantSelector={handleVariantSelector}
          placeholder="Search for keywords"
        />
      </div>
      <div className={css.buttonContainer}>
        <ClearAllSignalButton name={name} control={control} />
        <Button
          onClick={handleOpen}
          icon={<BulbOutlined color={sysPrimaryDefault} className={css.icon} />}
        >
          Advanced keyword tips
        </Button>
      </div>
      <TextSearchTipsModal isOpen={isModalOpen} onClose={handleClose} />
    </div>
  );
}
