import React from "react";
import { LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { Divider } from "antd5";

import { EditAnswer } from "components/bid_module/qualification_report_section/EditAnswer";
import { ALLOW_BID_REQUALIFY, useVariableValue } from "lib/featureFlags";
import { useQualifyQuestion } from "lib/hooks/api/bid/scorecard";
import { EventData, EventNames } from "lib/tracking";
import {
  Scorecard_Result_Questions,
  Scorecard_Result_Questions_Sources,
} from "../../../lib/generated/app-service-gql/graphql";
import { Text } from "../../../styles/utility-components";
import DocumentSources from "./DocumentSources";

import css from "./QuestionAndAnswer.module.scss";

type Props = {
  question: Scorecard_Result_Questions;
  procurementStageId: string;
  bidId: string;
  debugMode: boolean;
  onSelectDocument: (
    source: Scorecard_Result_Questions_Sources,
    trackingContext: EventNames,
    trackingData: EventData,
  ) => Promise<void>;
  showDivider: boolean;
  slideOutOpen: boolean;
};
export function QuestionAndAnswer({
  question,
  procurementStageId,
  bidId,
  debugMode,
  onSelectDocument,
  showDivider,
  slideOutOpen,
}: Props) {
  const allowRequalify = useVariableValue(ALLOW_BID_REQUALIFY, false);
  const { mutateAsync: qualifyQuestionMutation, isLoading: qualifyQuestionIsLoading } =
    useQualifyQuestion();

  async function qualifyQuestion(question: Scorecard_Result_Questions) {
    await qualifyQuestionMutation({
      input: { procurementStageId, question: question.title, bidId },
    });
  }

  return (
    <>
      <article
        key={question.title}
        className={css.questionAndAns}
        style={{ gridTemplateColumns: `${slideOutOpen ? 200 : 400}px max-content` }}
      >
        <Text className={css.questionTitle}>
          {question.title}
          {allowRequalify && (
            <>
              {qualifyQuestionIsLoading ? (
                <LoadingOutlined className={css.icon} />
              ) : (
                <ReloadOutlined className={css.icon} onClick={() => qualifyQuestion(question)} />
              )}
            </>
          )}
        </Text>

        <aside className={css.answerContainer}>
          <EditAnswer
            answerValue={question.answer?.answer || "Not found"}
            answerId={question.answerId}
          />
          {question.sources && question.sources.length > 0 && (
            <span className={css.sources}>
              <DocumentSources
                sources={question.sources}
                onSelectDocument={(source) =>
                  onSelectDocument(source, EventNames.citationOpened, {
                    "Action type": "Link clicked",
                    "Criteria name": question.title,
                  })
                }
              />
              {debugMode && <pre>{JSON.stringify(question.sources, null, 2)}</pre>}
            </span>
          )}
        </aside>
      </article>
      {showDivider && <Divider className={css.divider} />}
    </>
  );
}
